import { FC } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FormContainer } from 'app/hooks/useGlobalForm';
import DOMPurify from 'dompurify';

interface Props {
  openAccessoriesDialog: () => void;
}

const StrollerDisplay: FC<Props> = ({ openAccessoriesDialog }) => {
  const { currentProduct } = FormContainer.useContainer();

  const sanitizedProductDescription = {
    __html:
      DOMPurify.sanitize(currentProduct?.product?.ProductDescription || '') ||
      '',
  };

  return (
    <>
      <Text fontSize={36} fontWeight={700} color="primary.darker">
        {currentProduct?.product?.ProductName}
      </Text>
      <Text
        fontSize={16}
        fontWeight={400}
        fontFamily="Roboto, sans-serif"
        color="grey.800"
      >
        <div dangerouslySetInnerHTML={sanitizedProductDescription} />
      </Text>
      <Box width="100%" maxWidth="346px" maxHeight="346px" mt={5}>
        {currentProduct?.product && (
          <Image
            onClick={openAccessoriesDialog}
            src={currentProduct?.product?.productImages?.[0]?.URL || ''}
            alt="Stroller preview"
            sx={{ cursor: 'pointer' }}
            imgFluid
          />
        )}
      </Box>

      <Button
        variant="contained"
        sx={{
          mt: 5,
          width: {
            xs: '100%',
            lg: '387px',
          },
        }}
        onClick={openAccessoriesDialog}
        color="secondary"
      >
        Reserve This Stroller Now
      </Button>
    </>
  );
};

export default StrollerDisplay;
