import { useEffect } from 'react';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';

const useGetBraintreeToken = () => {
  const { get } = useDataProvider();

  const getToken = () => {
    return get({
      uri: '/braintree',
    });
  };

  const { data, execute, isPending, error } = useQuery(getToken, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  useEffect(() => {
    execute();
  }, []);

  return { data, execute, isPending, error };
};

export default useGetBraintreeToken;
