import { FC, useEffect, useState } from 'react';
import TextField from 'app/components/TextField';
import { FormContainer } from 'app/hooks/useGlobalForm';
import useDebounce from 'app/hooks/useDebounce';

interface Props {
  error?: string;
}

const CommentsAndNotes: FC<Props> = ({ error }) => {
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();
  const [commentsAndNotes, setCommentsAndNotes] = useState<string | undefined>(
    currentProduct.accessories?.commentsAndNotes,
  );
  const debouncedValue = useDebounce<string | undefined>(commentsAndNotes, 300);

  useEffect(() => {
    if (debouncedValue !== undefined) {
      updateCurrentProduct({
        accessories: {
          commentsAndNotes: debouncedValue,
        },
      });
    }
  }, [debouncedValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentsAndNotes(event.target.value);
  };

  return (
    <TextField
      label="Comments"
      variant="outlined"
      margin="normal"
      fullWidth
      color="info"
      value={commentsAndNotes}
      onChange={onChange}
      sx={{ m: 0 }}
      error={!!error}
      helperText={error}
      inputProps={{ maxLength: 300 }}
      options={{ multiline: true, maxRows: 3, rows: 3 }}
    />
  );
};

export default CommentsAndNotes;
