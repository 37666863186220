import { FC } from 'react';
import Box from '@mui/material/Box';
import Header from 'app/components/Header';
import useScrollTop from 'app/hooks/useScrollTop';
import PageLoader from './Testimonials';

const TestimonialsMobile: FC = () => {
  useScrollTop(true);

  return (
    <Box minHeight="100vh" width="100vw">
      <Header backButton />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight={300}
        px={2}
        mt={2}
      >
        <PageLoader />
      </Box>
    </Box>
  );
};

export default TestimonialsMobile;
