import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { PageContentEnum } from 'ks-common/dist/page-content/enums/page-content.enum';
import { toast } from 'react-toastify';

interface Props {
  screen?: PageContentEnum;
}

const useGetPageContent = ({ screen }: Props) => {
  const { get } = useDataProvider();

  const getPageContent = () => {
    return get({
      uri: `/page-content?filter=Internal_Name||$eq||${screen}`,
    });
  };

  const { data, execute, isPending, error } = useQuery(getPageContent, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  return { data, execute, isPending, error };
};

export default useGetPageContent;
