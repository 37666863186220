import { ProductInterface } from 'ks-common';
import priceMask from './priceMask';

interface Params {
  stroller: ProductInterface;
  daysDifference: number;
  hideSign?: boolean;
}

interface PriceCalculator {
  (params: Params): string;
}

const priceCalculator: PriceCalculator = ({
  stroller,
  daysDifference,
  hideSign,
}) => {
  const {
    Nights1to3,
    Nights4to7,
    Nights8to10,
    Nights11to14,
    Nights15to18,
    Nights19to21,
  } = stroller;

  const pricingData = {
    p1to3: {
      nights: [1, 3],
      price: Nights1to3,
    },
    p4to7: {
      nights: [4, 7],
      price: Nights4to7,
    },
    p8to10: {
      nights: [8, 10],
      price: Nights8to10,
    },
    p11to14: {
      nights: [11, 14],
      price: Nights11to14,
    },
    p15to18: {
      nights: [15, 18],
      price: Nights15to18,
    },
    p19to21: {
      nights: [19, 21],
      price: Nights19to21,
    },
  };

  let price;

  Object.values(pricingData).forEach(priceData => {
    if (
      daysDifference >= priceData.nights[0] &&
      daysDifference <= priceData.nights[1]
    ) {
      price = priceData.price;
    }
  });

  return price ? priceMask(price * 100, hideSign) : '';
};

export default priceCalculator;
