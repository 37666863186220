import { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Text } from '@concepta/react-material-ui';
import { isMobile } from 'utils/isMobile';
import { UserTestimonialsInterface } from 'ks-common';
import Stars from './Stars';

interface Props {
  testimonial: UserTestimonialsInterface;
}

const TestimonialCard: FC<Props> = ({ testimonial }) => (
  <Card>
    <CardHeader
      title={
        <Text
          fontSize={isMobile ? 18 : 26}
          fontWeight={700}
          color="primary.darker"
        >
          {testimonial.Subject}
        </Text>
      }
      subheader={
        <Text fontSize={isMobile ? 16 : 20} fontWeight={400} color="grey.700">
          {testimonial.Name}
        </Text>
      }
    />
    <CardContent>
      <Typography variant="body2" color="text.secondary">
        {testimonial.Body}
      </Typography>
      <Box mt={2} display="flex" alignItems="center" justifyContent="center">
        <Stars stars={testimonial.Stars} />
      </Box>
    </CardContent>
  </Card>
);

export default TestimonialCard;
