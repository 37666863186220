import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';

interface Params {
  startDate?: string | null;
  endDate?: string | null;
  productId?: number;
}

const useGetProductAvailability = ({
  startDate,
  endDate,
  productId,
}: Params) => {
  const { get } = useDataProvider();

  const getProductAvailabilityRequest = () => {
    return get({
      uri: `/product-availability?filter=startDate||$eq||${startDate}&filter=endDate||$eq||${endDate}${
        productId ? `&filter=productId||$eq||${productId}` : ''
      }`,
    });
  };

  const { data, execute, isPending, error } = useQuery(
    getProductAvailabilityRequest,
    false,
    {
      onError: error => {
        // Todo: Fix error response in rockets
        const errorRes = error as unknown as {
          response: { data: { message: string } };
        };

        toast.error(errorRes?.response?.data?.message || error?.message);
      },
    },
  );

  return { data, execute, isPending, error };
};

export default useGetProductAvailability;
