import { PublicRoute, Router } from '@concepta/react-router';
import { Navigate } from 'react-router-dom';
import routes, { Routes } from 'app/core/routes';
import NotFound from 'app/screens/NotFound';
import Unauthorized from 'app/screens/Unauthorized';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';

const AppRouter = () => {
  const { products, thankYouProducts } = FormContainer.useContainer();

  const hasProducts = products.some(
    (product: Partial<Form>) => product.step && product.step >= 1,
  );

  const hasThankYouProducts = thankYouProducts?.length > 0;

  return (
    <Router
      isAuth={false}
      NotFoundComponent={NotFound}
      UnauthorizedComponent={Unauthorized}
    >
      {routes.map(route => {
        const component = (route: Routes) => {
          if (route.needsCart && !hasProducts)
            return () => <Navigate to="/" replace={true} />;

          if (
            route.needsThankYouProducts &&
            !(hasProducts || hasThankYouProducts)
          )
            return () => <Navigate to="/" replace={true} />;

          return route.component;
        };

        return (
          <PublicRoute
            path={route.route}
            Component={component(route)}
            key={route.name}
            {...route.props}
          />
        );
      })}
    </Router>
  );
};

export default AppRouter;
