import { FC, Fragment, useState } from 'react';
import ReactGA from 'react-ga4';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import { FormContainer } from 'app/hooks/useGlobalForm';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import BlueCard from 'app/components/BlueCard';
import AlertDialog, { AlertType } from 'app/components/AlertDialog';
import isToday from 'date-fns/isToday';
import { gaProductFormatter } from 'utils/GAnalytics/formatters';
import { DateObject } from 'react-multi-date-picker';
import formatDate from 'utils/formatDate';

interface Props {
  index: number;
  selected: boolean;
  setSelected: (index: number) => void;
  readonly?: boolean;
}

const ProductCardDesktop: FC<Props> = ({
  index,
  selected,
  setSelected,
  readonly,
}) => {
  const {
    products: globalProducts,
    removeProduct,
    thankYouProducts,
  } = FormContainer.useContainer();
  const products = readonly ? thankYouProducts : globalProducts;

  const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);

  const product = products[index];

  const stroller = product?.product;

  const handleRemoveClick = () => {
    setRemoveProductDialogOpen(true);
  };

  const handleRemoveConfirm = () => {
    const gaData = gaProductFormatter(product);
    ReactGA.event('remove_from_cart', gaData);
    removeProduct(index);
  };

  const handleClose = () => {
    setRemoveProductDialogOpen(false);
  };

  const deliveryDateIsToday =
    !!product?.deliveryDate &&
    isToday(
      new DateObject({
        date: product.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  return (
    <Fragment>
      <BlueCard
        onClick={() => setSelected(index)}
        sx={{
          transition: 'all .2s ease-in-out',
          cursor: 'pointer',
          py: selected ? 2 : 1,
          pl: selected ? 8 : 4,
          pr: selected ? 7 : 2,
          mb: 2,
          mr: selected ? 0 : 6,
          borderRadius: '20px',
          position: 'relative',

          '&::after': {
            content: '""',
            transition: 'all .2s ease-in-out',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: '6px',
            marginRight: '-3px',
            backgroundColor: 'primary.200',
            zIndex: 5,
            opacity: selected ? 1 : 0,
          },

          ...(selected && {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none',
            overflow: 'visible',
          }),
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              transition: 'all .2s ease-in-out',
              transform: selected ? 'scale(1.2)' : 'none',
            }}
          >
            <Box width="88px" height="88px" mr={3}>
              {product?.product?.productImages?.[0]?.URL && (
                <Image
                  src={product?.product?.productImages?.[0]?.URL}
                  alt="Stroller preview"
                  imgFluid
                />
              )}
            </Box>

            <Box display="flex" alignItems="center" flex={1}>
              <Box flex={1}>
                <Text fontSize={20} fontWeight={700} color="primary.darker">
                  {stroller?.ProductName}
                </Text>

                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <Text
                      fontSize={14}
                      fontWeight={700}
                      color={deliveryDateIsToday ? 'error.main' : 'grey.400'}
                      display="inline"
                    >
                      {!!product?.deliveryDate &&
                        formatDate(product.deliveryDate, 'MM/DD/YY')}
                    </Text>
                    <Text
                      fontSize={14}
                      fontWeight={700}
                      color="grey.400"
                      display="inline"
                    >
                      {' - '}
                      {!!product?.returnDate &&
                        formatDate(product.returnDate, 'MM/DD/YY')}
                    </Text>
                  </Box>
                  {!readonly && (
                    <Box ml="auto" display="flex" onClick={handleRemoveClick}>
                      <Text fontSize={12} fontWeight={500} color="grey.400">
                        Remove
                      </Text>
                      <DeleteOutline
                        sx={{
                          fontSize: '18px',
                          color: 'primary.darker',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BlueCard>

      <AlertDialog
        alertType={AlertType.CONFIRMATION}
        open={removeProductDialogOpen}
        title="Attention"
        text="Are you sure you want to remove this item from your cart?"
        onClose={handleClose}
        onConfirm={handleRemoveConfirm}
      />
    </Fragment>
  );
};

export default ProductCardDesktop;
