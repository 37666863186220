import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { FormContainer, Form } from './useGlobalForm';
import productParserToOrderRequest from 'utils/productParserToOrderRequest';
import {
  OrderQuoteInputInterface,
  OrderQuoteItemInputInterface,
} from 'ks-common';
import useLocalStorageState from 'use-local-storage-state';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { addBreadcrumb } from '@sentry/react';

type Props = Partial<Form>[];

const useOrderQuote = (products: Props) => {
  const [tapfiliateVid] = useLocalStorageState<string>('tapVid');

  const { orderQuoteId, setOrderQuoteId } = FormContainer.useContainer();

  const { post } = useDataProvider();

  const filteredProducts = products
    .map(product => productParserToOrderRequest(product))
    .filter(prod => !!prod) as OrderQuoteItemInputInterface[];

  const body: OrderQuoteInputInterface = {
    TapfilliateId: tapfiliateVid || '',
    OrderQuoteId: orderQuoteId || undefined,
    Items: filteredProducts,
  };

  const postOrderQuote = () => {
    return post({
      uri: 'order-quote',
      body,
    });
  };

  const {
    data,
    execute: _execute,
    isPending,
  } = useQuery(postOrderQuote, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
    onSuccess: () => {
      addBreadcrumb({
        category: 'OrderQuote',
        message: 'OrderQuote created',
        data: body,
      });
    },
  });

  useEffect(() => {
    if (data && data.OrderQuoteId) {
      setOrderQuoteId(data.OrderQuoteId);
    }
  }, [data]);

  const execute = () => {
    // Prevent order quote if products are not complete
    if (filteredProducts.length === 0) return;
    _execute();
  };

  return { data, execute, isPending };
};

export default useOrderQuote;
