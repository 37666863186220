import { FC, Fragment, useState, useEffect, useMemo } from 'react';
import { Text } from '@concepta/react-material-ui';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ReactGA from 'react-ga4';
import mergeWith from 'lodash/mergeWith';
import Header from 'app/components/Header';
import FormLabel from 'app/components/FormLabel';
import Accessories from './Accessories';
import Insurance from './Insurance';
import NameTag from './NameTag';
import CommentsAndNotes from './CommentsAndNotes';
import {
  FormContainer,
  Form,
  AccessoriesFormErrors,
  LocationMoment,
} from 'app/hooks/useGlobalForm';
import Location from './Location/Location';
import ReserveDates from 'app/components/ReserveDates';
import useGetProductAvailability from 'app/hooks/useGetProductAvailability';
import StrollerCard from './StrollerCard';
import mergeArrayCustomizer from 'utils/mergeArrayCustomizer';
import { AccessoriesAvailabilityInterface } from 'ks-common';
import ErrorText from 'app/components/ErrorText';
import { useNavigate, useLocation } from 'react-router-dom';
import { AccessoriesScreenContainer } from 'app/hooks/useAccessoriesScreenState';
import useGetAccessoriesAvailability from 'app/hooks/useGetAccessoriesAvailability';
import { isMobile } from 'utils/isMobile';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isToday from 'date-fns/isToday';
import { gaProductFormatter } from 'utils/GAnalytics/formatters';
import { DateObject } from 'react-multi-date-picker';
import castleBg from 'assets/images/castle-bg-mobile.jpg';

const HORIZONTAL_PADDING = isMobile ? 4 : 2;

interface AvailableStroller {
  ProductID: number;
  Available: boolean;
}

export interface AccessoriesProps {
  isReview?: boolean;
  isModal?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const AccessoriesForm: FC<AccessoriesProps> = ({
  isReview,
  onCancel,
  onConfirm,
}) => {
  const theme = useTheme();

  const location = useLocation();
  const isLocationReview = location?.state?.isReview || isReview;

  const navigate = useNavigate();

  const {
    currentProduct,
    updateCurrentProduct,
    setCurrentProduct,
    validations,
  } = FormContainer.useContainer();
  const [formErrors, setFormErrors] =
    useState<Partial<AccessoriesFormErrors>>();
  const [temporaryForm, setTemporaryForm] = useState<Partial<Form>>({});
  const { strollerIsAvailable, setStrollerIsAvailable } =
    AccessoriesScreenContainer.useContainer();
  const [initialValues, setInitialValues] = useState<Partial<Form>>();

  useEffect(() => {
    if (isLocationReview) {
      setInitialValues(cloneDeep(currentProduct));
    }
  }, []);

  const updateTemporaryForm = (value: Partial<Form>) => {
    const updatedForm = {
      ...mergeWith(temporaryForm, value, mergeArrayCustomizer),
    };
    setTemporaryForm(updatedForm);
  };

  const checkFormErrors = (isSubmit?: boolean) => {
    setFormErrors(undefined);

    let errors: Partial<AccessoriesFormErrors> = {
      ...validations.accessoriesFormErrors,
    };

    if (!deliveryDate) {
      errors = { ...errors, deliveryDate: 'Please select a delivery date.' };
    }

    if (!returnDate) {
      errors = { ...errors, returnDate: 'Please select a return date.' };
    }

    const requiredValidations = validations.requiredAccessoriesFormErrors;

    if (
      isSubmit &&
      requiredValidations &&
      Object.keys(requiredValidations).length
    ) {
      errors = { ...errors, ...requiredValidations };
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return errors;
    }

    return undefined;
  };

  useEffect(() => {
    if (formErrors) {
      checkFormErrors();
    }
  }, [currentProduct]);

  const handleSubmit = () => {
    const errors = checkFormErrors(true);

    if (!errors) {
      const gaData = gaProductFormatter(merge(temporaryForm, currentProduct));
      ReactGA.event('add_to_cart', gaData);
      updateCurrentProduct({ ...temporaryForm, step: 2 });
      navigate('/review');
    }
  };

  const {
    data: productAvailability,
    execute,
    isPending,
    error,
  } = useGetProductAvailability({
    startDate: currentProduct?.deliveryDate,
    endDate: currentProduct?.returnDate,
    productId: currentProduct?.product?.ProductID,
  });

  const deliveryDate = currentProduct?.deliveryDate;
  const returnDate = currentProduct?.returnDate;

  useEffect(() => {
    if (deliveryDate && returnDate) {
      execute();
    }
  }, [deliveryDate, returnDate]);

  useEffect(() => {
    let availableStroller = false;

    if (!!productAvailability && !isPending && !error) {
      availableStroller = productAvailability.find(
        (stlr: AvailableStroller) =>
          stlr.ProductID === currentProduct?.product?.ProductID,
      )?.Available;
    }

    setStrollerIsAvailable(availableStroller);
  }, [productAvailability, isPending, error]);

  const deliveryDateIsToday =
    !!currentProduct?.deliveryDate &&
    isToday(
      new DateObject({
        date: currentProduct?.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const dateRangeError = useMemo(() => {
    if (!deliveryDate) {
      return 'Please select a delivery date.';
    }

    if (!returnDate) {
      return 'Please select a return date.';
    }

    if (deliveryDate && returnDate && validations.dateRangeError) {
      return validations.dateRangeError;
    }

    if (
      deliveryDate &&
      returnDate &&
      !isPending &&
      strollerIsAvailable === false
    ) {
      return 'Stroller not available in this date';
    }

    return '';
  }, [deliveryDate, returnDate, strollerIsAvailable, isPending]);

  const {
    data: accessoriesAvailability,
    error: accessoriesError,
    execute: accessoriesExecute,
  } = useGetAccessoriesAvailability({
    startDate: currentProduct?.deliveryDate,
    endDate: currentProduct?.returnDate,
    productId: currentProduct?.product?.ProductID,
  });

  useEffect(() => {
    if (
      !!currentProduct?.deliveryDate &&
      !!currentProduct?.returnDate &&
      !!currentProduct?.product?.ProductID
    ) {
      accessoriesExecute();
    }
  }, [
    currentProduct?.deliveryDate,
    currentProduct?.returnDate,
    currentProduct?.product?.ProductID,
  ]);

  const accessoriesAvailabilityError = useMemo(() => {
    let error = '';

    if (isLocationReview && currentProduct?.accessories?.accessories) {
      currentProduct?.accessories.accessories.forEach(accessoryId => {
        const isAvailable = accessoriesAvailability?.find(
          (accessoryAvlb: AccessoriesAvailabilityInterface) =>
            accessoryAvlb.AccessoriesID === accessoryId,
        )?.Available;

        if (!isAvailable) {
          error =
            'This item is not available with selected accessories during this period.';
        }
      });
    }

    return error;
  }, [accessoriesAvailability]);

  const submitIsDisabled =
    !deliveryDate ||
    !returnDate ||
    !strollerIsAvailable ||
    !!validations.deliveryZipError ||
    !!dateRangeError ||
    deliveryDateIsToday ||
    !!validations.returnZipError ||
    !!accessoriesAvailabilityError;

  const resetData = () => {
    initialValues && setCurrentProduct(initialValues);
  };

  const handleCancel = () => {
    resetData();
    onCancel?.();
    if (isMobile && isLocationReview) {
      navigate(-1);
    }
  };

  const handleConfirmEdit = () => {
    const errors = checkFormErrors(true);

    if (!errors && !submitIsDisabled && !isPending) {
      if (isMobile) {
        navigate(-1);
      }
      onConfirm?.();
    }
  };

  const datePickers = (
    <Box
      display="flex"
      justifyContent="center"
      py={5}
      px={15}
      sx={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url('${castleBg}')`,
        '@media (max-width: 1100px)': {
          px: 2,
        },
      }}
    >
      <ReserveDates hideTitle error={dateRangeError} />
    </Box>
  );

  const locations = (
    <Box px={HORIZONTAL_PADDING} pt={3}>
      <FormLabel>Location</FormLabel>

      <Location
        id={LocationMoment.DeliveryLocation}
        zipError={validations.deliveryZipError}
        formErrors={formErrors}
      />

      <Location
        id={LocationMoment.ReturnLocation}
        zipError={validations.returnZipError}
        formErrors={formErrors}
        dateError={dateRangeError}
      />
    </Box>
  );

  const accessoriesInsuranceNameAndComments = (
    <Fragment>
      <Divider sx={{ mt: 4, backgroundColor: 'primary.darker' }} />

      {!!currentProduct?.product?.accessoriesProducts?.length && (
        <Fragment>
          <Box px={HORIZONTAL_PADDING} pt={2}>
            <FormLabel>Accessories</FormLabel>
            <Accessories
              accessoriesAvailability={accessoriesAvailability}
              error={accessoriesError}
              temporaryForm={temporaryForm}
              updateTemporaryForm={updateTemporaryForm}
            />
          </Box>
          <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />
        </Fragment>
      )}

      <Box px={HORIZONTAL_PADDING} pt={2}>
        <FormLabel>Insurance</FormLabel>
        <Insurance
          temporaryForm={temporaryForm}
          updateTemporaryForm={updateTemporaryForm}
        />
      </Box>
      <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

      <Box px={HORIZONTAL_PADDING} pt={3}>
        <NameTag product={currentProduct} error={formErrors?.nameTag} />

        <FormLabel mt={4}>Comments & Notes</FormLabel>
        <CommentsAndNotes error={formErrors?.commentsAndNotes} />
      </Box>
    </Fragment>
  );

  return (
    <Box display="flex" flexDirection="column">
      {!isMobile && (
        <Fragment>
          <Text fontSize={36} fontWeight={700} color="primary.darker">
            Tell us about your trip
          </Text>
          <Divider sx={{ mt: 2, mr: 2, backgroundColor: 'primary.darker' }} />
        </Fragment>
      )}
      {isMobile && <Header backButton handleBack={handleCancel} />}

      {isMobile && datePickers}

      {isMobile && deliveryDateIsToday && (
        <ErrorText sx={{ px: 2 }}>
          Please review and enter a valid delivery date.
        </ErrorText>
      )}

      {isMobile && !isLocationReview && <StrollerCard />}

      {locations}

      {!isLocationReview && accessoriesInsuranceNameAndComments}

      {isLocationReview && accessoriesAvailabilityError && (
        <ErrorText sx={{ px: 2 }}>{accessoriesAvailabilityError}</ErrorText>
      )}

      {formErrors && Object.keys(formErrors) && (
        <ErrorText sx={{ px: 2 }}>
          You are almost there. <br /> Please check all fields before
          proceeding.
        </ErrorText>
      )}

      {!isLocationReview && (
        <Button
          variant="contained"
          sx={{ mx: 4, mt: 6, mb: 4 }}
          onClick={handleSubmit}
          disabled={submitIsDisabled || isPending}
        >
          Continue
        </Button>
      )}

      {isLocationReview && (
        <Box m={4} ml="auto">
          <Link
            onClick={handleCancel}
            fontWeight={500}
            sx={{ cursor: 'pointer' }}
          >
            CANCEL
          </Link>
          <Link
            ml={5}
            onClick={handleConfirmEdit}
            fontWeight={500}
            sx={{
              cursor: 'pointer',
              ...((submitIsDisabled || isPending) && {
                color: 'grey.300',
                textDecorationColor: theme.palette.grey[300],
              }),
            }}
          >
            SAVE
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default AccessoriesForm;
