import { FC, useCallback, useEffect } from 'react';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import CheckboxWithInfoAndValue from 'app/components/CheckboxWithInfoAndValue';
import {
  AccessoriesAvailabilityInterface,
  AccessoriesProductsDTO,
} from 'ks-common';
import sortAccessories from 'utils/sortAccessoriesByPrice';

interface Props {
  accessoriesAvailability: AccessoriesAvailabilityInterface[];
  error: unknown;
  temporaryForm: Partial<Form>;
  updateTemporaryForm: (value: Partial<Form>) => void;
}

const Accessories: FC<Props> = ({
  accessoriesAvailability,
  error,
  temporaryForm,
  updateTemporaryForm,
}) => {
  const { currentProduct } = FormContainer.useContainer();

  const options: AccessoriesProductsDTO[] =
    currentProduct.product?.accessoriesProducts || [];

  const selected = temporaryForm?.accessories?.accessories || [];

  const updateSelectedAccessories = (updated: number[]) => {
    updateTemporaryForm({
      accessories: {
        accessories: updated,
      },
    });
  };

  useEffect(() => {
    if (accessoriesAvailability && selected.length) {
      let newSelected = [...selected];
      accessoriesAvailability?.forEach(
        (accssr: AccessoriesAvailabilityInterface) => {
          if (!accssr.Available && selected.includes(accssr.AccessoriesID)) {
            newSelected = newSelected.filter(
              (v: number) => v !== accssr.AccessoriesID,
            );
          }
        },
      );
      updateSelectedAccessories(newSelected);
    }
  }, [accessoriesAvailability]);

  const selectValue = (value: number) => {
    const updated = [...selected, value];
    updateSelectedAccessories(updated);
  };

  const deselectValue = (value: number) => {
    const updated = selected.filter((v: number) => v !== value);
    updateSelectedAccessories(updated);
  };

  const checkboxOnChange =
    (option: AccessoriesProductsDTO) =>
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        selectValue(option.AccessoriesID);
      } else {
        deselectValue(option.AccessoriesID);
      }
    };

  const accessoryIsAvailable = useCallback(
    (id: number) =>
      accessoriesAvailability?.find?.(
        (accssr: AccessoriesAvailabilityInterface) =>
          id === accssr?.AccessoriesID,
      )?.Available,
    [accessoriesAvailability],
  );

  return (
    <>
      {options?.sort(sortAccessories).map((option: AccessoriesProductsDTO) => {
        const checked = selected.indexOf(option.AccessoriesID) !== -1;

        const checkboxInfo = {
          image: option.accessories?.ImagePath,
          price: option.accessories?.Price,
          disabled: false,
        };

        const itemDisabled =
          !!error || !accessoryIsAvailable(option.AccessoriesID);

        return (
          <CheckboxWithInfoAndValue
            key={option?.AccessoriesID}
            checked={checked}
            disabled={itemDisabled}
            onChange={checkboxOnChange(option)}
            label={option.accessories?.DisplayName}
            info={checkboxInfo}
          />
        );
      })}
    </>
  );
};

export default Accessories;
