const priceMask = (input: number, hideSign?: boolean) => {
  input = input || 0;

  let formatedValue = (input / 100)
    .toFixed(2)
    .replace(/\d{3}(?=(\d{3})*,)/g, function (s) {
      return '.' + s;
    });
  if (formatedValue.substring(0, 1) === '.') {
    formatedValue = formatedValue.substring(1);
  }

  return `${hideSign ? '' : '$'}${formatedValue}`;
};

export default priceMask;
