import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import { FormContainer, Form, ValueOf } from 'app/hooks/useGlobalForm';
import ExpandableStrollerCard, {
  ExpandableStrollerCardType,
} from 'app/components/ExpandableStrollerCard';
import { ListItem } from '../StrollerList';
import DOMPurify from 'dompurify';
import formatDate from 'utils/formatDate';

const MobileItem: FC<ListItem> = ({ stroller, price, disabled }) => {
  const navigate = useNavigate();
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();

  const setFormField = (field: keyof Form, value: ValueOf<Form>) =>
    updateCurrentProduct({ [field]: value });

  const sanitizedProductDescription = {
    __html: DOMPurify.sanitize(stroller.Specifications || '') || '',
  };

  const handleReserveClick = () => {
    setFormField('product', stroller);
    updateCurrentProduct({ step: 1 });
    navigate('/accessories');
  };

  return (
    <ExpandableStrollerCard
      stroller={stroller}
      type={ExpandableStrollerCardType.RESERVE}
      reservePrice={price}
      disabled={disabled}
      handleActionClick={handleReserveClick}
    >
      <Box p={2}>
        <Text
          fontWeight={700}
          fontSize={17}
          textAlign="center"
          color={disabled ? 'grey.600' : 'primary.darker'}
          mt={2}
        >
          {currentProduct?.deliveryDate &&
            formatDate(currentProduct.deliveryDate, 'MM/DD/YY')}{' '}
          -{' '}
          {currentProduct?.returnDate &&
            formatDate(currentProduct.returnDate, 'MM/DD/YY')}
        </Text>
        <Box flex={1} mt={2} px={1}>
          <div dangerouslySetInnerHTML={sanitizedProductDescription} />
        </Box>
      </Box>
    </ExpandableStrollerCard>
  );
};

export default MobileItem;
