import { FC, Fragment, useEffect } from 'react';
import HomeDesktop from './HomeDesktop/HomeDesktop';
import HomeMobile from './HomeMobile/HomeMobile';
import { isMobile } from 'utils/isMobile';
import Tap from '@tapfiliate/tapfiliate-js';
import useLocalStorageState from 'use-local-storage-state';
import useScrollTop from 'app/hooks/useScrollTop';
import { Helmet } from 'react-helmet';

const ENV = process.env.REACT_APP_ENV;
interface Result {
  id: string;
  vid: string;
}

const Home: FC = () => {
  useScrollTop(true);
  const [, setTapfiliateVid] = useLocalStorageState('tapVid');

  const detectOptions = {
    always_callback: true,
  };

  const callback = (error: unknown, result: Result) => {
    if (result) {
      setTapfiliateVid(result.vid || result.id);
    }
  };

  useEffect(() => {
    Tap.detect(detectOptions, callback);
  }, []);

  return (
    <Fragment>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
        <title>
          Stroller Rentals in Orlando | Strollers to your Orlando Resort
        </title>
        <meta
          name="description"
          content="Premium stroller and crib rentals for Disney vacations. Most recommended stroller rental company in Orlando."
        />
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      {isMobile ? <HomeMobile /> : <HomeDesktop />}
    </Fragment>
  );
};

export default Home;
