import differenceInDays from 'date-fns/differenceInDays';
import { DateObject } from 'react-multi-date-picker';

interface DatesValidator {
  (returnDate: string, deliveryDate: string): string;
}

const datesValidator: DatesValidator = (deliveryDate, returnDate) => {
  if (!returnDate || !deliveryDate) return 'Date missing';

  const difference = differenceInDays(
    new DateObject({
      date: returnDate,
      format: 'YYYY-MM-DD',
    }).toDate(),
    new DateObject({
      date: deliveryDate,
      format: 'YYYY-MM-DD',
    }).toDate(),
  );

  if (difference > 21) {
    return 'You exceeded the rental limit of 21 days';
  }

  if (difference === 0) {
    return 'The dates difference must be at least 1 day';
  }

  if (difference < 0) {
    return 'The Return date must be after the Delivery date';
  }

  return '';
};

export default datesValidator;
