import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { FormContainer } from 'app/hooks/useGlobalForm';
import BlueCard from 'app/components/BlueCard';
import CostSummary from 'app/components/CostSummary/CostSummary';
import ProductCardDesktop from './ProductCardDesktop';
import ProductDisplay from 'app/components/ProductCard/ProductDisplay';
import ErrorText from 'app/components/ErrorText/ErrorText';
import isToday from 'date-fns/isToday';
import { paymentImgs } from '../paymentImgs';
import { OrderQuoteOutputInterface } from 'ks-common';
import { DateObject } from 'react-multi-date-picker';
import useScrollTop from 'app/hooks/useScrollTop';

interface Props {
  readonly?: boolean;
  orderQuoteCalculated?: OrderQuoteOutputInterface;
  isPending?: boolean;
}

const ReviewOrderDesktopDisplay: FC<Props> = ({
  readonly,
  orderQuoteCalculated,
  isPending,
}) => {
  const {
    products: globalProducts,
    setProducts,
    startNewProduct,
    setCurrentProductIndex,
    thankYouProducts,
    validations,
  } = FormContainer.useContainer();
  const products = readonly ? thankYouProducts : globalProducts;

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (!products[selected]) {
      setSelected(products.length - 1);
    }
  }, [products]);

  const navigate = useNavigate();

  useScrollTop(true);

  const handleAddRental = () => {
    startNewProduct();
    navigate('/stroller-list');
  };

  useEffect(() => {
    if (!readonly) {
      const filteredProducts = products.filter(product => product.step === 2);
      if (filteredProducts.length !== products.length) {
        setProducts?.(filteredProducts);
        setCurrentProductIndex?.(
          filteredProducts.length ? filteredProducts.length - 1 : 0,
        );
      }
    }
  }, [products]);

  const goToCheckout = () => {
    navigate('/checkout');
  };

  const goHome = () => {
    navigate('/');
  };

  const deliveryDateIsToday = products.some(
    prod =>
      !!prod.deliveryDate &&
      isToday(
        new DateObject({
          date: prod.deliveryDate,
          format: 'YYYY-MM-DD',
        }).toDate(),
      ),
  );

  const disabled = deliveryDateIsToday || validations.nameTagsError;

  return (
    <Container maxWidth="xl">
      <Box display="flex" px={4} py={4}>
        <Box
          flex={1}
          sx={{
            position: 'relative',
          }}
        >
          {products.map((product, i) => (
            <ProductCardDesktop
              key={`${product.product?.ProductID}${i}`}
              index={i}
              selected={selected === i}
              setSelected={setSelected}
              readonly={readonly}
            />
          ))}
          {!!products.length && !deliveryDateIsToday && (
            <Box mt={2} pl={2} pr={9}>
              <CostSummary
                quoteData={orderQuoteCalculated}
                isPending={isPending}
              />
            </Box>
          )}
        </Box>
        <Box flex={1.4}>
          <BlueCard
            sx={{
              transition: 'all .2s ease-in-out',
              px: 2,
              pb: 4,
              mb: 1,
              borderRadius: '20px',
              borderTopLeftRadius: selected === 0 ? 0 : '20px',
            }}
          >
            {products[selected] && (
              <ProductDisplay
                product={products[selected]}
                index={selected}
                readonly={readonly}
              />
            )}

            {!products.length && (
              <Box
                display="flex"
                my={10}
                flexDirection="column"
                alignItems="center"
              >
                <Text fontWeight={600} fontSize={20} color="grey.400">
                  Your cart is empty.
                </Text>
                <Link
                  mt={6}
                  fontWeight="500"
                  onClick={() => navigate('/')}
                  sx={{ cursor: 'pointer' }}
                >
                  HOME
                </Link>
              </Box>
            )}
          </BlueCard>

          {readonly && (
            <Box textAlign="center" mt={4}>
              <Button variant="contained" onClick={goHome} sx={{ px: 6 }}>
                Return to home
              </Button>
            </Box>
          )}

          {deliveryDateIsToday && (
            <ErrorText align="center">
              Please review and enter a valid delivery date.
            </ErrorText>
          )}

          {!!products.length && !readonly && (
            <Box display="flex" px={2} mt={5}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mx: 1,
                  backgroundColor: 'warning.light',
                  '&:hover': { backgroundColor: 'warning.main' },
                  '&:focus': { backgroundColor: 'warning.dark' },
                }}
                onClick={handleAddRental}
              >
                ADD ANOTHER RENTAL
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ mx: 1 }}
                onClick={goToCheckout}
                disabled={disabled}
              >
                RESERVE MY ORDER NOW
              </Button>
            </Box>
          )}

          {!readonly && (
            <Box mt={6}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
                columnSpacing={6}
              >
                {paymentImgs.map(img => (
                  <Grid item xs="auto" key={img.key}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: img.maxWidth }}
                    >
                      <Image src={img.img} imgFluid />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ReviewOrderDesktopDisplay;
