import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { isMobile } from 'utils/isMobile';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

interface Props {
  grow?: boolean;
  children?: ReactNode;
}

const KgdCard: FC<Props> = ({ grow, children }) => {
  const theme = useTheme();
  const blue = theme.palette.primary['900'] || theme.palette.primary.dark;

  return (
    <Box
      bgcolor={alpha(blue, 0.9)}
      borderRadius={isMobile ? '12px' : '20px'}
      display="flex"
      overflow="auto"
      flex={grow ? 1 : 'none'}
      justifyContent="center"
    >
      {children}
    </Box>
  );
};

export default KgdCard;
