import createTheme from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
    900?: string;
    200?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
    900?: string;
    200?: string;
  }
}

const themeLight = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#406297',
      darker: '#103265',
      '900': '#315B99',
      '200': '#E4E9F1',
    },
    secondary: {
      main: '#FF8C25',
      darker: '#b36725',
      light: '#ffb472',
      contrastText: '#fff',
    },
    error: {
      main: '#E96B6B',
    },
    grey: {
      '400': '#B8B8B8',
      '600': '#666666',
      '900': '#2F2F2F',
    },
    warning: {
      main: '#ed6c02',
      light: '#F2994A',
    },
  },
});

export default themeLight;
