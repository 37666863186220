import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ProductInterface } from 'ks-common';
import DOMPurify from 'dompurify';
import { RentalPricing } from '../RentalPricing';

interface Props {
  currentStrollerFromList?: ProductInterface;
}

const Tabs: FC<Props> = ({ currentStrollerFromList }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const sanitizedProductDescription = {
    __html:
      DOMPurify.sanitize(currentStrollerFromList?.Specifications || '') || '',
  };

  return (
    <>
      {currentStrollerFromList && (
        <>
          <MuiTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Pricing" sx={{ flex: 1 }} />
            <Tab label="Features" sx={{ flex: 1 }} />
          </MuiTabs>

          <Box
            sx={{
              flex: 1,
              mt: 3,
              px: 5,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute', // Hack to keep the same box size if tab changes
                opacity: value === 0 ? 1 : 0,
                top: 0,
                left: 0,
                width: '100%',
                px: 5,
              }}
            >
              <RentalPricing product={currentStrollerFromList} hideTite />
            </Box>
            <Box
              sx={{
                minHeight: '155px', // Same height as pricing box // Hack to keep the same box size if tab changes
                opacity: value === 1 ? 1 : 0,
                width: '100%',
              }}
            >
              <div dangerouslySetInnerHTML={sanitizedProductDescription} />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Tabs;
