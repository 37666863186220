import { FC, useEffect } from 'react';
import { Checkbox } from '@concepta/react-material-ui';
import { FormContainer, LocationType } from 'app/hooks/useGlobalForm';
import cloneDeep from 'lodash/cloneDeep';

const LocationSameAsCheckin: FC = () => {
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();

  const deliveryLocation = currentProduct.deliveryLocation;
  const returnLocation = currentProduct.returnLocation;
  const sameAsCheckin = returnLocation?.sameAsCheckin || false;

  const deliveryLocationLocation = deliveryLocation?.location;
  const returnLocationLocation = returnLocation?.location;

  const deliveryResortId = deliveryLocation?.resort?.ResortId;
  const returnResortId = returnLocation?.resort?.ResortId;

  useEffect(() => {
    if (sameAsCheckin) {
      if (returnLocationLocation !== deliveryLocationLocation) {
        updateCurrentProduct({
          returnLocation: {
            sameAsCheckin: false,
          },
        });
      }
    }
  }, [deliveryLocationLocation, returnLocationLocation]);

  useEffect(() => {
    if (sameAsCheckin) {
      if (returnResortId !== deliveryResortId) {
        updateCurrentProduct({
          returnLocation: {
            sameAsCheckin: false,
          },
        });
      }
    }
  }, [deliveryResortId, returnResortId]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      updateCurrentProduct({
        returnLocation: {
          sameAsCheckin: checked,
          location: deliveryLocation?.location,
          ...(deliveryLocationLocation === LocationType.Resort && {
            resort: deliveryLocation?.resort,
          }),
          ...(deliveryLocationLocation === LocationType.VacationHome && {
            vacationHome: cloneDeep(deliveryLocation?.vacationHome),
          }),
        },
      });
    }

    if (!checked) {
      updateCurrentProduct({
        returnLocation: {
          sameAsCheckin: false,
        },
      });
    }
  };

  return (
    <Checkbox
      label="Same as Check In"
      checked={sameAsCheckin}
      onChange={onChange}
    />
  );
};

export default LocationSameAsCheckin;
