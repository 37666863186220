import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import useLocalStorageState from 'use-local-storage-state';
import {
  ProductInterface,
  ResortInterface,
  OrderQuoteItemInputInterface,
} from 'ks-common';
import mergeArrayCustomizer from 'utils/mergeArrayCustomizer';
import differenceInHours from 'date-fns/differenceInHours';
import useCheckoutLocalStorage from './useCheckoutLocalStorage';
import useGlobalFormValidations from './useGlobalFormValidations';
import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

export type ValueOf<T> = T[keyof T];
export interface VacationHome {
  streetAddress?: string;
  city?: string;
  zip?: string;
  state?: string;
  gateCode?: string;
  addressUnknown?: boolean;
}

export enum LocationType {
  Resort = 'resort',
  VacationHome = 'vacationHome',
  Airport = 'airport',
}

export enum LocationMoment {
  DeliveryLocation = 'deliveryLocation',
  ReturnLocation = 'returnLocation',
}

export interface Form {
  step?: 0 | 1 | 2;
  deliveryDate?: string | null;
  returnDate?: string | null;
  product?: ProductInterface;
  accessories: {
    accessories?: number[];
    insurance?: OrderQuoteItemInputInterface['Insurance'];
    nameTag?: OrderQuoteItemInputInterface['NameTag'];
    commentsAndNotes?: OrderQuoteItemInputInterface['SpecialNotes'];
  };
  deliveryLocation: {
    location?: LocationType;
    resort?: ResortInterface;
    vacationHome?: VacationHome;
    time?: string;
  };
  returnLocation: {
    sameAsCheckin?: boolean;
    location?: LocationType | null;
    resort?: ResortInterface | null;
    vacationHome?: VacationHome | null;
    time?: string;
  };
  billing?: {
    rental?: number;
    tax?: number;
  };
}

export interface AccessoriesFormErrors {
  accessories: string;
  nameTag: string;
  commentsAndNotes: string;
  deliveryLocation: string;
  returnLocation: string;
  deliveryLocationResort: string;
  returnLocationResort: string;
  deliveryVacationHome: string;
  deliveryVacationHomeFields: Partial<Record<keyof VacationHome, string>>;
  returnVacationHome: string;
  returnVacationHomeFields: Partial<Record<keyof VacationHome, string>>;
  deliveryTime: string;
  returnTime: string;
  deliveryDate: string;
  returnDate: string;
}

export type FormErrors = Record<keyof Form, string>;

export type UpdateForm = (
  value: Partial<Form>,
  index?: number,
  saveToLocalStorage?: boolean,
) => void;

export type StartNewProduct = (value?: Partial<Form>) => void;

export type SetCurrentProduct = (value: Partial<Form>) => void;

const useForm = () => {
  const [storageProductsArray, setStorageProductsArray] = useLocalStorageState<
    Partial<Form>[]
  >('storageProductsArray');
  const [products, setProducts] = useState<Partial<Form>[]>([]);
  const [thankYouProducts, setThankYouProducts] = useState<Partial<Form>[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [lastSession, setLastSession] = useLocalStorageState<Date | null>(
    'lastSession',
  );
  const [, setTapfiliateVid] = useLocalStorageState<string>('tapVid');
  const { clearCheckoutForm } = useCheckoutLocalStorage();

  const [orderQuoteId, setOrderQuoteId] =
    useLocalStorageState<string>('orderQuoteId');

  const setLastSessionNow = () => {
    setLastSession(new Date());
  };

  const currentProduct = products[currentProductIndex];
  const setCurrentProduct: SetCurrentProduct = product => {
    const newArr = [...products];
    newArr[currentProductIndex] = product;
    setProducts(newArr);
    setStorageProductsArray(newArr);
  };

  useEffect(() => {
    if (lastSession) {
      const hoursDifference = differenceInHours(
        new Date(),
        new Date(lastSession),
      );

      if (hoursDifference >= 1) {
        clearForm();
      } else {
        if (storageProductsArray) {
          setProducts(storageProductsArray);
        }
      }
    }

    setLastSessionNow();

    const handleBeforeUnload = () => {
      setLastSessionNow();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setLastSessionNow();
  }, [products, currentProductIndex]);

  useEffect(() => {
    if (storageProductsArray?.length === 0 && products.length > 0) {
      setProducts([]);
    }
  }, [storageProductsArray]);

  const updateCurrentProduct: UpdateForm = (
    value,
    index = currentProductIndex,
    saveToLocalStorage = true,
  ) => {
    let indexToUse = index;

    if (!products[indexToUse]) {
      indexToUse = products.length;
      setCurrentProductIndex(indexToUse);
    }

    const currentProduct = products[indexToUse] || { step: 0 };
    const updatedProduct = {
      ...mergeWith(currentProduct, value, mergeArrayCustomizer),
    };

    const newArr = [...products];
    newArr[indexToUse] = updatedProduct;
    setProducts(newArr);

    if (saveToLocalStorage) {
      setStorageProductsArray(newArr);
    }
  };

  const startNewProduct: StartNewProduct = value => {
    const firstProduct = products[0];
    let newProductData: Partial<Form> = {
      step: 0,
    };

    if (firstProduct) {
      newProductData = {
        ...newProductData,
        deliveryDate: cloneDeep(products[0].deliveryDate),
        returnDate: cloneDeep(products[0].returnDate),
        deliveryLocation: cloneDeep(products[0].deliveryLocation),
        returnLocation: cloneDeep(products[0].returnLocation),
        accessories: { nameTag: products[0].accessories?.nameTag },
      };
    }

    if (value) {
      newProductData = {
        ...newProductData,
        ...value,
      };
    }

    const newArr = [...products];
    newArr.push(newProductData);
    setProducts(newArr);
    setStorageProductsArray(newArr);
    setCurrentProductIndex(newArr.length ? newArr.length - 1 : 0);
  };

  const removeProduct = (index: number) => {
    const newArr = [...products];
    newArr.splice(index, 1);

    setProducts(newArr);
    setStorageProductsArray(newArr);
    setCurrentProductIndex(newArr.length ? newArr.length - 1 : 0);
  };

  const clearForm = () => {
    setProducts([]);
    setOrderQuoteId('');
    setCurrentProductIndex(0);
    setStorageProductsArray([]);
    setTapfiliateVid('');
    clearCheckoutForm();
  };

  const validations = useGlobalFormValidations(products, currentProduct);

  return {
    products,
    setProducts,
    currentProduct,
    setCurrentProduct,
    updateCurrentProduct,
    startNewProduct,
    removeProduct,
    currentProductIndex,
    setCurrentProductIndex,
    clearForm,
    thankYouProducts,
    setThankYouProducts,
    validations,
    orderQuoteId,
    setOrderQuoteId,
  };
};

export const FormContainer = createContainer(useForm);
