import { FC } from 'react';
import Box from '@mui/material/Box';
import StarOutline from '@mui/icons-material/StarOutline';
import Star from '@mui/icons-material/Star';

const STAR_COLOR = '#fcd800';

interface Props {
  stars?: number;
  maxStars?: number;
}

const Stars: FC<Props> = ({ stars = 0, maxStars = 5 }) => {
  return (
    <Box display="flex">
      {Array.from(Array(stars).keys()).map((_, i) => (
        <Star key={i} sx={{ color: STAR_COLOR }} />
      ))}
      {Array.from(Array(maxStars - stars).keys()).map((_, i) => (
        <StarOutline key={i} sx={{ color: STAR_COLOR }} />
      ))}
    </Box>
  );
};

export default Stars;
