import React, { FC } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ListItem } from '../StrollerList';
import { FormContainer } from 'app/hooks/useGlobalForm';
import DOMPurify from 'dompurify';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import formatDate from 'utils/formatDate';

interface Props {
  handleReserveClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
}

const ItemModal: FC<Props & ListItem> = ({
  stroller,
  price,
  disabled,
  handleReserveClick,
  onClose,
}) => {
  const theme = useTheme();

  const { currentProduct } = FormContainer.useContainer();

  const strollerImg = stroller?.productImages?.[0]?.URL;

  const sanitizedProductDescription = {
    __html: DOMPurify.sanitize(stroller.Specifications || '') || '',
  };

  return (
    <Box
      display="flex"
      flex={1}
      minWidth={700}
      p={4}
      position="relative"
      sx={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" flex={1}>
        <Text fontSize={26} fontWeight={700} color="primary.darker" mb={2}>
          {stroller.ProductName}
        </Text>
        <Box width="146px" height="146px" mb={2}>
          {strollerImg && (
            <Image src={strollerImg} alt={stroller.ProductName} imgFluid />
          )}
        </Box>
        <Text
          fontWeight={500}
          fontSize={22}
          textAlign="center"
          color={disabled ? 'grey.600' : 'primary.darker'}
        >
          {currentProduct?.deliveryDate &&
            formatDate(currentProduct.deliveryDate, 'MM/DD/YY')}{' '}
          -{' '}
          {currentProduct?.returnDate &&
            formatDate(currentProduct.returnDate, 'MM/DD/YY')}
        </Text>
        <Text fontSize={18} fontWeight={300} color="grey.800" mt={0}>
          <Text fontSize={27} fontWeight={600} color="primary.darker">
            {price}
          </Text>
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" flex={1}>
        <Text fontSize={24} fontWeight={700} color="primary.darker">
          Specifications
        </Text>
        <Box flex={1} mt={2}>
          <div dangerouslySetInnerHTML={sanitizedProductDescription} />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            py: 1,
            px: 8,
            textTransform: 'none',
            maxWidth: '178px',
            ...(disabled && {
              backgroundColor: `${theme.palette.grey[600]} !important`,
              color: `${theme.palette.common.white} !important`,
            }),
          }}
          onClick={handleReserveClick}
          disabled={disabled}
        >
          {disabled ? 'Unavailable' : 'Reserve'}
        </Button>
      </Box>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default ItemModal;
