import { FC, ReactNode } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import InfoIcon from '@mui/icons-material/Info';

export enum AlertType {
  INFO = 'info',
  CONFIRMATION = 'confirmation',
}

interface Props {
  alertType?: AlertType;
  open: boolean;
  onClose: () => void;
  title: string;
  text?: string;
  children?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const AlertDialog: FC<Props> = ({
  open,
  onClose,
  title,
  text,
  children,
  alertType = 'info',
  onConfirm,
  onCancel,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={3}
      >
        <InfoIcon
          sx={{
            fontSize: '30px',
            color: 'primary.darker',
            mb: '10px',
          }}
        />

        <Text
          fontSize={16}
          fontWeight={700}
          color="primary.darker"
          mb="10px"
          textAlign="center"
        >
          {title}
        </Text>

        {text && (
          <Text
            fontSize={14}
            fontWeight={400}
            color="text.secondary"
            mb="10px"
            textAlign="center"
          >
            {text}
          </Text>
        )}

        {children && <Box mb="10px">{children}</Box>}

        {alertType === AlertType.INFO && (
          <Link onClick={onClose} fontWeight={500} sx={{ cursor: 'pointer' }}>
            CLOSE
          </Link>
        )}

        {alertType === AlertType.CONFIRMATION && (
          <Box>
            <Link
              onClick={handleCancel}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
            >
              NO
            </Link>
            <Link
              ml={5}
              onClick={handleConfirm}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
            >
              YES
            </Link>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default AlertDialog;
