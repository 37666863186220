import { FC, Fragment } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import BlueCard from 'app/components/BlueCard';
import Divider from '@mui/material/Divider';
import LeftRightInfo from 'app/components/LeftRightInfo/LeftRightInfo';
import CircularProgress from '@mui/material/CircularProgress';
import priceMask from 'utils/priceMask';
import Carousel from './Carousel';
import { OrderQuoteOutputInterface } from 'ks-common';
import { isMobile } from 'utils/isMobile';

interface Props {
  quoteData: OrderQuoteOutputInterface;
  isPending: boolean;
}

const TEXT_SIZE = isMobile ? 13 : 18;

const StrollersCostSummary: FC<Props> = ({ quoteData, isPending }) => {
  return (
    <BlueCard
      sx={{
        py: 2,
      }}
    >
      <Box>
        {isPending && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <CircularProgress />
          </Box>
        )}

        {quoteData && !isPending && (
          <Box display="flex" justifyContent="center" px={2}>
            <Box width={isMobile ? '100px' : '133px'} mr={3}>
              <Carousel />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              height="fit-content"
            >
              <Text
                mb={1}
                fontSize={isMobile ? 17 : 23}
                fontWeight={700}
                color="primary.darker"
              >
                Order Summary
              </Text>

              <LeftRightInfo
                left={
                  <Text fontSize={TEXT_SIZE} fontWeight={300}>
                    Rental:
                  </Text>
                }
                right={
                  <Text fontSize={TEXT_SIZE} fontWeight={300}>
                    {priceMask(quoteData.RentalValue * 100)}
                  </Text>
                }
              />
              {!!quoteData.DiscountValue && (
                <Fragment>
                  <Divider sx={{ my: '4px' }} />
                  <LeftRightInfo
                    left={
                      <Text fontSize={TEXT_SIZE} fontWeight={300}>
                        Discount:
                      </Text>
                    }
                    right={
                      <Text fontSize={TEXT_SIZE} fontWeight={300}>
                        - {priceMask(quoteData.DiscountValue * 100)}
                      </Text>
                    }
                  />
                </Fragment>
              )}
              <Divider sx={{ my: '4px' }} />
              <LeftRightInfo
                left={
                  <Text fontSize={TEXT_SIZE} fontWeight={300}>
                    Tax:
                  </Text>
                }
                right={
                  <Text fontSize={TEXT_SIZE} fontWeight={300}>
                    {priceMask(quoteData.TaxValue * 100)}
                  </Text>
                }
              />
              <Divider sx={{ my: '4px' }} />
              <Box display="flex" flex={1} width="100%">
                <Text
                  fontSize={TEXT_SIZE}
                  fontWeight={700}
                  color="primary.darker"
                >
                  Order Total:
                </Text>
                <Text
                  fontSize={TEXT_SIZE}
                  fontWeight={700}
                  color="primary.darker"
                  sx={{ ml: 'auto' }}
                >
                  {priceMask(quoteData.TotalValue * 100)}
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </BlueCard>
  );
};

export default StrollersCostSummary;
