import { FC } from 'react';
import Box from '@mui/material/Box';
import AccessoriesForm from '../Components/AccessoriesForm';

const AcessoriesMobile: FC = () => (
  <Box minHeight="100vh" width="100vw">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={300}
    >
      <AccessoriesForm />
    </Box>
  </Box>
);

export default AcessoriesMobile;
