import useLocalStorageState from 'use-local-storage-state';
import { CustomerForm, AgentForm } from 'app/hooks/useOrderCheckout';

export interface CheckoutForm {
  customerForm: Partial<CustomerForm>;
  agentForm: Partial<AgentForm>;
}

const useCheckoutLocalStorage = () => {
  const [checkoutForm, setCheckoutForm] =
    useLocalStorageState<Partial<CheckoutForm | null>>('checkoutForm');

  const updateCheckoutForm = (value: Partial<CheckoutForm>) => {
    setCheckoutForm({ ...checkoutForm, ...value });
  };

  const clearCheckoutForm = () => {
    setCheckoutForm(null);
  };

  return { checkoutForm, updateCheckoutForm, clearCheckoutForm };
};

export default useCheckoutLocalStorage;
