import React, { FC } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CustomDialog, CustomDialogTitle } from './Styles';
import { Breakpoint } from '@mui/system/createTheme';

export interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  dividers?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
}

const Dialog: FC<DialogProps> = props => {
  const {
    open,
    handleClose,
    title,
    children,
    footer,
    dividers = false,
    fullWidth,
    maxWidth,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CustomDialog
      onClose={handleClose}
      open={open}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {title && (
        <CustomDialogTitle onClose={handleClose}>{title}</CustomDialogTitle>
      )}

      {children && (
        <DialogContent dividers={dividers}>{children}</DialogContent>
      )}

      {footer && <DialogActions>{footer}</DialogActions>}
    </CustomDialog>
  );
};

export default Dialog;
