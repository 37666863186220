import { FC } from 'react';
import { LocationMoment } from 'app/hooks/useGlobalForm';
import { Box } from '@mui/material';
import { AccessoriesScreenContainer } from 'app/hooks/useAccessoriesScreenState';
import { Text } from '@concepta/react-material-ui';
import FormLabel from 'app/components/FormLabel';

interface Props {
  id: LocationMoment;
}

const LocationWarnings: FC<Props> = ({ id }) => {
  const { deliveryInstructions, returnInstructions } =
    AccessoriesScreenContainer.useContainer();

  const instructions = {
    [LocationMoment['DeliveryLocation']]: deliveryInstructions,
    [LocationMoment['ReturnLocation']]: returnInstructions,
  };

  const title = {
    [LocationMoment['DeliveryLocation']]: 'Delivery details:',
    [LocationMoment['ReturnLocation']]: 'Return details:',
  };

  return (
    <Box mt={3}>
      <FormLabel sx={{ textAlign: 'center' }}>{title[id]}</FormLabel>
      {Object.values(instructions[id])?.map((instruction, i) => (
        <Box key={instruction} mt={2}>
          <Text
            key={i}
            fontWeight={500}
            fontSize={14}
            color="text.primary"
            align="center"
          >
            {instruction}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default LocationWarnings;
