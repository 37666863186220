import { FC } from 'react';
import Grid from '@mui/material/Grid';
import MobileTypeItem from './MobileTypeItem';
import categories from './strollersCategories';

interface StrollerTypeInfo {
  name: string;
  type: number;
}
interface Props {
  setStrollerType?: ({ name, type }: StrollerTypeInfo) => void;
}

const TypesMenuMobile: FC<Props> = ({ setStrollerType }) => {
  const select = (i: number) => () => {
    const stroller = categories[i];
    setStrollerType?.({ type: stroller.type, name: stroller.name });
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {categories.map((option, i) => (
        <MobileTypeItem {...option} onClick={select(i)} key={option.key} />
      ))}
    </Grid>
  );
};

export default TypesMenuMobile;
