import { useEffect } from 'react';
import { GlobalStateContainer } from 'app/hooks/useGlobalState';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';

const useGetAllProducts = () => {
  const { allProducts, setAllProducts } = GlobalStateContainer.useContainer();

  const { get } = useDataProvider();

  const getProducts = () =>
    get({
      uri: '/product',
    });

  const { data, execute, isPending } = useQuery(getProducts, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  useEffect(() => {
    if (data) {
      setAllProducts(data);
    }
  }, [data]);

  useEffect(() => {
    if (!allProducts) {
      execute();
    }
  }, []);

  return { allProducts, isPending };
};

export default useGetAllProducts;
