import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'app/components/Header';
import Box from '@mui/material/Box';
import ReserveDates from 'app/components/ReserveDates';
import useScrollTop from 'app/hooks/useScrollTop';
import StrollerList from '../StrollerList';
import castleBg from 'assets/images/castle-bg-mobile.jpg';

const ENV = process.env.REACT_APP_ENV;

export interface StrollerGroup {
  type: number;
  key: string;
  name: string;
}

const StrollerListMobile: FC = () => {
  useScrollTop(true);

  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <Box
        minHeight="100vh"
        width="100vw"
        display="flex"
        flexDirection="column"
        pb={4}
      >
        <Header backButton />

        <Box
          display="flex"
          justifyContent="center"
          py={5}
          px={15}
          sx={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url('${castleBg}')`,
            '@media (max-width: 1100px)': {
              px: 2,
            },
          }}
        >
          <ReserveDates hideTitle />
        </Box>

        <StrollerList />
      </Box>
    </>
  );
};

export default StrollerListMobile;
