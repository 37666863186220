import { FC, MutableRefObject } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import { isMobile } from 'utils/isMobile';

export interface Category {
  type: number;
  key: string;
  name: string;
  ref: MutableRefObject<null | HTMLDivElement>;
}

interface Props {
  categories: Category[];
}

const CategoriesMenu: FC<Props> = ({ categories }) => {
  const handleScroll = (ref: MutableRefObject<null | HTMLDivElement>) => () => {
    const dims = ref?.current?.offsetTop || 0;
    window.scrollTo({
      left: window.scrollX,
      top: dims - 60,
      behavior: 'smooth',
    });
  };

  const renderCategories = () => {
    if (isMobile)
      return (
        <Box
          sx={{
            button: {
              borderBottom: 1,
              borderColor: 'primary.darker',
              mt: 1,
              fontSize: 12,
              color: 'common.black',
              fontWeight: 700,
            },
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
              justifyContent: 'center',
            },
          }}
        >
          {categories.map(group => (
            <Tab
              key={group.key}
              label={group.name}
              onClick={handleScroll(group.ref)}
            />
          ))}
        </Box>
      );

    return (
      <Box mt={4}>
        {categories.map((group, i) => (
          <Button
            key={group.key}
            variant={i === 0 ? 'contained' : 'outlined'}
            sx={{
              mb: 1,
              textTransform: 'none',
              minWidth: 193,
              '&:not(:last-child)': { mr: 3 },
            }}
            onClick={handleScroll(group.ref)}
          >
            {group.name}
          </Button>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ textAlign: 'center' }} id="TESTE">
      {renderCategories()}
    </Box>
  );
};

export default CategoriesMenu;
