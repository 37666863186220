import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { Form } from './useGlobalForm';
import {
  OrderCheckoutInputInterface,
  OrderQuoteItemInputInterface,
} from 'ks-common';
import productParserToCheckout from 'utils/productParserToCheckout';
import customerParserToCheckout from 'utils/customerParserToCheckout';
import agentParserToCheckout from 'utils/agentParserToCheckout';
import useLocalStorageState from 'use-local-storage-state';
import { toast } from 'react-toastify';
import { addBreadcrumb, captureException } from '@sentry/react';

export interface CustomerForm {
  firstName: string;
  lastName: string;
  billingAddress1: string;
  billingAddress2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  primaryCellPhone: string;
  confirmPrimaryCellPhone: string;
  secondaryCellPhone: string;
  emailAddress: string;
  confirmEmail: string;
  secondaryEmail: string;
  rentalAgreement: boolean;
}

export interface AgentForm {
  agentFirstName: string;
  agentLastName: string;
  agencyName: string;
  agentPhoneNumber: string;
  agentEmail: string;
  payPalCommissionAccount: string;
  iataClia: string;
}

interface Props {
  products: Partial<Form>[];
  paymentInfo: OrderCheckoutInputInterface['PaymentInfo'];
  customer: CustomerForm;
  agent: AgentForm;
  orderQuoteId?: string;
}

const useOrderCheckout = ({
  products,
  paymentInfo,
  customer,
  agent,
  orderQuoteId,
}: Props) => {
  const [tapfiliateVid] = useLocalStorageState<string>('tapVid');
  const { post } = useDataProvider();

  const body: OrderCheckoutInputInterface = {
    OrderQuote: {
      Items: products
        .map(product => productParserToCheckout(product))
        .filter(item => item !== undefined) as OrderQuoteItemInputInterface[],
      TapfilliateId: tapfiliateVid || '',
      OrderQuoteId: orderQuoteId || undefined,
    },
    Customer: customerParserToCheckout(customer),
    PaymentInfo: paymentInfo,
    ...(agent.agencyName && { Agent: agentParserToCheckout(agent) }),
  };

  const postOrderCheckout = () => {
    return post({
      uri: 'order-checkout',
      body,
    });
  };

  const { data, execute, isPending, error } = useQuery(
    postOrderCheckout,
    false,
    {
      onError: errorResponse => {
        // Todo: Fix error response in rockets
        const errorRes = errorResponse as unknown as {
          response: { data: { message: string } };
        };

        toast.error(
          errorRes?.response?.data?.message || errorResponse?.message,
        );

        captureException({
          category: 'OrderCheckout',
          message: 'OrderCheckout error',
          data: body,
          error: errorResponse,
        });
      },
      onSuccess: () => {
        addBreadcrumb({
          category: 'OrderCheckout',
          message: 'OrderCheckout success',
          data: body,
        });
      },
    },
  );

  return { data, execute, isPending, error };
};

export default useOrderCheckout;
