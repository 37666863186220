import { FC, PropsWithChildren } from 'react';
import Card, { CardProps } from '@mui/material/Card';

interface Props {
  borderRadius?: number;
}
const BlueCard: FC<PropsWithChildren<Props & CardProps>> = props => {
  const { children, borderRadius = '22px', sx, ...otherProps } = props;

  return (
    <Card
      sx={[
        {
          borderRadius,
          backgroundColor: 'primary.200',
          border: '1px solid ',
          borderColor: 'secondary.main',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
    >
      {children}
    </Card>
  );
};

export default BlueCard;
