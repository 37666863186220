import { FC, useMemo, useEffect } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import { GlobalStateContainer } from 'app/hooks/useGlobalState';
import useGetAllProducts from 'app/hooks/useGetAllProducts';
import { FormContainer, Form, ValueOf } from 'app/hooks/useGlobalForm';
import { ProductInterface } from 'ks-common';
import cloneDeep from 'lodash/cloneDeep';

const StrollerMenu: FC = () => {
  const theme = useTheme();
  const { allProducts } = useGetAllProducts();

  const { globalState } = GlobalStateContainer.useContainer();
  const { currentProduct, updateCurrentProduct, startNewProduct, products } =
    FormContainer.useContainer();

  const setFormField = (field: keyof Form, value: ValueOf<Form>) => {
    if (currentProduct?.step === 2) {
      startNewProduct({ [field]: value });
    } else {
      updateCurrentProduct({ [field]: value });
    }
  };

  const currentCategory = globalState?.currentCategory;

  const strollersList: ProductInterface[] = useMemo(() => {
    if (!allProducts || !currentCategory) return [];

    return allProducts
      ?.filter((stl: ProductInterface) => stl.TypeProduct === currentCategory)
      ?.sort(
        (a: ProductInterface, b: ProductInterface) =>
          a.Nights1to3 - b.Nights1to3,
      );
  }, [currentCategory, allProducts]);

  const currentProductId = currentProduct?.product?.ProductID;

  useEffect(() => {
    setFormField('product', cloneDeep(strollersList[0]));
  }, [strollersList]);

  useEffect(() => {
    if (strollersList.length && products?.length === 0) {
      setFormField('product', cloneDeep(strollersList[0]));
    }
  }, [strollersList, products]);

  const handleClick = (stroller: ProductInterface) => () => {
    setFormField('product', cloneDeep(stroller));
  };

  const renderStroller = (stroller: ProductInterface) => {
    const isActive = stroller.ProductID === currentProductId;

    const strollerItem = (
      <Box
        height="224px"
        width="257px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handleClick(stroller)}
        >
          <Box
            width={`${isActive ? 120 : 100}px`}
            height={`${isActive ? 120 : 100}px`}
            mb={2}
          >
            <Image
              src={stroller.productImages?.[0]?.URL || ''}
              alt="Stroller preview"
              imgFluid
            />
          </Box>
          <Text
            lineHeight="20px"
            fontSize={isActive ? 22 : 20}
            fontWeight={isActive ? 800 : 400}
            color={isActive ? 'primary.darker' : 'grey.800'}
            align="center"
          >
            {stroller.ProductName}
          </Text>
        </Box>
      </Box>
    );

    return (
      <Card
        sx={{
          height: '224px',
          borderRadius: '17px',
          boxShadow: isActive ? '0px 1px 6px 2px rgba(0, 0, 0, 0.25)' : 'none',
          border: `solid 2px ${
            isActive ? theme.palette.primary.darker : 'transparent'
          }`,
          backgroundColor: isActive ? 'common.white' : 'transparent',
        }}
      >
        {strollerItem}
      </Card>
    );
  };

  return (
    <Box position="relative">
      <Card
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: '17px',
        }}
      />
      <Box sx={{ zIndex: 5, position: 'relative' }}>
        {strollersList.map(stroller => renderStroller(stroller))}
      </Box>
    </Box>
  );
};

export default StrollerMenu;
