import { FC, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Text } from '@concepta/react-material-ui';
import ErrorText from 'app/components/ErrorText';
import { CustomerForm } from 'app/hooks/useOrderCheckout';
import { isMobile } from 'utils/isMobile';
import { FormErrors } from '../validators';

interface Props {
  checked?: boolean;
  changeAccept: (checked: boolean) => void;
  customerErrors?: FormErrors<CustomerForm>;
}

const RentalAgreementCheck: FC<Props> = props => {
  const { checked, changeAccept, customerErrors } = props;

  const navigate = useNavigate();

  const onChangeAccept = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    changeAccept(checked);
  };

  return (
    <Box
      mt={3}
      display="flex"
      alignItems={isMobile ? 'center' : 'flex-start'}
      flexDirection="column"
    >
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChangeAccept} />}
        label={
          <Text
            fontSize={isMobile ? 16 : 20}
            fontWeight={400}
            color="primary.darker"
            onClick={() => navigate('/rental-agreement')}
          >
            I AGREE TO RENTAL AGREEMENT
          </Text>
        }
        sx={{ marginRight: 0 }}
      />
      <Text
        fontSize={isMobile ? 12 : 14}
        fontWeight={300}
        align={isMobile ? 'center' : 'left'}
      >
        You must agree to Rental Agreement in order to continue
      </Text>
      {customerErrors?.rentalAgreement && (
        <ErrorText>{customerErrors.rentalAgreement}</ErrorText>
      )}
    </Box>
  );
};

export default RentalAgreementCheck;
