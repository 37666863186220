import { FC, Fragment, useState } from 'react';
import { FormContainer } from 'app/hooks/useGlobalForm';
import ReserveDates from 'app/components/ReserveDates';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { Text, Image } from '@concepta/react-material-ui';
import TypesMenu from 'app/components/TypesMenu';
import StrollerMenu from './StrollerMenu';
import StrollerDisplay from './StrollerDisplay';
import StrollerSpecifications from './StrollerSpecifications';
import Accessories from 'app/screens/Accessories';
import castleBg from 'assets/images/castle-bg-sepia-desktop.jpg';
import airport from 'assets/icons/airport.svg';
import delivery from 'assets/icons/delivery.svg';
import grocery from 'assets/icons/grocery.svg';

const HomeDesktop: FC = () => {
  const { updateCurrentProduct } = FormContainer.useContainer();
  const [accessoriesDialogOpen, setAccessoriesDialogOpen] = useState(false);

  const openAccessoriesDialog = () => {
    updateCurrentProduct({ step: 1 });
    setAccessoriesDialogOpen(true);
  };
  const closeAccessoriesDialog = () => {
    setAccessoriesDialogOpen(false);
  };

  return (
    <Fragment>
      <Box minHeight="100vh">
        <Header />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={5}
          px={15}
          sx={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url('${castleBg}')`,
            '@media (max-width: 1100px)': {
              px: 2,
            },
          }}
        >
          <Text
            color="primary.contrastText"
            fontSize={28}
            mb={3}
            fontWeight={400}
            align="center"
            variant="h2"
          >
            <strong>Welcome to Kingdom Strollers,</strong> Orlando&apos;s First
            Choice For Stroller Rentals
          </Text>
          <ReserveDates showContinue />
        </Box>

        <Box
          display="flex"
          flex={1}
          sx={{ backgroundColor: 'primary.200', py: 4 }}
        >
          <Container maxWidth="xl">
            <Box display="flex" alignItems="flex-start" flex={1} width="100%">
              <Box flex={1} textAlign="center" px={1}>
                <Image src={delivery} alt="Delivery" />
                <Text color="primary.darker" fontWeight={600} fontSize={21}>
                  Free Delivery & Pickup
                  <br />
                  From Your Resort,
                  <br />
                  Hotel, or Vacation Home
                </Text>
              </Box>
              <Box flex={1} textAlign="center" px={1}>
                <Image src={airport} alt="Airport" />
                <Text color="primary.darker" fontWeight={600} fontSize={24}>
                  Orlando International Airport
                  <br />
                  Delivery and Drop-off
                </Text>
              </Box>
              <Box flex={1} textAlign="center" px={1}>
                <Image src={grocery} alt="Grocery" />
                <Text color="primary.darker" fontWeight={600} fontSize={24}>
                  Free Grocery Delivery
                  <br />
                  Coupon for Disney Resort Guests
                  <br />
                  <Text color="primary.darker" fontWeight={500} fontSize={20}>
                    (Provided by Orlando Grocery Express)
                  </Text>
                </Text>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight={300}
          py={6}
        >
          <TypesMenu />

          <Container maxWidth="xl" sx={{ mt: 8 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              flex={1}
              width="100%"
            >
              <Box display="flex" flex={1} justifyContent="center">
                <StrollerMenu />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                alignItems="center"
                justifyContent="center"
              >
                <StrollerDisplay
                  openAccessoriesDialog={openAccessoriesDialog}
                />
              </Box>
              <Box
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="center"
              >
                <StrollerSpecifications />
              </Box>
            </Box>
          </Container>
        </Box>

        <Footer />
      </Box>

      <Dialog
        onClose={closeAccessoriesDialog}
        open={accessoriesDialogOpen}
        scroll="body"
        fullWidth
        maxWidth="lg"
      >
        {accessoriesDialogOpen && <Accessories isModal />}
      </Dialog>
    </Fragment>
  );
};

export default HomeDesktop;
