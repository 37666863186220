import { FC, useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Dropin } from 'braintree-web-drop-in';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import Link from '@mui/material/Link';
import { Text } from '@concepta/react-material-ui';
import useLocalStorageState from 'use-local-storage-state';
import ReactGA from 'react-ga4';
import { FormContainer } from 'app/hooks/useGlobalForm';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import ErrorText from 'app/components/ErrorText';
import StrollersCostSummary from './Components/StrollersCostSummary';
import useOrderCheckout, {
  CustomerForm,
  AgentForm,
} from 'app/hooks/useOrderCheckout';
import { OrderQuoteOutputInterface } from 'ks-common';
import useOrderQuote from 'app/hooks/useOrderQuote';
import useTapConversion from 'app/hooks/useTapConversion';
import Customer from './Components/CustomerForm';
import Agent from './Components/AgentForm';
import Recaptcha from './Components/Recaptcha';
import { isMobile } from 'utils/isMobile';
import isToday from 'date-fns/isToday';
import useDebounce from 'app/hooks/useDebounce';
import useCheckoutLocalStorage, {
  CheckoutForm,
} from 'app/hooks/useCheckoutLocalStorage';
import useScrollTop from 'app/hooks/useScrollTop';
import Braintree from './Components/Braintree';
import RentalAgreementCheck from './Components/RentalAgreementCheck';
import { agentValidate, customerValidate, FormErrors } from './validators';
import { gaCartFormatter } from 'utils/GAnalytics/formatters';
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const ENV = process.env.REACT_APP_ENV;

const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

export interface OnChangeTextField {
  (input: { name: string; value: string }): void;
}

const CheckoutMobile: FC = () => {
  useScrollTop(true);
  const [tapfiliateVid] = useLocalStorageState<string>('tapVid');

  const navigate = useNavigate();
  const { products, orderQuoteId } = FormContainer.useContainer();
  const { checkoutForm, updateCheckoutForm } = useCheckoutLocalStorage();
  const [gaSent, setGaSent] = useState(false);

  const {
    data: quoteData,
    execute: quoteExecute,
    isPending: quoteIsPending,
  } = useOrderQuote(products);

  useEffect(() => {
    if (products) {
      quoteExecute();
    }
  }, [products]);

  useEffect(() => {
    if (quoteData && !gaSent) {
      const gaData = gaCartFormatter(quoteData);
      ReactGA.event('begin_checkout', gaData);
      setGaSent(true);
    }
  }, [quoteData]);

  const [searchParams] = useSearchParams();
  const refQuery = searchParams.get('ref');

  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [customerErrors, setCustomerErrors] = useState<
    FormErrors<CustomerForm>
  >({});
  const [customerForm, setCustomerForm] = useState<Partial<CustomerForm>>({
    country: 'US',
  });
  const [agentErrors, setAgentErrors] = useState<FormErrors<AgentForm>>({});
  const [agentForm, setAgentForm] = useState<Partial<AgentForm>>({});

  useEffect(() => {
    if (checkoutForm?.customerForm) {
      setCustomerForm(checkoutForm.customerForm);
    }
    if (checkoutForm?.agentForm && Object.keys(checkoutForm.agentForm).length) {
      setIsAgent(true);
      setAgentForm(checkoutForm.agentForm);
    }
  }, []);

  const debouncedCustomerForm = useDebounce<Partial<CustomerForm>>(
    customerForm,
    300,
  );
  const debouncedAgentForm = useDebounce<Partial<AgentForm>>(agentForm, 300);

  useEffect(() => {
    const forms: CheckoutForm = {
      customerForm,
      agentForm,
    };
    updateCheckoutForm(forms);
  }, [debouncedCustomerForm, debouncedAgentForm]);

  const [isAgent, setIsAgent] = useState<boolean>(false);

  const handleTravelAgent = () => {
    setIsAgent(prev => !prev);
  };

  const onChange: OnChangeTextField = ({ name, value }) => {
    setCustomerForm(prev => ({ ...prev, [name]: value }));
  };

  const onSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setCustomerForm({ ...customerForm, [name]: value });
  };

  const agentOnChange: OnChangeTextField = ({ name, value }) => {
    setAgentForm({ ...agentForm, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(customerErrors).length > 0) {
      customerValidate({
        customerForm,
        recaptchaToken,
        setRecaptchaError,
        setCustomerErrors,
      });
    }
  }, [customerForm]);

  useEffect(() => {
    if (Object.keys(agentErrors).length > 0) {
      agentValidate({
        agentForm,
        isAgent,
        setAgentErrors,
      });
    }
  }, [agentForm]);

  const validateForms = () => {
    const formValidation = customerValidate({
      customerForm,
      recaptchaToken,
      setRecaptchaError,
      setCustomerErrors,
    });
    const agentValidation = agentValidate({
      agentForm,
      isAgent,
      setAgentErrors,
    });

    return formValidation && agentValidation;
  };

  const hasErrors = useMemo(() => {
    if (Object.keys(customerErrors).length > 0) return true;

    return isAgent && Object.keys(agentErrors).length > 0;
  }, [customerErrors, agentErrors]);

  const [nounce, setNounce] = useState<string>('');
  const [braintreeIsPending, setBraintreeIsPending] = useState<boolean>(false);

  const {
    data: checkoutData,
    execute: executeCheckout,
    isPending: checktoutIsPending,
    error,
  } = useOrderCheckout({
    products,
    customer: customerForm as CustomerForm,
    agent: agentForm as AgentForm,
    paymentInfo: {
      Nounce: nounce,
      Value: quoteData?.TotalValue,
    },
    orderQuoteId: orderQuoteId,
  });

  const {
    sendComission,
    isPending: conversionIsPending,
    status,
  } = useTapConversion({
    orderId: checkoutData?.OrderId,
    orderAmount: checkoutData?.OrderQuoteCalculated?.SubTotalValue,
    billingName: `${customerForm?.firstName} ${customerForm?.lastName}`,
  });

  useEffect(() => {
    if (checkoutData && tapfiliateVid) {
      sendComission();
    }
    if (checkoutData && !tapfiliateVid) {
      navigate('/thank-you', { state: checkoutData });
    }
  }, [checkoutData]);

  useEffect(() => {
    if (status === 'success') {
      navigate('/thank-you', { state: checkoutData });
    }
  }, [status]);

  useEffect(() => {
    if (nounce) {
      executeCheckout();
    }
  }, [nounce]);

  const changeAccept = (checked: boolean) => {
    setCustomerForm({ ...customerForm, rentalAgreement: checked });
  };

  const [braintreeInstance, setBraintreeInstance] = useState<
    Dropin | undefined
  >(undefined);

  const handleSubmit = () => {
    if (validateForms() && braintreeInstance) {
      setBraintreeIsPending(true);
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          toast.error('Please select a payment method');
        } else {
          setNounce(payload?.nonce);
        }
        setBraintreeIsPending(false);
      });
    }
  };

  const onChangeCaptcha = (token: string | null) => {
    if (token) {
      setRecaptchaToken(token);

      if (recaptchaError) {
        validateForms();
      }
    }
  };

  const expiredCaptcha = () => {
    setRecaptchaToken('');
  };

  const deliveryDateIsToday = products.some(
    prod =>
      !!prod.deliveryDate &&
      isToday(
        new DateObject({
          date: prod.deliveryDate,
          format: 'YYYY-MM-DD',
        }).toDate(),
      ),
  );

  const goToReview = () => {
    navigate('/review');
  };

  const isLoading =
    braintreeIsPending || checktoutIsPending || conversionIsPending;

  useEffect(() => {
    if (error) {
      braintreeInstance?.clearSelectedPaymentMethod();
    }
  }, [error]);

  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <Box minHeight="100vh" width="100vw">
        <Header />
        <Container maxWidth="xl">
          <Grid item container spacing={4} p={2}>
            <Grid item xs={12} md={7}>
              <Text
                fontSize={isMobile ? 24 : 36}
                fontWeight={700}
                color="primary.darker"
                align={isMobile ? 'center' : 'left'}
              >
                Secure Checkout
              </Text>
              <Text
                fontSize={isMobile ? 20 : 25}
                fontWeight={400}
                mt={3}
                display={isMobile ? 'block' : 'inline'}
                color={isMobile ? 'grey.900' : 'primary.darker'}
                textTransform={isMobile ? 'uppercase' : 'none'}
              >
                Billing Address Information{isMobile ? ':' : ''}
              </Text>
              <Text
                fontSize={isMobile ? 16 : 25}
                fontWeight={400}
                display={isMobile ? 'block' : 'inline'}
                color="primary.darker"
                textTransform={isMobile ? 'uppercase' : 'none'}
              >
                {isMobile ? '' : ' '}(Not Your Vacation Address)
              </Text>

              <Box mt={3}>
                <Customer
                  form={customerForm}
                  errors={customerErrors}
                  onChange={onChange}
                  onSelectChange={onSelectChange}
                />
              </Box>

              <Braintree
                braintreeInstance={braintreeInstance}
                setBraintreeInstance={setBraintreeInstance}
              />

              {!refQuery && !tapfiliateVid && (
                <Agent
                  agentForm={agentForm}
                  agentErrors={agentErrors}
                  agentOnChange={agentOnChange}
                  handleTravelAgent={handleTravelAgent}
                  isAgent={isAgent}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                sx={{
                  position: 'sticky',
                  top: '24px',
                }}
              >
                {!deliveryDateIsToday && (
                  <StrollersCostSummary
                    quoteData={quoteData as OrderQuoteOutputInterface}
                    isPending={quoteIsPending}
                  />
                )}

                <RentalAgreementCheck
                  changeAccept={changeAccept}
                  checked={customerForm?.rentalAgreement}
                  customerErrors={customerErrors}
                />

                {!isMobile && !!SHOW_RECAPTCHA && RECAPTCHA_KEY && (
                  <Box mt={3}>
                    <Recaptcha
                      onChange={onChangeCaptcha}
                      expiredCallback={expiredCaptcha}
                    />
                  </Box>
                )}

                {recaptchaError && <ErrorText>{recaptchaError}</ErrorText>}

                {hasErrors && (
                  <ErrorText>
                    Please check all required fields before completing your
                    order.
                  </ErrorText>
                )}

                {deliveryDateIsToday && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={isMobile ? 'center' : 'flex-start'}
                  >
                    <ErrorText sx={{ my: 2 }}>
                      Please review and enter a valid delivery date.
                    </ErrorText>
                    <Link
                      sx={{ cursor: 'pointer' }}
                      onClick={goToReview}
                      fontWeight={500}
                    >
                      RETURN TO REVIEW ORDER SCREEN
                    </Link>
                  </Box>
                )}

                {isLoading && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={4}
                  >
                    <CircularProgress />
                  </Box>
                )}

                <Button
                  variant="contained"
                  sx={{ mt: 4 }}
                  onClick={handleSubmit}
                  fullWidth
                  disabled={isLoading || deliveryDateIsToday}
                >
                  Send Order
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {!isMobile && <Footer />}
      </Box>
    </>
  );
};

export default CheckoutMobile;
