import { useEffect } from 'react';

const useScrollTop = (imediate?: boolean) => {
  const scrollTop = () => {
    // Make scrollTop compatible with all browsers
    window.pageYOffset = 0;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (imediate) scrollTop();
  }, []);

  return { scrollTop };
};

export default useScrollTop;
