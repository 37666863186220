import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FormContainer } from 'app/hooks/useGlobalForm';
import { useTheme } from '@mui/material';
import RangeDatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import formatDate from 'utils/formatDate';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { isMobile } from 'utils/isMobile';
import isArray from 'lodash/isArray';

interface Props {
  label: string;
  value?: string | (string | undefined | null)[] | null;
  onChange?: (value: (string | undefined | null)[] | null) => void;
  disabledDates?: string;
  error?: boolean;
  defaultColor?: boolean;
  variant?: TextFieldProps['variant'];
  type: 'return' | 'delivery' | 'both';
}

type OnDateChange = (
  selectedDates: DateObject | (DateObject | null)[] | null,
) => void;

const DatePicker: FC<Props> = props => {
  const {
    label,
    value,
    onChange,
    disabledDates,
    error,
    defaultColor,
    variant,
    type,
  } = props;
  const theme = useTheme();
  const { currentProduct } = FormContainer.useContainer();

  const [newRangeDates, setNewRangeDates] = useState<string[]>([]);

  const isBoth = type === 'both';
  const isDelivery = type === 'delivery';
  const isReturn = type === 'return';

  const inputColor = defaultColor ? null : theme.palette.primary.contrastText;

  const [finalValue, setFinalValue] = useState<
    (string | undefined | null)[] | undefined | null
  >();

  useEffect(() => {
    finalValue && onChange?.(finalValue);
  }, [finalValue]);

  const currentDeliveryDate = currentProduct?.deliveryDate;
  const currentReturnDate = currentProduct?.returnDate;

  const firstDate = newRangeDates[0] || currentDeliveryDate;
  const secondDate = newRangeDates[1] || currentReturnDate;

  const datePickerValue = () => {
    if (
      (isBoth && !!firstDate && !!secondDate) ||
      (!!firstDate &&
        !!secondDate &&
        (!!currentDeliveryDate || !!currentReturnDate))
    ) {
      return [
        new DateObject({
          date: firstDate,
          format: 'YYYY-MM-DD',
        }),
        new DateObject({
          date: secondDate,
          format: 'YYYY-MM-DD',
        }),
      ];
    }

    // Try to get correct value, but if it's not set, show the other value of the range
    const dateValue =
      (isDelivery ? firstDate : secondDate) ||
      currentDeliveryDate ||
      currentReturnDate;

    if (!dateValue) {
      return undefined;
    }

    return new DateObject({
      date: dateValue,
      format: 'YYYY-MM-DD',
    });
  };

  const onOpen = () => {
    (!!currentDeliveryDate || !!currentReturnDate) &&
      setFinalValue([currentDeliveryDate, currentReturnDate]);
    setNewRangeDates([]);
  };

  const onClose = () => {
    setTemporarySecondDate(undefined);
    setNewRangeDates([]);
  };

  const _onChange: OnDateChange = dateValue => {
    let deliveryString: string | undefined | null = undefined;
    let returnString: string | undefined | null = undefined;

    if (isArray(dateValue)) {
      deliveryString = dateValue[0]?.toString();
      returnString = dateValue[1]?.toString();

      if (deliveryString && returnString && deliveryString >= returnString) {
        if (isDelivery) returnString = null;
        if (isReturn) deliveryString = null;
      }
    } else {
      if (isDelivery) {
        deliveryString = dateValue?.toString();
      }
      if (isReturn) {
        returnString = dateValue?.toString();
      }
    }

    setFinalValue([deliveryString, returnString]);
  };

  const [temporarySecondDate, setTemporarySecondDate] = useState<
    string | undefined
  >(undefined);

  const onFocusedDateChange = (
    focusedDate: DateObject | undefined,
    clickedDate: DateObject | undefined,
  ) => {
    const newClickedDate: string | undefined = clickedDate?.toString();
    if (typeof newClickedDate === 'string') {
      const _newRangeDates: string[] = [...newRangeDates];
      if (isDelivery) {
        _newRangeDates[0] = newClickedDate;
      }
      if (isReturn) {
        _newRangeDates[1] = newClickedDate;
      }
      if (isBoth && clickedDate) {
        if (!temporarySecondDate) {
          const secondDate = new DateObject(clickedDate)
            .add(1, 'days')
            .toString();

          setTemporarySecondDate(secondDate?.toString());
          _newRangeDates[0] = newClickedDate;
          _newRangeDates[1] = secondDate;
        }

        if (temporarySecondDate) {
          _newRangeDates[1] = newClickedDate;
          setTemporarySecondDate(undefined);
        }
      }

      setNewRangeDates(_newRangeDates);
    }
  };

  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const displayValue = () => {
    if (typeof value === 'string') return formatDate(value);
    if (isArray(value))
      return value
        ?.map(date => (date ? formatDate(date) : 'MM/DD/YYYY'))
        .join(' - ');

    return '';
  };

  const displayRange = () => {
    if (isDelivery) return !!secondDate;
    if (isReturn) return !!firstDate;

    return isBoth;
  };

  return (
    <Box
      display="flex"
      flex={1}
      mt={0}
      justifyContent="center"
      sx={{
        '.rmdp-week-day': {
          fontSize: '12px',
          fontWeight: 400,
          color: 'rgba(0, 0, 0, 0.6)',
        },
        '.rmdp-day': {
          fontFamily: '"Inter", sans-serif',
          fontWeight: 400,
          span: {
            fontSize: '12px',
          },
          '.sd': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        '.rmdp-range': {
          backgroundColor: theme.palette.primary.main,
          '.sd': {
            color: 'common.white',
          },
        },
        '.rmdp-panel': {
          li: {
            backgroundColor: theme.palette.primary.main,
          },
        },
      }}
    >
      <RangeDatePicker
        numberOfMonths={isMobile ? 1 : 2}
        onChange={_onChange}
        onOpen={onOpen}
        onClose={onClose}
        onFocusedDateChange={onFocusedDateChange}
        className="rmdp-mobile"
        range={displayRange()}
        value={datePickerValue()}
        weekDays={weekDays}
        minDate={new DateObject().add(1, 'day')}
        format="YYYY-MM-DD"
        render={(_, openCalendar) => (
          <TextField
            label={label}
            variant={variant || (isMobile ? 'standard' : 'outlined')}
            error={error}
            value={displayValue()}
            onFocus={openCalendar}
            InputLabelProps={{ shrink: !!value }}
            InputProps={{
              endAdornment:
                isMobile || variant === 'standard' ? null : (
                  <DateRangeIcon sx={{ color: inputColor }} />
                ),
              disableUnderline: isMobile,
            }}
            sx={{
              input: { color: inputColor, minWidth: 220 },
              label: { color: inputColor },

              borderBottom: isMobile ? '1px solid' : 'none',
              borderBottomColor: inputColor,

              '& label.Mui-focused': {
                color: inputColor,
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: inputColor,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: inputColor,
                },
                '&:hover fieldset': {
                  borderColor: inputColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: inputColor,
                },
              },
            }}
          />
        )}
        mapDays={({ date }) => {
          const formatedDay = date.format('YYYY-MM-DD');
          if (disabledDates?.includes(formatedDay)) {
            return {
              disabled: true,
            };
          }
        }}
        plugins={[
          <DatePanel
            key="0"
            removeButton={false}
            formatFunction={date => {
              return formatDate(date.format);
            }}
          />,
        ]}
      />
    </Box>
  );
};

export default DatePicker;
