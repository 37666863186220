import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';

interface ProductAvailability {
  startDate?: string | null;
  endDate?: string | null;
  productId?: number;
}

const useGetAccessoriesAvailability = ({
  startDate,
  endDate,
  productId,
}: ProductAvailability) => {
  const { get } = useDataProvider();

  const getAccessoriesAvailabilityRequest = () => {
    return get({
      uri: `/accessories-availability?filter=startDate||$eq||${startDate}&filter=endDate||$eq||${endDate}&filter=productId||$eq||${productId}`,
    });
  };

  const { data, execute, isPending, error } = useQuery(
    getAccessoriesAvailabilityRequest,
    false,
    {
      onError: error => {
        // Todo: Fix error response in rockets
        const errorRes = error as unknown as {
          response: { data: { message: string } };
        };

        toast.error(errorRes?.response?.data?.message || error?.message);
      },
    },
  );

  return { data, execute, isPending, error };
};

export default useGetAccessoriesAvailability;
