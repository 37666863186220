import { useState } from 'react';
import { createContainer } from 'unstated-next';

export interface Instructions {
  resortInstructions?: string;
  disneyInstructions?: string;
}

const useAccessoriesScreenState = () => {
  const [deliveryInstructions, setDeliveryInstructions] =
    useState<Instructions>({});
  const [returnInstructions, setReturnInstructions] = useState<Instructions>(
    {},
  );
  const [strollerIsAvailable, setStrollerIsAvailable] = useState<boolean>();

  return {
    deliveryInstructions,
    setDeliveryInstructions,
    returnInstructions,
    setReturnInstructions,
    strollerIsAvailable,
    setStrollerIsAvailable,
  };
};

export const AccessoriesScreenContainer = createContainer(
  useAccessoriesScreenState,
);
