import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from 'app/components/TextField';
import { Checkbox, Text } from '@concepta/react-material-ui';
import {
  FormContainer,
  VacationHome,
  LocationMoment,
} from 'app/hooks/useGlobalForm';
import useDebounce from 'app/hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import ErrorText from 'app/components/ErrorText';

interface Props {
  id: LocationMoment;
  error?: string;
  fieldsErrors?: Record<string, string>;
}

interface Row {
  name: keyof VacationHome;
  title: string;
  maxLength?: number;
}

const LocationVacationHome: FC<Props> = ({ id, error, fieldsErrors }) => {
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();
  const [vacationHomeForm, setVacationHomeForm] = useState<VacationHome>(
    currentProduct[id]?.vacationHome || {},
  );
  const debouncedVacationHomeForm = useDebounce<VacationHome>(
    vacationHomeForm,
    300,
  );

  useEffect(() => {
    updateCurrentProduct({
      [id]: {
        vacationHome: vacationHomeForm,
      },
    });
  }, [debouncedVacationHomeForm]);

  const sameAsCheckin = currentProduct.returnLocation?.sameAsCheckin;

  const vacationHome = currentProduct[id]?.vacationHome;
  const streetAddress = vacationHome?.streetAddress;
  const city = vacationHome?.city;
  const zip = vacationHome?.zip;
  const state = vacationHome?.state;
  const gateCode = vacationHome?.gateCode;
  const addressUnknown = vacationHome?.addressUnknown;

  useEffect(() => {
    if (
      id === LocationMoment.ReturnLocation &&
      sameAsCheckin &&
      vacationHome &&
      !isEqual(vacationHome, vacationHomeForm)
    ) {
      setVacationHomeForm(vacationHome);
    }
  }, [streetAddress, city, zip, state, gateCode, addressUnknown]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const target = event?.target;
    if (target?.name) {
      setVacationHomeForm({ ...vacationHomeForm, [target.name]: target.value });
    }
  };

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateCurrentProduct({
      [id]: {
        vacationHome: {
          addressUnknown: checked,
        },
      },
    });
  };

  const options: Row[] = [
    { name: 'streetAddress', title: 'Street Address', maxLength: 100 },
    { name: 'city', title: 'City', maxLength: 100 },
    { name: 'zip', title: 'ZIP', maxLength: 5 },
    { name: 'state', title: 'State', maxLength: 50 },
    {
      name: 'gateCode',
      title: 'Gate Code (if applicable)',
      maxLength: 100,
    },
  ];

  const checkboxLabel = `${
    id === LocationMoment.ReturnLocation ? 'Pickup' : 'Delivery'
  } address unknown at this time`;

  return (
    <Box>
      {options.map(({ name, title, maxLength }) => (
        <TextField
          key={name}
          name={name}
          variant="standard"
          fullWidth
          sx={{ m: 0 }}
          error={!!fieldsErrors?.[name]}
          helperText={fieldsErrors?.[name]}
          label={
            <Text fontWeight={400} fontSize="14px" letterSpacing="-0.5px">
              {title}
            </Text>
          }
          inputProps={{ ...(maxLength && { maxLength }) }}
          InputLabelProps={{ shrink: !!vacationHomeForm?.[name] }}
          onChange={onChange}
          value={vacationHomeForm?.[name]}
        />
      ))}

      <Box mt={2}>
        <Checkbox
          label={checkboxLabel}
          textProps={{ fontSize: '14px', fontWeight: 400 }}
          onChange={handleCheckbox}
          checked={currentProduct[id]?.vacationHome?.addressUnknown}
        />
      </Box>

      {error && <ErrorText>{error}</ErrorText>}
    </Box>
  );
};

export default LocationVacationHome;
