import { FC, Fragment, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Text, Image } from '@concepta/react-material-ui';
import { Helmet } from 'react-helmet';

import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Container from '@mui/material/Container';
import { isMobile } from 'utils/isMobile';
import LoginHeader from './Header';
import airport from 'assets/icons/airport.svg';
import delivery from 'assets/icons/delivery.svg';
import grocery from 'assets/icons/grocery.svg';

const ENV = process.env.REACT_APP_ENV;

interface Info {
  icon: ReactNode;
  text: string;
}

const Login: FC = () => {
  const imageSx = {
    width: isMobile ? 46 : 58,
    height: isMobile ? 46 : 58,
  };
  const info: Info[] = [
    {
      icon: <Image src={delivery} alt="Delivery" sx={imageSx} />,
      text: 'Free Delivery & Pickup From Your Resort, Hotel, or Vacation Home',
    },
    {
      icon: <Image src={airport} alt="Airport" sx={imageSx} />,
      text: 'Orlando International Airport Delivery and Drop-off',
    },
    {
      icon: <Image src={grocery} alt="Grocery" sx={imageSx} />,
      text: 'Free Grocery Delivery Coupon for Disney Resort Guests (Provided by Orlando Grocery Express)',
    },
  ];
  return (
    <Fragment>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <Box minHeight="100vh">
        <Header />

        <LoginHeader />

        <Box display="flex" flex={1} sx={{ py: 4 }}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              flex={1}
              width="100%"
            >
              <Text
                color="primary.darker"
                fontSize={isMobile ? 24 : 28}
                mb={3}
                fontWeight={400}
                align="center"
                variant="h2"
                mx="auto"
              >
                <strong>Kingdom Strollers {isMobile ? '' : ''}</strong>
                {isMobile && <br />}
                <Box
                  component={isMobile ? 'p' : 'span'}
                  sx={
                    isMobile
                      ? {
                          mt: 2,
                        }
                      : {}
                  }
                >
                  Orlando&apos;s First Choice For Stroller Rentals
                </Box>
              </Text>

              {info.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  flex={1}
                  width="100%"
                  sx={{ py: 2 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item.icon}
                  </Box>
                  <Text variant="body1" sx={{ ml: isMobile ? 2 : 5 }}>
                    <Text
                      color="primary.darker"
                      fontWeight={600}
                      fontSize={isMobile ? 16 : 21}
                    >
                      {item.text}
                    </Text>
                  </Text>
                </Box>
              ))}
            </Box>
          </Container>
        </Box>

        <Footer />
      </Box>
    </Fragment>
  );
};

export default Login;
