import { FC, useEffect, useState, ChangeEvent, useRef, useMemo } from 'react';
import Box from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Text } from '@concepta/react-material-ui';
import TextField from 'app/components/TextField';
import FormLabel from 'app/components/FormLabel';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import useDebounce from 'app/hooks/useDebounce';
import { isMobile } from 'utils/isMobile';

interface Props {
  product: Partial<Form>;
  index?: number;
  error?: string;
  variant?: TextFieldProps['variant'];
  isReview?: boolean;
  readonly?: boolean;
}

const NameTag: FC<Props> = ({
  product,
  index,
  error,
  variant = 'outlined',
  isReview,
  readonly,
}) => {
  const { updateCurrentProduct } = FormContainer.useContainer();

  const [nameTag, setNameTag] = useState<string | undefined>();
  const debouncedValue = useDebounce<string | undefined>(nameTag, 300);

  useEffect(() => {
    setNameTag(product.accessories?.nameTag);
  }, [index]);

  useEffect(() => {
    if (debouncedValue !== undefined && !readonly) {
      updateCurrentProduct(
        {
          accessories: {
            nameTag: debouncedValue,
          },
        },
        index,
      );
    }
  }, [debouncedValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameTag(event.target.value);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef?.current?.blur?.();
    }
  };

  const isCol = useMemo(() => {
    if (!isMobile && isReview) return false;

    return true;
  }, [isMobile, isReview]);

  return (
    <Box
      display="flex"
      flexDirection={isCol ? 'column' : 'row'}
      alignItems={isCol ? 'flex-start' : readonly ? 'center' : 'flex-end'}
    >
      <FormLabel mb={isCol ? 1 : 0}>
        {`Name Tag${isReview ? ':' : ''}`}
      </FormLabel>

      <Box ml={isCol ? 0 : 6} flex={1} width={isCol ? '100%' : 'auto'}>
        {readonly ? (
          <Text
            fontSize={14}
            fontWeight={400}
            color="grey.900"
            align={isMobile ? 'left' : 'right'}
          >
            {nameTag} Family
          </Text>
        ) : (
          <TextField
            label="Family"
            variant={variant}
            margin="normal"
            fullWidth
            color="info"
            value={nameTag}
            onChange={onChange}
            sx={{ m: 0 }}
            error={!!error}
            helperText={error}
            inputProps={{ maxLength: 50, ref: inputRef }}
            onKeyUp={onEnter}
            InputLabelProps={{ shrink: !!nameTag }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Family</InputAdornment>
              ),
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default NameTag;
