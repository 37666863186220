import { FC, useState } from 'react';
import { useTheme } from '@mui/material';
import { Box, Button } from '@mui/material';

import TextField from 'app/components/TextField';
import useLogin from 'app/hooks/useLogin';
import { isMobile } from 'utils/isMobile';
import emailValidation from 'utils/emailValidation';

interface LoginForm {
  orderNumber: string;
  email: string;
}

interface Field {
  label: string;
  name: keyof LoginForm;
}

const Form: FC = () => {
  const theme = useTheme();
  const [form, setForm] = useState<LoginForm>({
    orderNumber: '',
    email: '',
  });
  const [errors, setErrors] = useState<Partial<LoginForm>>();

  const { execute } = useLogin(form);

  const inputColor = theme.palette.primary.contrastText;

  const fields: Field[] = [
    { label: 'Order Number', name: 'orderNumber' },
    { label: 'Email', name: 'email' },
  ];

  const validateForm = () => {
    const newErrors: Partial<LoginForm> = {};
    let isValid = true;

    fields.forEach(field => {
      if (!form[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
        isValid = false;
      }
    });

    if (form.email && !emailValidation(form.email)) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    }

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) execute();
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [evt.target.name]: evt.target.value });
    if (errors) validateForm();
  };

  return (
    <Box
      sx={{
        maxWidth: 310,
        textAlign: 'center',
        mt: 1,
      }}
    >
      {fields.map(field => (
        <TextField
          key={field.label}
          label={field.label}
          name={field.name}
          variant="outlined"
          fullWidth
          value={form[field.name]}
          onChange={onChange}
          error={!!errors?.[field.name]}
          helperText={errors?.[field.name]}
          InputLabelProps={{ shrink: !!form.orderNumber }}
          inputProps={{
            disableUnderline: true,
          }}
          sx={{
            mt: 0.5,
            mb: 2,

            input: { color: inputColor },
            label: { color: inputColor },

            borderBottom: 'none',
            borderBottomColor: inputColor,

            '& label.Mui-focused': {
              color: inputColor,
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: inputColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: inputColor,
              },
              '&:hover fieldset': {
                borderColor: inputColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: inputColor,
              },
            },
          }}
        />
      ))}
      <Button
        variant="contained"
        sx={{ mt: 3, width: isMobile ? 140 : 270, mx: 'auto' }}
        onClick={handleSubmit}
        color="secondary"
      >
        Login
      </Button>
    </Box>
  );
};

export default Form;
