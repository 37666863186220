import { Form } from 'app/hooks/useGlobalForm';
import { ProductInterface, OrderQuoteOutputInterface } from 'ks-common';
import { GAProduct, GAItem } from './GATypes';
import categories from 'app/components/TypesMenu/strollersCategories';
import priceMask from 'utils/priceMask';
import differenceInDays from 'date-fns/differenceInDays';
import priceCalculator from 'utils/priceCalculator';
import { DateObject } from 'react-multi-date-picker';

type ProductToItemsFormatter = (product: Partial<Form>) => GAItem[];
type ProductToGAFormatter = (product: Partial<Form>) => GAProduct;
type CartToGAFormatter = (orderQuote: OrderQuoteOutputInterface) => GAProduct;

// TODO get constants from ks-common (Got help from Marshall. This is not working now and we don't know why)
const DELIVERY_TIME_PREMIUM_FEE = '06:00 AM';

const productToItemsFormatter: ProductToItemsFormatter = stroller => {
  const accessoriesIds: number[] = stroller?.accessories?.accessories || [];
  const product: ProductInterface = stroller?.product as ProductInterface;
  const strollerName = product?.ProductName;

  const accessoriesItems: GAItem[] = accessoriesIds.map(id => {
    const accessoryData = product.accessoriesProducts?.find?.(
      accessory => accessory.AccessoriesID === id,
    );

    const price = accessoryData?.accessories?.Price;

    return {
      item_id: '',
      item_name: `${strollerName} - ${accessoryData?.accessories?.Name}`,
      item_category: '',
      price: `${priceMask((price ? price : 0) * 100, true)}`,
      quantity: 1,
    };
  });

  const insurance: GAItem | false = !!stroller?.accessories?.insurance && {
    item_id: '',
    item_name: `${strollerName} - insurance`,
    item_category: '',
    price: `${priceMask(product.InsuranceRate * 100, true)}`,
    quantity: 1,
  };

  const premiumFee: GAItem | false = stroller?.deliveryLocation?.time ===
    DELIVERY_TIME_PREMIUM_FEE &&
    stroller?.deliveryLocation?.resort?.ResortType !== 1 && {
      item_id: '',
      item_name: `${strollerName} - premium fee`,
      item_category: '',
      price: `${priceMask(product.InsuranceRate * 100, true)}`,
      quantity: 1,
    };

  const daysDifference =
    stroller?.returnDate &&
    stroller?.deliveryDate &&
    differenceInDays(
      new DateObject({
        date: stroller.returnDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
      new DateObject({
        date: stroller.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const strollerPrice =
    product && daysDifference
      ? priceCalculator({ stroller: product, daysDifference, hideSign: true })
      : '';

  const items = [
    {
      item_id: `${product?.ProductID}`,
      item_name: strollerName,
      item_category:
        categories.find(cat => cat.type === product?.TypeProduct)?.name || '',
      price: strollerPrice,
      quantity: 1,
    },
    ...(accessoriesItems.length ? accessoriesItems : []),
    ...(insurance ? [insurance] : []),
    ...(premiumFee ? [premiumFee] : []),
  ];

  return items;
};

const calcTotalValue = (items: GAItem[]) => {
  const itemsPrices: number[] = items?.map(item => Number(item.price));
  return itemsPrices.length ? itemsPrices.reduce((a, b) => a + b) : 0;
};

export const gaProductFormatter: ProductToGAFormatter = stroller => {
  const items = productToItemsFormatter(stroller);

  const totalValue = calcTotalValue(items);

  return {
    currency: 'USD',
    value: `${priceMask(totalValue * 100, true)}`,
    items,
  };
};

export const gaCartFormatter: CartToGAFormatter = orderQuote => {
  const items: GAItem[] = [];

  const totalValue = orderQuote.TotalValue;
  const TotalStrollerPerDayValueSum = orderQuote.Items.reduce(
    (a, item) => a + item.StrollerPerDayValue,
    0,
  );

  orderQuote.Items.forEach(item => {
    const strollerName = item.Product.ProductName;

    items.push({
      item_id: `${item.ProductId}`,
      item_name: strollerName,
      item_category:
        categories.find(cat => cat.type === item?.Product?.TypeProduct)?.name ||
        '',
      price: priceMask(item.TotalItemValue * 100, true),
      quantity: 1,
      ...(orderQuote.CouponCode && {
        coupon: orderQuote.CouponCode || undefined,
      }),
      ...(orderQuote.DiscountValue && {
        discount: orderQuote.DiscountValue
          ? `${priceMask(
              (orderQuote.DiscountValue / TotalStrollerPerDayValueSum) *
                item.StrollerPerDayValue *
                100,
              true,
            )}`
          : undefined,
      }),
    });

    if (item.Insurance) {
      items.push({
        item_id: '',
        item_name: `${strollerName} - insurance`,
        item_category: '',
        price: `${priceMask(item.InsuranceValue * 100, true)}`,
        quantity: 1,
      });
    }

    if (
      orderQuote.PremiumFeeValue &&
      item.DeliveryTime === DELIVERY_TIME_PREMIUM_FEE
    ) {
      items.push({
        item_id: '',
        item_name: `${strollerName} - premium fee`,
        item_category: '',
        price: `${priceMask(orderQuote.PremiumFeeValue * 100, true)}`,
        quantity: 1,
      });
    }

    if (item.Accessories.length) {
      item.Accessories.forEach(accessory =>
        items.push({
          item_id: '',
          item_name: `${strollerName} - ${accessory.Name}`,
          item_category: '',
          price: `${priceMask(accessory.Price * 100, true)}`,
          quantity: 1,
        }),
      );
    }
  });

  return {
    currency: 'USD',
    value: `${priceMask(totalValue * 100, true)}`,
    items,
  };
};
