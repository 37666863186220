import { FC } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import differenceInDays from 'date-fns/differenceInDays';
import { Form } from 'app/hooks/useGlobalForm';
import BlueCard from 'app/components/BlueCard';
import LeftRightInfo from '../LeftRightInfo';
import priceMask from 'utils/priceMask';
import { FormContainer } from 'app/hooks/useGlobalForm';
import priceCalculator from 'utils/priceCalculator';
import { DateObject } from 'react-multi-date-picker';

enum StrollersCardSize {
  SMALL = 'sm',
  LARGE = 'lg',
}

interface Props {
  size?: StrollersCardSize;
  unavailable?: boolean;
}

const StrollersCard: FC<Partial<Form> & Props> = ({
  billing,
  size = StrollersCardSize.SMALL,
  unavailable,
}) => {
  const { currentProduct } = FormContainer.useContainer();

  const stroller = currentProduct.product;

  const daysDifference =
    currentProduct?.returnDate &&
    currentProduct?.deliveryDate &&
    differenceInDays(
      new DateObject({
        date: currentProduct.returnDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
      new DateObject({
        date: currentProduct.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const price =
    stroller && daysDifference
      ? priceCalculator({ stroller, daysDifference })
      : '';

  return (
    <BlueCard
      sx={{
        py: size === StrollersCardSize.SMALL ? 4 : 5,
        px: size === StrollersCardSize.SMALL ? 4 : 9,
        borderRadius: '20px',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ opacity: unavailable ? 0.5 : 1 }}
      >
        {stroller && (
          <Box display="flex" flexDirection="column" key={stroller.ProductID}>
            <Box
              width={size === StrollersCardSize.SMALL ? '146px' : '280px'}
              height={size === StrollersCardSize.SMALL ? '146px' : '280px'}
              mb={3}
              mx="auto"
            >
              <Image
                src={stroller.productImages?.[0]?.URL || ''}
                alt="Stroller preview"
                imgFluid
              />
            </Box>

            <Text
              fontSize={size === StrollersCardSize.SMALL ? 26 : 36}
              fontWeight={700}
              color="primary.darker"
              align="center"
            >
              {stroller.ProductName}
            </Text>
            {!!daysDifference && (
              <Text
                fontSize={25}
                fontWeight={600}
                color="primary.darker"
                align="center"
              >
                {price} - {daysDifference} nights
              </Text>
            )}
          </Box>
        )}

        {billing && (
          <>
            {billing.rental && (
              <LeftRightInfo
                left={
                  <Text fontSize={20} fontWeight={600}>
                    Rental
                  </Text>
                }
                right={
                  <Text fontSize={20} fontWeight={400}>
                    {priceMask(billing.rental)}
                  </Text>
                }
              />
            )}
            {billing.tax && (
              <LeftRightInfo
                left={
                  <Text fontSize={20} fontWeight={600}>
                    Tax
                  </Text>
                }
                right={
                  <Text fontSize={20} fontWeight={400}>
                    {priceMask(billing.tax)}
                  </Text>
                }
              />
            )}

            <Divider sx={{ width: '100%', my: 1 }} />

            <LeftRightInfo
              left={
                <Text fontSize={20} fontWeight={600}>
                  Order Total
                </Text>
              }
              right={
                <Text fontSize={20} fontWeight={400}>
                  {priceMask((billing.rental || 0) + (billing.tax || 0))}
                </Text>
              }
            />
          </>
        )}
      </Box>
    </BlueCard>
  );
};

export default StrollersCard;
