import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { useNavigate } from '@concepta/react-router';
import { toast } from 'react-toastify';
import { addBreadcrumb, captureException } from '@sentry/react';

interface LoginForm {
  orderNumber: string;
  email: string;
}

const useLogin = (props: LoginForm) => {
  const navigate = useNavigate();
  const { post } = useDataProvider();

  const postOrderCheckout = () => {
    return post({
      uri: 'login',
      body: props,
    });
  };

  const { data, execute, isPending, error } = useQuery(
    postOrderCheckout,
    false,
    {
      onError: errorResponse => {
        // Todo: Get error response from back correctly
        const errorRes = errorResponse as unknown as {
          response: { data: { message: string } };
        };

        toast.error(
          errorRes?.response?.data?.message || errorResponse?.message,
        );

        captureException({
          category: 'Login',
          message: 'Login error',
          data: props,
          error: errorResponse,
        });
      },
      onSuccess: () => {
        navigate('/edit-order');
        addBreadcrumb({
          category: 'Login',
          message: 'Login success',
          data: props,
        });
      },
    },
  );

  return { data, execute, isPending, error };
};

export default useLogin;
