import { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { Text } from '@concepta/react-material-ui';
import TestimonialCard from './TestimonialCard';
import { isMobile } from 'utils/isMobile';
import { UserTestimonialsInterface } from 'ks-common';
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
import { toast } from 'react-toastify';
import useScrollTop from 'app/hooks/useScrollTop';

const NUMBER_OF_CARDS = 12;

const Testimonials = () => {
  useScrollTop(true);
  const { get } = useDataProvider();

  const [testimonials, setTestimonials] = useState<UserTestimonialsInterface[]>(
    [],
  );

  const getTestimonials = () => {
    return get({
      uri: '/user-testimonials',
      queryParams: {
        sort: 'CreatedDate,DESC',
        limit: NUMBER_OF_CARDS,
        offset: testimonials.length,
      },
    });
  };

  const { data, execute, isPending } = useQuery(getTestimonials, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  useEffect(() => {
    if (data?.data) {
      setTestimonials([...testimonials, ...data.data]);
    }
  }, [data]);

  const loaderRef = useRef(null);
  const entry = useIntersectionObserver(loaderRef, { threshold: 0.5 });

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      if (!isPending) {
        execute();
      }
    }
  }, [entry]);

  return (
    <Box flex={1}>
      <>
        <Text
          fontSize={isMobile ? 25 : 30}
          fontWeight={600}
          color="primary.darker"
          variant="h1"
        >
          Testimonials
        </Text>

        <Grid container spacing={2} mt={2}>
          <Grid item container spacing={2}>
            {testimonials?.map(testimonial => (
              <Grid item sm={6} key={testimonial.TID}>
                <TestimonialCard testimonial={testimonial} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        my={4}
        ref={loaderRef}
      >
        {isPending && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default Testimonials;
