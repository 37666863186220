import { FC, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { FormContainer, LocationMoment } from 'app/hooks/useGlobalForm';
import { ResortDetailsInterface, VersionTimesInterface } from 'ks-common';
import format from 'date-fns/format';
import isArray from 'lodash/isArray';
import { isMobile } from 'utils/isMobile';
import { DateObject } from 'react-multi-date-picker';

interface Props {
  id: LocationMoment;
  resort?: ResortDetailsInterface;
  error?: string;
  disabled?: boolean;
}

type WeekDates = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

const LocationTime: FC<Props> = ({ id, resort, error, disabled }) => {
  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();

  const { deliveryDate, returnDate } = currentProduct;

  const locationDates = {
    deliveryLocation: deliveryDate,
    returnLocation: returnDate,
  };

  const date = locationDates[id];

  const weekDay =
    date &&
    format(
      new DateObject({
        date: date,
        format: 'YYYY-MM-DD',
      }).toDate(),
      'iii',
    );

  const weekTimes: VersionTimesInterface | undefined = useMemo(() => {
    const timeSchedule = resort?.TimeSchedule;
    if (typeof timeSchedule !== 'string') {
      const times = timeSchedule?.Versions?.[0]?.Times;
      return times;
    }
  }, [resort]);

  const type = id === 'deliveryLocation' ? 'Delivery' : 'Pickup';
  const timesKey: keyof VersionTimesInterface | undefined =
    type && (weekDay as WeekDates) && `${type}${weekDay as WeekDates}`;

  const timeOptions = weekTimes && timesKey && weekTimes[timesKey];

  useEffect(() => {
    const currentValue = currentProduct[id]?.time;

    if (currentValue && timeOptions) {
      if (!timeOptions?.includes(currentValue)) {
        updateCurrentProduct({
          [id]: {
            time: null,
          },
        });
      }
    }
  }, [timeOptions]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCurrentProduct({
      [id]: {
        time: event.target.value,
      },
    });
  };

  const value = currentProduct[id]?.time;

  return (
    <>
      <TextField
        select
        label="Time"
        value={value}
        error={!!error}
        helperText={isMobile ? error : ''}
        onChange={onChange}
        sx={{ width: '100%', mt: 1 }}
        variant="standard"
        disabled={disabled || !timeOptions}
      >
        {isArray(timeOptions) &&
          timeOptions.map((opt: string, i: number) => {
            return (
              <MenuItem key={i} value={opt}>
                {opt}
              </MenuItem>
            );
          })}
      </TextField>
    </>
  );
};

export default LocationTime;
