import { FC, useEffect } from 'react';
import { RadioGroup } from '@concepta/react-material-ui';
import { RadioOptions } from '@concepta/react-material-ui/dist/components/RadioGroup';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';

interface Props {
  temporaryForm: Partial<Form>;
  updateTemporaryForm: (value: Partial<Form>) => void;
}

const Insurance: FC<Props> = ({ temporaryForm, updateTemporaryForm }) => {
  const { currentProduct } = FormContainer.useContainer();

  const insurance = temporaryForm?.accessories?.insurance;

  const setInsurance = (value: boolean) => {
    updateTemporaryForm({
      accessories: {
        insurance: value, // This looks weird, I know.. But MUI's Radio Group value is string
      },
    });
  };

  useEffect(() => {
    if (insurance === undefined) {
      setInsurance(true);
    }
  }, [insurance]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInsurance(event.target.value === 'true');
  };

  const price = currentProduct.product?.InsuranceRate;

  const options = [
    {
      label: `Please add optional damage / theft insurance (just $${price} for the entire rental period)`,
      value: 'true', // Same thing as comment above
    },
    {
      label: 'I decline the optional damage / theft insurance',
      value: 'false',
    },
  ];

  return (
    <RadioGroup
      options={options as RadioOptions[]}
      onChange={onChange}
      value={temporaryForm?.accessories?.insurance}
      row
    />
  );
};

export default Insurance;
