import { FC } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export enum ExpandableStrollerCardType {
  REVIEW = 'review',
  RESERVE = 'reserve',
}

interface Props {
  handleViewMore: () => void;
  expanded?: boolean;
  disabled?: boolean;
}

const ExpandButton: FC<Props> = ({ handleViewMore, expanded, disabled }) => (
  <Box display="flex" alignItems="center" onClick={handleViewMore}>
    <Text
      fontSize={12}
      fontWeight={500}
      color={disabled ? 'grey.600' : 'grey.400'}
    >
      View {expanded ? 'less' : 'more'}
    </Text>
    {expanded ? (
      <ExpandLess
        sx={{
          fontSize: '26px',
          color: disabled ? 'grey.600' : 'primary.darker',
        }}
      />
    ) : (
      <ExpandMore
        sx={{
          fontSize: '26px',
          color: disabled ? 'grey.600' : 'primary.darker',
        }}
      />
    )}
  </Box>
);

export default ExpandButton;
