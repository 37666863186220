import { FC, useEffect, useRef, useCallback } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import { FormContainer } from 'app/hooks/useGlobalForm';
import useGetAllProducts from 'app/hooks/useGetAllProducts';
import useGetProductAvailability from 'app/hooks/useGetProductAvailability';
import { ProductInterface, ProductAvailabilityInterface } from 'ks-common';
import differenceInDays from 'date-fns/differenceInDays';
import CategoriesMenu, { Category } from './Components/CategoriesMenu';
import MobileItem from './StrollerListMobile/MobileItem';
import DesktopItem from './StrollerListDesktop/DesktopItem';
import BlueCard from 'app/components/BlueCard';
import priceCalculator from 'utils/priceCalculator';
import { isMobile } from 'utils/isMobile';
import { DateObject } from 'react-multi-date-picker';

export interface ListItem {
  stroller: ProductInterface;
  price: string;
  disabled?: boolean;
}

export interface StrollerGroup {
  type: number;
  key: string;
  name: string;
}

const StrollerList: FC = () => {
  const { currentProduct } = FormContainer.useContainer();
  const { allProducts } = useGetAllProducts();

  const {
    data: productAvailability,
    execute,
    isPending,
  } = useGetProductAvailability({
    startDate: currentProduct?.deliveryDate,
    endDate: currentProduct?.returnDate,
  });

  useEffect(() => {
    if (
      !isPending &&
      currentProduct?.deliveryDate &&
      currentProduct?.returnDate
    ) {
      execute();
    }
  }, [currentProduct?.deliveryDate, currentProduct?.returnDate]);

  const categories: Category[] = [
    {
      type: 1,
      key: 'singles',
      name: 'Singles',
      ref: useRef(null),
    },
    {
      type: 2,
      key: 'doubles',
      name: 'Doubles',
      ref: useRef(null),
    },
    {
      type: 4,
      key: 'specialNeeds',
      name: 'Special Needs',
      ref: useRef(null),
    },
    {
      type: 5,
      key: 'scooters',
      name: 'Scooters',
      ref: useRef(null),
    },
    {
      type: 3,
      key: 'cribsAndBedding',
      name: 'Cribs and Bedding',
      ref: useRef(null),
    },
  ];

  const daysDifference =
    currentProduct?.returnDate &&
    currentProduct?.deliveryDate &&
    differenceInDays(
      new DateObject({
        date: currentProduct.returnDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
      new DateObject({
        date: currentProduct.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const checkAvailability = (strollerId: number) => {
    if (daysDifference && daysDifference > 21) return false;

    return (
      productAvailability?.find(
        (product: ProductAvailabilityInterface) =>
          product.ProductID === strollerId,
      )?.Available || false
    );
  };

  const listType: (type: number) => ProductInterface[] = useCallback(
    type => {
      if (!allProducts) return [];

      const availableProducts: ProductInterface[] = [];
      const unavailableProducts: ProductInterface[] = [];

      allProducts
        ?.filter((stl: ProductInterface) => {
          return stl.TypeProduct === type;
        })
        ?.sort(
          (a: ProductInterface, b: ProductInterface) =>
            a.Nights1to3 - b.Nights1to3,
        )
        .forEach((stl: ProductInterface) => {
          const arrayToPush = checkAvailability(stl.ProductID)
            ? availableProducts
            : unavailableProducts;

          arrayToPush.push(stl);
        });

      return [...availableProducts, ...unavailableProducts];
    },
    [allProducts, productAvailability],
  );

  const renderCard = (stroller: ProductInterface) => {
    const disabled = !checkAvailability(stroller.ProductID);

    const price = daysDifference
      ? priceCalculator({ stroller, daysDifference })
      : '';

    if (!isMobile) {
      return (
        <DesktopItem
          key={stroller.ProductID}
          stroller={stroller}
          price={price}
          disabled={disabled}
        />
      );
    }

    return (
      <MobileItem
        key={stroller.ProductID}
        stroller={stroller}
        price={price}
        disabled={disabled}
      />
    );
  };

  const Wrapper = isMobile ? Box : BlueCard;

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <CategoriesMenu categories={categories} />

      {categories.map(category => (
        <Box ref={category.ref} key={category.key}>
          <Wrapper
            sx={{
              mx: isMobile ? 2 : 0,
              ...(!isMobile && {
                px: 9,
                py: 3,
                mt: 6,
              }),
            }}
            {...(!isMobile && { elevation: 0 })}
          >
            <Text
              fontWeight={700}
              fontSize={isMobile ? 24 : 48}
              color="primary.darker"
              pt={isMobile ? 4 : 3}
              mx={isMobile ? 2 : 0}
            >
              {category.name}
            </Text>
            {listType(category.type)?.map(stroller => renderCard(stroller))}
          </Wrapper>
        </Box>
      ))}
    </Box>
  );
};

export default StrollerList;
