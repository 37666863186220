import { FC } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Text } from '@concepta/react-material-ui';
import priceMask from 'utils/priceMask';
import { ProductInterface } from 'ks-common';
import { isMobile } from 'utils/isMobile';

interface Props {
  product: ProductInterface;
  hideTite?: boolean;
}

interface SingleItem {
  key: string;
  name: string;
  price: number;
}

const RentalPricing: FC<Props> = ({ product, hideTite }) => {
  const {
    Nights1to3,
    Nights4to7,
    Nights8to10,
    Nights11to14,
    Nights15to18,
    Nights19to21,
  } = product;

  const prices = [
    { key: 'Nights1to3', name: '1 - 3 Nights', price: Nights1to3 * 100 },
    { key: 'Nights4to7', name: '4 - 7 Nights', price: Nights4to7 * 100 },
    { key: 'Nights8to10', name: '8 - 10 Nights', price: Nights8to10 * 100 },
    { key: 'Nights11to14', name: '11 - 14 Nights', price: Nights11to14 * 100 },
    { key: 'Nights15to18', name: '15 - 18 Nights', price: Nights15to18 * 100 },
    { key: 'Nights19to21', name: '19 - 21 Nights', price: Nights19to21 * 100 },
  ];

  const singleItem = (item: SingleItem, i: number) => {
    const { key, name, price } = item;
    return (
      <Box key={key}>
        <Box
          display="flex"
          flex={1}
          my={isMobile ? '2px' : '6px'}
          justifyContent="space-between"
        >
          <Text fontSize={isMobile ? 14 : 20} fontWeight={600} marginRight={6}>
            {name}
          </Text>
          <Text fontSize={isMobile ? 14 : 20} fontWeight={400}>
            {priceMask(price)}
          </Text>
        </Box>
        {i !== prices.length - 1 && <Divider />}
      </Box>
    );
  };

  return (
    <Box flex={1}>
      {!hideTite && (
        <Text
          fontSize={isMobile ? 20 : 30}
          fontWeight={700}
          color="primary.darker"
          mb="10px"
        >
          Rental Pricing
        </Text>
      )}

      {prices.map((price, i) => singleItem(price, i))}
    </Box>
  );
};

export default RentalPricing;
