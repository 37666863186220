import React, { Fragment, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Text } from '@concepta/react-material-ui';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from 'app/components/Dialog';
import ItemModal from './ItemModal';
import { ListItem } from '../StrollerList';
import { FormContainer } from 'app/hooks/useGlobalForm';

const DesktopItem: FC<ListItem> = props => {
  const { stroller, price, disabled } = props;

  const theme = useTheme();

  const navigate = useNavigate();
  const { updateCurrentProduct } = FormContainer.useContainer();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const openDialog = () => {
    setDialogIsOpen(true);
  };

  const closeDialog = () => {
    setDialogIsOpen(false);
  };

  const strollerImg = stroller?.productImages?.[0]?.URL;

  const handleReserveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    updateCurrentProduct({ product: stroller });
    updateCurrentProduct({ step: 1 });
    navigate('/accessories');
    e.stopPropagation();
  };

  return (
    <Fragment>
      <Divider sx={{ my: 2, backgroundColor: 'primary.darker' }} />

      <Box
        display="flex"
        flex={1}
        my={1}
        justifyContent="space-between"
        sx={{
          cursor: 'pointer',
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={openDialog}
      >
        <Box display="flex" flex={1} alignItems="center">
          <Box width="130px" height="130px" mr={2}>
            {strollerImg && (
              <Image src={strollerImg} alt="Stroller preview" imgFluid />
            )}
          </Box>
          <Text fontSize={30} fontWeight={700} color="primary.darker">
            {stroller?.ProductName}
          </Text>
          <Text
            fontSize={20}
            fontWeight={300}
            color="grey.700"
            sx={{ textDecoration: 'underline' }}
            ml={4}
          >
            More details
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Text fontSize={18} fontWeight={300} color="grey.800">
            <Text fontSize={27} fontWeight={600} color="primary.darker">
              {price}
            </Text>
          </Text>

          <Button
            variant="contained"
            color="secondary"
            sx={{
              py: 1,
              px: 8,
              textTransform: 'none',
              ...(disabled && {
                backgroundColor: `${theme.palette.grey[600]} !important`,
                color: `${theme.palette.common.white} !important`,
              }),
            }}
            onClick={handleReserveClick}
            disabled={disabled}
          >
            {disabled ? 'Unavailable' : 'Reserve'}
          </Button>
        </Box>
      </Box>

      <Dialog open={dialogIsOpen} handleClose={closeDialog} maxWidth={'md'}>
        <ItemModal
          {...props}
          handleReserveClick={handleReserveClick}
          onClose={closeDialog}
        />
      </Dialog>
    </Fragment>
  );
};

export default DesktopItem;
