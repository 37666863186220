import { AccessoriesProductsDTO } from 'ks-common';

const sortAccessories = (
  a?: AccessoriesProductsDTO,
  b?: AccessoriesProductsDTO,
) => {
  const aPrice = a?.accessories?.Price || 0;
  const bPrice = b?.accessories?.Price || 0;

  const aDisplayName = a?.accessories?.DisplayName || '';
  const bDisplayName = b?.accessories?.DisplayName || '';

  if (aPrice === bPrice) {
    return aDisplayName.localeCompare(bDisplayName);
  }

  return aPrice - bPrice;
};

export default sortAccessories;
