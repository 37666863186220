import { useMemo } from 'react';
import {
  Form,
  LocationType,
  AccessoriesFormErrors,
} from 'app/hooks/useGlobalForm';
import useLocalStorageState from 'use-local-storage-state';
import datesValidator from 'utils/validators/datesValidator';
import isToday from 'date-fns/isToday';
import {
  checkVacationHomeFieldsErrors,
  checkVacationHomeZipError,
} from 'utils/validators/vacationHomeValidator';
import { SystemParameterInterface } from 'ks-common';
import { DateObject } from 'react-multi-date-picker';

interface Validations {
  dateRangeError?: string;
  deliveryDateIsToday?: boolean;
  requiredAccessoriesFormErrors?: Partial<AccessoriesFormErrors>;
  accessoriesFormErrors?: Partial<AccessoriesFormErrors>;
  deliveryZipError?: string;
  returnZipError?: string;
  nameTagsError: boolean;
}

type FormValidations = (
  products: Partial<Form>[],
  currentProduct?: Partial<Form>,
) => Validations;

const useGlobalFormValidations: FormValidations = (
  products,
  currentProduct,
) => {
  const [systemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');

  const deliveryDateIsToday =
    !!currentProduct?.deliveryDate &&
    isToday(
      new DateObject({
        date: currentProduct.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const dateRangeError = useMemo(() => {
    const { deliveryDate, returnDate } = currentProduct || {};
    if (deliveryDateIsToday) {
      return 'Please review and enter a valid delivery date.';
    }

    if (deliveryDate && returnDate) {
      return datesValidator(deliveryDate, returnDate);
    }
    return '';
  }, [currentProduct]);

  const requiredAccessoriesFormErrors = useMemo(() => {
    const errors: Partial<AccessoriesFormErrors> = {};

    if (!currentProduct?.accessories?.nameTag) {
      errors.nameTag = 'Please enter your family name';
    }

    if (!currentProduct?.deliveryLocation?.location) {
      errors.deliveryLocation = 'Please select a delivery location';
    }

    if (!currentProduct?.returnLocation?.location) {
      errors.returnLocation = 'Please select a return location';
    }

    if (!currentProduct?.deliveryLocation?.time) {
      errors.deliveryTime = 'Please select a delivery location time';
    }

    if (!currentProduct?.returnLocation?.time) {
      errors.returnTime = 'Please select a return location time';
    }

    if (
      currentProduct?.deliveryLocation?.location === LocationType.VacationHome
    ) {
      const deliveryVacationHome =
        currentProduct?.deliveryLocation?.vacationHome;

      const deliveryVacationErrors = checkVacationHomeFieldsErrors({
        isSubmit: true,
        vacationHomeForm: deliveryVacationHome,
        systemParams,
      });

      if (deliveryVacationErrors) {
        errors.deliveryVacationHomeFields = deliveryVacationErrors;
      }
    }

    if (
      currentProduct?.returnLocation?.location === LocationType.VacationHome
    ) {
      const returnVacationHome = currentProduct?.returnLocation?.vacationHome;

      const returnVacationErrors = checkVacationHomeFieldsErrors({
        isSubmit: true,
        vacationHomeForm: returnVacationHome,
        systemParams,
      });

      if (returnVacationErrors) {
        errors.returnVacationHomeFields = returnVacationErrors;
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    return undefined;
  }, [currentProduct]);

  const accessoriesFormErrors = useMemo(() => {
    const errors: Partial<AccessoriesFormErrors> = {};

    if (
      currentProduct?.deliveryLocation?.location === LocationType.Resort &&
      !currentProduct?.deliveryLocation?.resort
    ) {
      errors.deliveryLocationResort = 'Please select a resort';
    }
    if (
      currentProduct?.returnLocation?.location === LocationType.Resort &&
      !currentProduct?.returnLocation?.resort
    ) {
      errors.returnLocationResort = 'Please select a resort';
    }

    if (
      currentProduct?.deliveryLocation?.location === LocationType.VacationHome
    ) {
      const deliveryVacationHome =
        currentProduct?.deliveryLocation?.vacationHome;

      const deliveryVacationErrors = checkVacationHomeFieldsErrors({
        isSubmit: false,
        vacationHomeForm: deliveryVacationHome,
        systemParams,
      });

      if (deliveryVacationErrors) {
        errors.deliveryVacationHomeFields = deliveryVacationErrors;
      }
    }

    if (
      currentProduct?.returnLocation?.location === LocationType.VacationHome
    ) {
      const returnVacationHome = currentProduct?.returnLocation?.vacationHome;

      const returnVacationErrors = checkVacationHomeFieldsErrors({
        isSubmit: false,
        vacationHomeForm: returnVacationHome,
        systemParams,
      });

      if (returnVacationErrors) {
        errors.returnVacationHomeFields = returnVacationErrors;
      }
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    return undefined;
  }, [currentProduct]);

  const deliveryZipError = useMemo(() => {
    if (currentProduct?.deliveryLocation?.location !== 'vacationHome')
      return '';

    return checkVacationHomeZipError({
      vacationHomeForm: currentProduct?.deliveryLocation?.vacationHome,
      systemParams,
    });
  }, [currentProduct]);

  const returnZipError = useMemo(() => {
    if (currentProduct?.returnLocation?.location !== 'vacationHome') return '';

    return checkVacationHomeZipError({
      vacationHomeForm: currentProduct?.returnLocation?.vacationHome,
      systemParams,
    });
  }, [currentProduct]);

  const nameTagsError = useMemo(() => {
    return products.some(prod => !prod.accessories?.nameTag);
  }, [products]);

  const validations = {
    dateRangeError,
    deliveryDateIsToday,
    requiredAccessoriesFormErrors,
    accessoriesFormErrors,
    deliveryZipError,
    returnZipError,
    nameTagsError,
  };

  return validations;
};

export default useGlobalFormValidations;
