import { FC, ReactNode } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box, { BoxProps } from '@mui/material/Box';

interface Props {
  children?: ReactNode;
  boxProps?: BoxProps;
}
const BlueWrapper: FC<Props> = ({ children, boxProps }) => {
  return (
    <Box
      {...boxProps}
      sx={[
        {
          borderRadius: '22px',
          overflow: 'hidden',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'primary.darker',
        },
        ...(!!boxProps?.sx && Array.isArray(boxProps.sx)
          ? boxProps.sx
          : [boxProps?.sx]),
      ]}
    >
      <Box bgcolor="primary.darker">
        <Text
          fontWeight={700}
          fontSize={20}
          color="common.white"
          padding="20px"
          align="center"
        >
          Important
        </Text>
      </Box>

      <Box padding="0 20px 20px">{children}</Box>
    </Box>
  );
};

export default BlueWrapper;
