import { FC, useState, useMemo, useEffect } from 'react';
import Footer from 'app/components/Footer';
import Box from '@mui/material/Box';
import { Text } from '@concepta/react-material-ui';
import TypesMenu from 'app/components/TypesMenu';
import useGetAllProducts from 'app/hooks/useGetAllProducts';
import StrollerOverlay from 'app/components/StrollerOverlay';
import { ProductInterface } from 'ks-common';
import Header from 'app/components/Header';
import ReserveDates from 'app/components/ReserveDates';
import castleBg from 'assets/images/castle-bg-mobile.jpg';

export interface StrollerType {
  name: string;
  type: number;
}

const HomeMobile: FC = () => {
  const { allProducts } = useGetAllProducts();

  const [strollerType, setStrollerType] = useState<StrollerType | null>();

  useEffect(() => {
    if (strollerType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [strollerType]);

  const closeModal = () => {
    setStrollerType(null);
  };

  const strollersList: ProductInterface[] = useMemo(() => {
    if (!allProducts || !strollerType?.type) return [];

    return allProducts
      ?.filter((stl: ProductInterface) => stl.TypeProduct === strollerType.type)
      ?.sort(
        (a: ProductInterface, b: ProductInterface) =>
          a.Nights1to3 - b.Nights1to3,
      );
  }, [strollerType, allProducts]);

  return (
    <Box minHeight="100vh">
      <Header />
      <Box>
        <Text
          color="primary.main"
          fontSize={22}
          my={2}
          fontWeight={700}
          align="center"
          variant="h2"
        >
          Welcome to Kingdom Strollers
        </Text>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        py={5}
        px={15}
        sx={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: `url('${castleBg}')`,
          '@media (max-width: 1100px)': {
            px: 2,
          },
        }}
      >
        <ReserveDates showContinue />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight={300}
        py={6}
      >
        <Box px={2}>
          <TypesMenu setStrollerType={setStrollerType} />
        </Box>
      </Box>

      <Footer />
      <StrollerOverlay
        show={!!strollerType && !!strollersList?.length}
        type={strollerType?.name}
        closeModal={closeModal}
        strollersList={strollersList}
      />
    </Box>
  );
};

export default HomeMobile;
