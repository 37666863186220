import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Form } from 'app/hooks/useGlobalForm';
import FormLabel from 'app/components/FormLabel';
import NameTag from 'app/screens/Accessories/Components/NameTag';
import CommentsAndNotes from './CommentsAndNotes';
import Accessories from './Accessories';
import Insurance from './Insurance';
import Location from './Location';
export interface FormErrors {
  nameTag: string;
}
interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
}

const ProductDisplay: FC<Props> = ({ product, index, readonly }) => {
  const [formErrors, setFormErrors] = useState<Partial<FormErrors>>();

  const checkFormErrors = () => {
    setFormErrors(undefined);

    const errors: Partial<FormErrors> = {};
    if (!product.accessories?.nameTag) {
      errors.nameTag = 'Please enter your family name';
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return errors;
    }

    return undefined;
  };

  const nameTag = product.accessories?.nameTag;

  useEffect(() => {
    checkFormErrors();
  }, [nameTag]);

  return (
    <Box py={0} display="flex" flexDirection="column">
      <Location product={product} index={index} readonly={readonly} />

      <Divider sx={{ mt: 2, backgroundColor: 'primary.darker' }} />

      <Box px={1.5} mt={2}>
        <FormLabel>Selected Accessories:</FormLabel>

        <Accessories product={product} index={index} readonly={readonly} />

        <Insurance product={product} index={index} readonly={readonly} />

        <NameTag
          product={product}
          index={index}
          error={formErrors?.nameTag}
          variant="standard"
          isReview
          readonly={readonly}
        />

        <CommentsAndNotes product={product} index={index} readonly={readonly} />
      </Box>
    </Box>
  );
};

export default ProductDisplay;
