import { useState } from 'react';
import { createContainer } from 'unstated-next';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import useLocalStorageState from 'use-local-storage-state';
import { ResortInterface, ProductInterface } from 'ks-common';

export interface GlobalState {
  resortsList: ResortInterface[];
  currentCategory: number;
}

const useGlobalState = () => {
  const [globalState, setGlobalState] =
    useLocalStorageState<Partial<GlobalState>>('globalStateStorage');
  const [allProducts, setAllProducts] = useState<ProductInterface[]>();

  const checkArray = (a: unknown, b: unknown) => (isArray(b) ? b : undefined);

  const updateGlobalState = (value: Partial<GlobalState>) => {
    const updatedGlobalState = { ...mergeWith(globalState, value, checkArray) };
    setGlobalState(updatedGlobalState);
  };

  return {
    globalState,
    setGlobalState,
    updateGlobalState,
    allProducts,
    setAllProducts,
  };
};

export const GlobalStateContainer = createContainer(useGlobalState);
