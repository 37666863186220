import { FC, useRef, useEffect } from 'react';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

interface ReCaptchaOptions {
  sitekey: string;
  callback: (token: string | null) => void;
  'expired-callback'?: () => void;
}

interface ReCaptchaInstance {
  render: (element: Element | string, options: ReCaptchaOptions) => number;
}

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
  }
}

interface Props {
  onChange: (token: string | null) => void;
  expiredCallback: () => void;
}

const Recaptcha: FC<Props> = ({ onChange, expiredCallback }) => {
  const recaptchaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const renderRecaptcha = () => {
      if (typeof window.grecaptcha === 'undefined') {
        // Retry rendering after a short delay if grecaptcha is not available. This was added to avoid and error "reCAPTCHA has already been rendered in this element"
        setTimeout(renderRecaptcha, 100);
        return;
      }

      if (
        RECAPTCHA_KEY &&
        recaptchaRef.current &&
        !recaptchaRef.current.hasChildNodes()
      ) {
        window?.grecaptcha?.render?.(recaptchaRef.current, {
          sitekey: RECAPTCHA_KEY,
          callback: onChange,
          'expired-callback': expiredCallback,
        });
      }
    };

    renderRecaptcha();
  }, [onChange]);

  return <div ref={recaptchaRef} />;
};

export default Recaptcha;
