import { DateObject } from 'react-multi-date-picker';

type FormatDate = (date?: string | null, format?: string) => string;

const formatDate: FormatDate = (date, format) => {
  if (!date) return '';

  return new DateObject({
    date: date,
    format: 'YYYY-MM-DD',
  }).format(format || 'MM/DD/YYYY');
};

export default formatDate;
