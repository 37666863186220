import { FC, useEffect } from 'react';
import dropin, { Dropin } from 'braintree-web-drop-in';
import Box from '@mui/material/Box';
import { Text } from '@concepta/react-material-ui';
import useGetBraintreeToken from 'app/hooks/useGetBraintreeToken';
import { isMobile } from 'utils/isMobile';

interface Props {
  braintreeInstance: Dropin | undefined;
  setBraintreeInstance: React.Dispatch<
    React.SetStateAction<Dropin | undefined>
  >;
}

const Braintree: FC<Props> = ({ braintreeInstance, setBraintreeInstance }) => {
  const { data: braintreeData } = useGetBraintreeToken();
  const brainTreeToken = braintreeData?.ClientAuthorization;

  const show = !!brainTreeToken;
  const token = brainTreeToken;

  useEffect(() => {
    if (show) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: token,
            container: '#braintree-drop-in',
            paypal: {
              flow: 'vault',
            },
          },
          function (error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          },
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [show]);

  return (
    <Box>
      {!isMobile && (
        <Text
          fontSize={36}
          fontWeight={700}
          color="primary.darker"
          align="left"
          mt={4}
        >
          Please select the payment mode
        </Text>
      )}

      <Box
        mt={isMobile ? 4 : 0}
        id={'braintree-drop-in'}
        sx={{ display: `${show ? 'block' : 'none'}` }}
      />
    </Box>
  );
};

export default Braintree;
