export enum PageContentEnum {
  'About' = 'About',
  'FAQs' = 'FAQs',
  'Rental Agreement' = 'Rental Agreement',
  'Contact Us' = 'Contact Us',
  'Privacy Policy' = 'Privacy Policy',
  'Reviews' = 'Reviews',
  'Articles' = 'Articles',
  'Facebook' = 'Facebook',
}
