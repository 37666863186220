import { FC, useRef, Fragment } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import StrollersCard from 'app/components/StrollersCard';
import AccessoriesForm, {
  AccessoriesProps,
} from '../Components/AccessoriesForm';
import { FormContainer, LocationMoment } from 'app/hooks/useGlobalForm';
import { AccessoriesScreenContainer } from 'app/hooks/useAccessoriesScreenState';
import Header from 'app/components/Header';
import ReserveDates from 'app/components/ReserveDates';
import Footer from 'app/components/Footer';
import sectionBg from 'assets/images/section-bg.jpg';
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
import LocationWarnings from '../Components/Location/LocationWarnings';
import BlueWrapper from 'app/components/BlueWrapper';

const AcessoriesDesktop: FC<AccessoriesProps> = props => {
  const { isModal } = props;

  const { currentProduct } = FormContainer.useContainer();
  const { deliveryInstructions, returnInstructions, strollerIsAvailable } =
    AccessoriesScreenContainer.useContainer();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(containerRef, {});
  const isVisible = !!entry?.isIntersecting;

  const hasDeliveryInstructions = !!Object.values(deliveryInstructions).length;
  const hasReturnInstructions = !!Object.values(returnInstructions).length;

  const hasInstructions = hasDeliveryInstructions || hasReturnInstructions;

  return (
    <Box>
      {!isModal && (
        <Fragment>
          <Box
            display="flex"
            justifyContent="center"
            py={4}
            px={15}
            sx={{
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundImage: `url('${sectionBg}')`,
              '@media (max-width: 1100px)': {
                px: 2,
              },

              transition: 'top .5s ease-in-out',

              flex: 1,
              position: 'fixed',
              top: isVisible ? -170 : 0,
              left: 0,
              right: 0,
              zIndex: 5,
              boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
              paddingTop: 2,
              paddingBottom: 2,
            }}
          >
            <ReserveDates shrink />
          </Box>

          <Header />

          <Box
            ref={containerRef}
            display="flex"
            position="relative"
            justifyContent="center"
            py={4}
            px={15}
            sx={{
              zIndex: 5,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundImage: `url('${sectionBg}')`,
              '@media (max-width: 1100px)': {
                px: 2,
              },
            }}
          >
            <ReserveDates />
          </Box>
        </Fragment>
      )}

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Container maxWidth="xl" sx={{ mt: 8 }}>
          <Box display="flex" justifyContent="center" flex={1} px={4}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="flex-start"
              pr={4}
              pb={4}
            >
              <StrollersCard
                unavailable={
                  !!currentProduct?.deliveryDate &&
                  !!currentProduct?.returnDate &&
                  !strollerIsAvailable
                }
              />

              {hasInstructions && (
                <BlueWrapper boxProps={{ mt: 4 }}>
                  {hasDeliveryInstructions && (
                    <LocationWarnings id={LocationMoment['DeliveryLocation']} />
                  )}

                  {hasReturnInstructions && (
                    <LocationWarnings id={LocationMoment['ReturnLocation']} />
                  )}
                </BlueWrapper>
              )}
            </Box>
            <Box display="flex" flex={2}>
              <AccessoriesForm {...props} />
            </Box>
          </Box>
        </Container>
      </Box>

      {!isModal && <Footer />}
    </Box>
  );
};

export default AcessoriesDesktop;
