import { FC, Fragment, useState } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import LeftRightInfo from 'app/components/LeftRightInfo';
import IconButton from '@mui/material/IconButton';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { AccessoriesProductsDTO } from 'ks-common';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import AlertDialog, { AlertType } from 'app/components/AlertDialog';
import { isMobile } from 'utils/isMobile';
import sortAccessories from 'utils/sortAccessoriesByPrice';

interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
}

const Accessories: FC<Props> = ({ product, index, readonly }) => {
  const { updateCurrentProduct } = FormContainer.useContainer();
  const [removeDialogOpen, setRemoveDialogOpen] = useState<number | false>(
    false,
  );

  const allProductAccessories = product?.product?.accessoriesProducts;
  const selectedIds = product?.accessories?.accessories || [];
  const selectedAccessories = selectedIds?.map(id =>
    allProductAccessories?.find?.(
      (accssr: AccessoriesProductsDTO) => id === accssr?.AccessoriesID,
    ),
  );

  const handleClose = () => {
    setRemoveDialogOpen(false);
  };
  const handleOpen = (id: number) => () => {
    if (readonly) return;

    setRemoveDialogOpen(id);
  };

  const handleRemove = () => {
    const newSeleced = [...selectedIds];
    const accessorieIndex =
      typeof removeDialogOpen === 'number' &&
      newSeleced.indexOf(removeDialogOpen);

    if (typeof accessorieIndex === 'number' && accessorieIndex >= 0) {
      newSeleced.splice(accessorieIndex, 1);
    }

    updateCurrentProduct(
      {
        accessories: {
          accessories: newSeleced,
        },
      },
      index,
    );
  };

  return (
    <Fragment>
      <Box mb={3}>
        {selectedAccessories?.sort(sortAccessories).map(accessory => {
          const accessoryInfo = accessory?.accessories;
          if (!accessoryInfo) return null;

          return (
            <LeftRightInfo
              key={accessory.AccessoriesID}
              sx={{ mt: 1 }}
              left={
                <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                  {accessoryInfo.Name}
                </Text>
              }
              right={
                <Box display="flex" alignItems="center">
                  <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                    {accessoryInfo.Price === 0
                      ? 'Free'
                      : `$${accessoryInfo.Price}`}
                  </Text>
                  {!readonly && (
                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={handleOpen(accessoryInfo.AccessoriesID)}
                    >
                      <DeleteOutline
                        sx={{ fontSize: isMobile ? '19px' : '22px' }}
                      />
                    </IconButton>
                  )}
                </Box>
              }
            />
          );
        })}
        {selectedIds?.length === 0 && (
          <Text
            fontSize={isMobile ? 14 : 16}
            fontWeight={400}
            color="grey.400"
            align={isMobile ? 'center' : 'left'}
          >
            No accessories selected
          </Text>
        )}
      </Box>
      <AlertDialog
        open={!!removeDialogOpen}
        title="Attention"
        text="Are you sure you want to remove this accessory?"
        onClose={handleClose}
        onConfirm={handleRemove}
        alertType={AlertType.CONFIRMATION}
      />
    </Fragment>
  );
};

export default Accessories;
