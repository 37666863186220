import paymentAmex from 'assets/images/payment-amex.png';
import paymentDiscover from 'assets/images/payment-discover.png';
import paymentMastercard from 'assets/images/payment-mastercard.png';
import paymentPaypal from 'assets/images/payment-paypal.png';
import paymentVisa from 'assets/images/payment-visa.png';

export const paymentImgs = [
  { key: 'visa', img: paymentVisa, maxWidth: 86 },
  { key: 'amex', img: paymentAmex, maxWidth: 53 },
  { key: 'master', img: paymentMastercard, maxWidth: 74 },
  { key: 'discover', img: paymentDiscover, maxWidth: 117 },
  { key: 'paypal', img: paymentPaypal, maxWidth: 140 },
];
