import { Form, LocationType } from 'app/hooks/useGlobalForm';
import { OrderQuoteItemInputInterface } from 'ks-common';

type ProductParser = (
  product: Partial<Form>,
) => OrderQuoteItemInputInterface | undefined;

const resortId = (
  location: Form['deliveryLocation'] | Form['returnLocation'] | undefined,
) => {
  const locationType = location?.location;
  const isVacationHome = locationType === LocationType.VacationHome;
  const isAirport = locationType === LocationType.Airport;

  if (isAirport) {
    return 8415;
  }
  if (isVacationHome) {
    return 243;
  }

  return location?.resort?.ResortId;
};

const productParserToOrderRequest: ProductParser = product => {
  const deliveryLocation = product.deliveryLocation;
  const returnLocation = product.returnLocation;

  if (!deliveryLocation || !returnLocation) return;

  const deliveryResortId = resortId(deliveryLocation);
  const returnResortId = resortId(returnLocation);

  if (
    !product.product ||
    !deliveryResortId ||
    !returnResortId ||
    !product.deliveryDate ||
    !product.returnDate ||
    !deliveryLocation?.time ||
    !returnLocation?.time
  )
    return;

  return {
    ProductId: product.product.ProductID,
    DeliveryResortId: deliveryResortId,
    PickUpResortId: returnResortId,
    DeliveryDate: product.deliveryDate as unknown as Date,
    PickUpDate: product.returnDate as unknown as Date,
    DeliveryTime: deliveryLocation?.time,
    PickUpTime: returnLocation?.time,
    Insurance: product.accessories?.insurance,
    SpecialNotes: product.accessories?.commentsAndNotes,
    Accessories:
      product.accessories?.accessories?.map(accessory => ({
        AccessoriesID: accessory,
      })) || [],
    NameTag: product.accessories?.nameTag,
  };
};

export default productParserToOrderRequest;
