import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { FormContainer } from 'app/hooks/useGlobalForm';
import Footer from 'app/components/Footer';
import Header from 'app/components/Header';
import useScrollTop from 'app/hooks/useScrollTop';
import OrderNumber from './OrderNumber';
import { OrderCheckoutOutputInterface } from 'ks-common';
import ReviewOrderDesktopDisplay from '../ReviewOrder/ReviewOrderDesktop/ReviewOrderDesktopDisplay';

const ThankYouDesktop: FC = () => {
  useScrollTop(true);

  const { thankYouProducts } = FormContainer.useContainer();

  const location = useLocation();
  const state = location?.state as OrderCheckoutOutputInterface;
  const orderId = state?.OrderId;
  const orderQuote = state?.OrderQuoteCalculated;

  return (
    <Box minHeight="100vh">
      <Header />

      <OrderNumber orderNumber={orderId} />

      {!!thankYouProducts.length && (
        <ReviewOrderDesktopDisplay readonly orderQuoteCalculated={orderQuote} />
      )}

      <Footer />
    </Box>
  );
};

export default ThankYouDesktop;
