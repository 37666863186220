import { AgentForm } from 'app/hooks/useOrderCheckout';
import { AgentInterface } from 'ks-common';

type AgentParser = (agent: AgentForm) => AgentInterface;

const agentParserToCheckout: AgentParser = (agent: AgentForm) => {
  return {
    AgentName: `${agent.agentFirstName} ${agent.agentLastName}`,
    AgencyName: agent.agencyName,
    PPCA: agent.payPalCommissionAccount,
    TravelAgentNumber: agent.iataClia,
    Phone: agent.agentPhoneNumber,
    AgentEmail: agent.agentEmail,
  };
};

export default agentParserToCheckout;
