import { FC, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export interface Props {
  children?: ReactNode;
}
const HeaderButton: FC<Props & BoxProps> = props => {
  const { children, sx, ...otherProps } = props;

  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          fontWeight: 400,
          padding: 1,
          color: 'primary.darker',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          '&:not(:last-child)': {
            marginRight: 2,
          },
          '@media (min-width: 1260px)': {
            fontSize: 16,
          },
          '@media (min-width: 1380px)': {
            fontSize: 18,
          },
          '@media (min-width: 1460px)': {
            fontSize: 20,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...otherProps}
    >
      {children}
    </Box>
  );
};

export default HeaderButton;
