import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { toast } from 'react-toastify';

const useGetResort = (resortId?: number) => {
  const { get } = useDataProvider();

  const getResortRequest = () => {
    return get({
      uri: `/resort/${resortId}`,
    });
  };

  const { data, execute, isPending, error } = useQuery(
    getResortRequest,
    false,
    {
      onError: error => {
        // Todo: Fix error response in rockets
        const errorRes = error as unknown as {
          response: { data: { message: string } };
        };

        toast.error(errorRes?.response?.data?.message || error?.message);
      },
    },
  );

  return { data, execute, isPending, error };
};

export default useGetResort;
