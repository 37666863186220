import { FC } from 'react';
import { useNavigate } from '@concepta/react-router';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const drawerWidth = '66vw';

const EDIT_LINK = process.env.REACT_APP_EDIT_ORDER_LINK as string;

const HeaderMobile: FC<Props> = ({ mobileOpen, handleDrawerToggle }) => {
  const navigate = useNavigate();

  const goToEdit = () => {
    window.location.replace(EDIT_LINK);
  };

  const navItems = [
    {
      icon: <HomeOutlinedIcon fontSize="inherit" />,
      text: 'Home',
      onClick: () => navigate('/'),
    },
    {
      icon: <GroupsOutlinedIcon fontSize="inherit" />,
      text: 'About Us',
      onClick: () => navigate('/about-us'),
    },
    {
      icon: <QuestionAnswerOutlinedIcon fontSize="inherit" />,
      text: 'Testimonials',
      onClick: () => navigate('/testimonials'),
    },
    {
      icon: <HelpOutlineOutlinedIcon fontSize="inherit" />,
      text: 'FAQs',
      onClick: () => navigate('/faqs'),
    },
    {
      icon: <HandshakeOutlinedIcon fontSize="inherit" />,
      text: 'Rental Agreement',
      onClick: () => navigate('/rental-agreement'),
    },
    {
      icon: <DevicesOutlinedIcon fontSize="inherit" />,
      text: 'Contact Us',
      onClick: () => navigate('/contact-us'),
    },
    {
      icon: <NoteAltOutlinedIcon fontSize="inherit" />,
      text: 'Edit Order',
      onClick: goToEdit,
    },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} pl={1} pt={7} position="relative">
      <List>
        {navItems.map(item => (
          <ListItem
            key={item.text}
            disablePadding
            sx={{ mb: '20px' }}
            onClick={item.onClick}
          >
            <ListItemButton>
              <ListItemIcon
                sx={{
                  minWidth: '46px',
                  color: 'primary.darker',
                  fontSize: '30px',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                disableTypography
                sx={{
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '20px',
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          color: theme => theme.palette.grey[800],
          fontSize: '30px',
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
    >
      {drawer}
    </Drawer>
  );
};

export default HeaderMobile;
