import { FC, useEffect, useState, useRef, useMemo } from 'react';
import { useGestureResponder, StateType } from 'react-gesture-responder';
import { useSpring, animated } from '@react-spring/web';
import Box from '@mui/material/Box/Box';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Circle from '@mui/icons-material/Circle';
import { FormContainer } from 'app/hooks/useGlobalForm';
import { Text } from '@concepta/react-material-ui';
import { isMobile } from 'utils/isMobile';

interface StrollerImgAndName {
  image: string;
  name: string;
}

const Carousel: FC = () => {
  const { products } = FormContainer.useContainer();

  const strollers: StrollerImgAndName[] = useMemo(() => {
    const strollersData: StrollerImgAndName[] = [];

    products.forEach(stroller => {
      const url = stroller.product?.productImages?.[0]?.URL;
      const name = stroller.product?.ProductName;

      if (url && name) {
        strollersData.push({ image: url, name });
      }
    });

    return strollersData;
  }, [products]);

  const [width, setWidth] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [{ x }, set] = useSpring(() => ({ x: 0 }));

  const ref = useRef<null | HTMLDivElement>(null);

  const { bind } = useGestureResponder({
    onStartShouldSet: () => true,
    onMove: (event: StateType) => {
      set({ x: currentImage * -width + event.delta[0] });
    },
    onRelease: (event: StateType) => {
      const threshold = width / 3;
      let newCurrentImage = currentImage;

      if (event.delta[0] > threshold && currentImage > 0) {
        newCurrentImage = currentImage - 1;
      } else if (
        event.delta[0] < -threshold &&
        currentImage < strollers.length - 1
      ) {
        newCurrentImage = currentImage + 1;
      }

      setCurrentImage(newCurrentImage);
      set({ x: newCurrentImage * -width });
    },
  });

  const handleChevronClick = (n: number) => () => {
    const newCurrentImage = currentImage + n;

    if (newCurrentImage > -1 && newCurrentImage < strollers.length) {
      setCurrentImage(newCurrentImage);
      set({ x: newCurrentImage * -width });
    }
  };

  useEffect(() => {
    if (ref?.current?.clientWidth) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);

  return (
    <Box>
      <Box
        {...bind}
        sx={{
          height: 'auto',
          overflow: 'hidden',
        }}
        display="flex"
        ref={ref}
      >
        {strollers.map((stroller, index) => (
          <animated.div
            key={index}
            style={{
              x,
              height: 'auto',
              width: width,
              minWidth: width,
            }}
          >
            <Box>
              <Box
                sx={{
                  backgroundImage: `url(${stroller.image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: width,
                  width: width,
                  minWidth: width,
                }}
              />
              <Text
                mt={1}
                fontSize={isMobile ? 12 : 16}
                fontWeight={400}
                color="primary.darker"
                align="center"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {stroller.name}
              </Text>
            </Box>
          </animated.div>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: '4px' }}
      >
        <ChevronLeft
          onClick={handleChevronClick(-1)}
          sx={{
            fontSize: isMobile ? '17px' : '19px',
            color: 'grey.600',
            cursor: 'pointer',
          }}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          {strollers.map((_, i) => (
            <Circle
              key={i}
              sx={{
                fontSize: isMobile ? '6px' : '8px',
                mx: '1px',
                color: currentImage === i ? 'primary.darker' : 'grey.400',
              }}
            />
          ))}
        </Box>
        <ChevronRight
          onClick={handleChevronClick(1)}
          sx={{
            fontSize: isMobile ? '17px' : '19px',
            color: 'grey.600',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
};

export default Carousel;
