import { FC, useMemo } from 'react';
import { Image, Text } from '@concepta/react-material-ui';
import differenceInDays from 'date-fns/differenceInDays';
import Box from '@mui/material/Box';
import BlueCard from 'app/components/BlueCard';
import { FormContainer } from 'app/hooks/useGlobalForm';
import priceCalculator from 'utils/priceCalculator';
import { DateObject } from 'react-multi-date-picker';

const StrollerCard: FC = () => {
  const { currentProduct } = FormContainer.useContainer();

  const stroller = currentProduct?.product;
  const strollerImg = stroller?.productImages?.[0]?.URL;

  const daysDifference =
    currentProduct?.returnDate &&
    currentProduct?.deliveryDate &&
    differenceInDays(
      new DateObject({
        date: currentProduct.returnDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
      new DateObject({
        date: currentProduct.deliveryDate,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  const calculatePrice = useMemo(() => {
    if (!stroller || !daysDifference) return;

    return priceCalculator({ stroller, daysDifference });
  }, [daysDifference, stroller]);

  return (
    <BlueCard
      sx={{
        mt: 4,
        mx: 2,
        py: 2,
      }}
    >
      <Box display="flex" alignItems="center" px={2}>
        <Box width="88px" height="88px" mr={3}>
          {strollerImg && (
            <Image src={strollerImg} alt="Stroller preview" imgFluid />
          )}
        </Box>

        <Box display="flex" flexDirection="column">
          <Text fontSize={20} fontWeight={700} color="primary.darker">
            {stroller?.ProductName}
          </Text>

          <Box display="flex" alignItems="center">
            <Text fontSize={18} fontWeight={700} color="primary.darker">
              {calculatePrice}
            </Text>
          </Box>
          <Text fontSize={14} fontWeight={700} color="grey.400">
            {daysDifference ? `${daysDifference} nights` : ''}
          </Text>
        </Box>
      </Box>
    </BlueCard>
  );
};

export default StrollerCard;
