import { CustomerForm } from 'app/hooks/useOrderCheckout';
import { CustomersCreateableInterface } from 'ks-common';

type CustomerParser = (customer: CustomerForm) => CustomersCreateableInterface;

const customerParserToCheckout: CustomerParser = customer => {
  return {
    FullName: `${customer.firstName} ${customer.lastName}`,
    CellPhone: customer.primaryCellPhone,
    Address1: customer.billingAddress1,
    City: customer.city,
    State: customer.state,
    Zip: customer.zipCode,
    Country: customer.country,
    Email: customer.emailAddress,
    ...(!!customer.secondaryCellPhone && {
      SecondCellPhone: customer.secondaryCellPhone,
    }),
    ...(!!customer.secondaryEmail && { SecondEmail: customer.secondaryEmail }),
    ...(!!customer.billingAddress2 && { Address2: customer.billingAddress2 }),
  };
};

export default customerParserToCheckout;
