import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Image, Text } from '@concepta/react-material-ui';
import useLocalStorageState from 'use-local-storage-state';
import { SystemParameterInterface } from 'ks-common';
import BlueCard from '../BlueCard';

interface Props {
  name: string;
  imageParamName: string;
  widthSize: number;
  onClick: () => void;
}

const MobileTypeItem: FC<Props> = ({
  name,
  imageParamName,
  widthSize,
  onClick,
}) => {
  const [systemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');

  const findStrollerImage = (name: string) =>
    systemParams?.find(param => param.ParamName === name)?.ParamValue || '';

  return (
    <Grid item xs={widthSize} onClick={onClick}>
      <BlueCard>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Text
            fontSize={20}
            fontWeight={600}
            color="primary.darker"
            sx={{ my: 1 }}
          >
            {name}
          </Text>

          <Divider sx={{ width: '100%' }} />

          <Box
            p={3}
            height="31.5vw"
            width="31.5vw"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src={findStrollerImage(imageParamName)}
              alt={`Stroller type: ${name}`}
              imgFluid
            />
          </Box>
        </Box>
      </BlueCard>
    </Grid>
  );
};

export default MobileTypeItem;
