import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import ImageDialog from './ImageDialog';

export type CheckboxWithInfoAndValueProps = {
  label?: string;
  info?: {
    image?: string;
    price?: number;
    disabled?: boolean;
  };
};

const CheckboxWithInfoAndValue: FC<
  CheckboxProps & CheckboxWithInfoAndValueProps
> = props => {
  const { label, checked, info, disabled } = props;

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const handleClickOpen = () => {
    if (info?.image) {
      setOpen(true);
      setSelectedImage(info.image);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <Box display="flex" sx={{ opacity: disabled ? 0.5 : 1, ml: '-11px' }}>
        <Box display="flex" flex={1} alignItems="center">
          <MuiCheckbox
            {...props}
            sx={{ ...props.sx }}
            checked={checked}
            disabled={disabled}
          />
          <Box display="flex" alignItems="center">
            {label}{' '}
            {info?.image && (
              <InfoIcon
                onClick={handleClickOpen}
                sx={{
                  marginLeft: '6px',
                  cursor: 'pointer',
                  fontSize: '18px',
                  color: 'primary.darker',
                }}
              />
            )}
          </Box>
        </Box>
        <Box textAlign="right" display="flex" alignItems="center">
          {info?.price === 0 ? 'Free' : `$${info?.price}`}
        </Box>
      </Box>
      <ImageDialog image={selectedImage} open={open} onClose={handleClose} />
    </>
  );
};

export default CheckboxWithInfoAndValue;
