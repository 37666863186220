import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import ReviewOrderMobile from './ReviewOrderMobile/ReviewOrderMobile';
import ReviewOrderDesktop from './ReviewOrderDesktop/ReviewOrderDesktop';
import useOrderQuote from 'app/hooks/useOrderQuote';
import { isMobile } from 'utils/isMobile';
import { gaCartFormatter } from 'utils/GAnalytics/formatters';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

const ENV = process.env.REACT_APP_ENV;

const ReviewOrder: FC = () => {
  const { products, setProducts, setCurrentProductIndex } =
    FormContainer.useContainer();

  const filteredProducts = products.filter(
    (product: Partial<Form>) => product.step === 2,
  );

  const { data, execute, isPending } = useOrderQuote(filteredProducts);
  const [gaSent, setGaSent] = useState(false);
  const [lastObjectQuoted, setLastObjectQuoted] = useState<Partial<Form>[]>([]);

  useEffect(() => {
    const filteredProducts = products.filter(product => product.step === 2);
    setProducts?.(filteredProducts);
    setCurrentProductIndex?.(filteredProducts.length - 1);
  }, []);

  useEffect(() => {
    if (
      !isEqual(products, lastObjectQuoted) &&
      products.every(product => product.step === 2)
    ) {
      execute();
      setLastObjectQuoted(cloneDeep(products));
    }
  }, [products]);

  useEffect(() => {
    if (data && !gaSent) {
      const gaData = gaCartFormatter(data);
      ReactGA.event('view_cart', gaData);
      setGaSent(true);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      {isMobile ? (
        <ReviewOrderMobile orderQuoteCalculated={data} isPending={isPending} />
      ) : (
        <ReviewOrderDesktop orderQuoteCalculated={data} isPending={isPending} />
      )}
    </>
  );
};

export default ReviewOrder;
