import { FC } from 'react';
import { Text } from '@concepta/react-material-ui';
import { useNavigate } from '@concepta/react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KgdCard from 'app/components/KgdCard';
import DeliveryReturnDateFields from 'app/components/DeliveryReturnDateFields';
import { isMobile } from 'utils/isMobile';
import { FormContainer } from 'app/hooks/useGlobalForm';

interface Props {
  showContinue?: boolean;
  shrink?: boolean;
  title?: string;
  hideTitle?: boolean;
  error?: string;
}

const ReserveDates: FC<Props> = ({
  showContinue,
  shrink,
  title = 'Reserve Your Stroller Today!',
  hideTitle,
  error,
}) => {
  const { currentProduct, validations } = FormContainer.useContainer();
  const navigate = useNavigate();

  return (
    <KgdCard grow>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        px={isMobile ? 4 : 6}
        py={shrink ? 2 : isMobile ? 2 : 5}
        alignItems="center"
      >
        {!shrink && !hideTitle && (
          <Text
            fontSize={isMobile ? 20 : 33}
            fontWeight={isMobile ? 500 : 700}
            color="primary.contrastText"
            mb={isMobile ? 0 : 2}
            variant="h1"
          >
            {title}
          </Text>
        )}

        <Box mt={shrink ? 0 : 1.5} width={isMobile ? 'auto' : 600}>
          <DeliveryReturnDateFields
            error={error || validations.dateRangeError}
          />
        </Box>

        {showContinue &&
          currentProduct?.deliveryDate &&
          currentProduct?.returnDate &&
          !validations.dateRangeError && (
            <Button
              variant="contained"
              sx={{ mt: 3, minWidth: isMobile ? 150 : 250 }}
              onClick={() => navigate('/stroller-list')}
              color="secondary"
            >
              Continue
            </Button>
          )}
      </Box>
    </KgdCard>
  );
};

export default ReserveDates;
