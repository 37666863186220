import { FC, useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Text } from '@concepta/react-material-ui';
import TextField from 'app/components/TextField';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import FormLabel from 'app/components/FormLabel';
import useOnClickOutside from 'app/hooks/useOnClickOutside';

interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
}

const CommentsAndNotes: FC<Props> = ({ product, index, readonly }) => {
  const { updateCurrentProduct } = FormContainer.useContainer();

  const [isEditing, setIsEditing] = useState(false);
  const [commentsAndNotes, setCommentsAndNotes] = useState<
    string | undefined
  >();

  useEffect(() => {
    setCommentsAndNotes(product.accessories?.commentsAndNotes);
  }, [index]);

  useEffect(() => {
    if (product.accessories?.commentsAndNotes !== commentsAndNotes) {
      setCommentsAndNotes(product.accessories?.commentsAndNotes);
    }
  }, [product.accessories?.commentsAndNotes]);

  const componentRef = useRef<HTMLElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentsAndNotes(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    inputRef?.current?.click?.();
  };

  const handleConfirm = () => {
    setIsEditing(false);
    updateCurrentProduct(
      {
        accessories: {
          commentsAndNotes,
        },
      },
      index,
    );
  };

  const handleCancel = () => {
    if (isEditing) {
      setIsEditing(false);
      setCommentsAndNotes(product.accessories?.commentsAndNotes);
    }
  };

  useOnClickOutside(componentRef, handleCancel);

  return (
    <Box ref={componentRef}>
      <Box display="flex" alignItems="center" mt={4} onClick={handleEditClick}>
        <FormLabel mb={0}>Comments & Notes:</FormLabel>{' '}
      </Box>
      {!readonly ? (
        <TextField
          label="Comments"
          variant="standard"
          margin="normal"
          fullWidth
          color="info"
          value={commentsAndNotes || ''} // hack to foce value to change if we change selected stroller from one with comments to one without
          onChange={onChange}
          sx={{ m: 0 }}
          inputProps={{ maxLength: 300 }}
          options={{ multiline: true, maxRows: 3 }}
          onClick={handleEditClick}
          ref={inputRef}
          InputLabelProps={{ shrink: !!commentsAndNotes }}
        />
      ) : (
        <Text fontSize={14} fontWeight={400} color="grey.900">
          {commentsAndNotes}
        </Text>
      )}
      {isEditing && (
        <Box textAlign="right" mt={1}>
          <Link
            onClick={handleCancel}
            fontWeight={500}
            sx={{ cursor: 'pointer' }}
          >
            CANCEL
          </Link>
          <Link
            ml={3}
            onClick={handleConfirm}
            fontWeight={500}
            sx={{ cursor: 'pointer' }}
          >
            SAVE
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default CommentsAndNotes;
