import { useEffect } from 'react';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import useLocalStorageState from 'use-local-storage-state';
import { SystemParameterInterface } from 'ks-common';
import { toast } from 'react-toastify';

const useGetSystemParameter = () => {
  const [, setSystemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');

  const { get } = useDataProvider();

  const getSystemParam = () =>
    get({
      uri: '/system-parameter',
    });

  const { execute } = useQuery(getSystemParam, false, {
    onSuccess: data => {
      if (data) setSystemParams(data);
    },
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  useEffect(() => {
    execute();
  }, []);
};

export default useGetSystemParameter;
