interface CategoryOption {
  type: number;
  key: string;
  name: string;
  imageParamName: string;
  widthSize: number;
}

const categories: CategoryOption[] = [
  {
    type: 1,
    key: 'singles',
    name: 'Singles',
    imageParamName: 'Menu_Singles',
    widthSize: 6,
  },
  {
    type: 2,
    key: 'doubles',
    name: 'Doubles',
    imageParamName: 'Menu_Doubles',
    widthSize: 6,
  },
  {
    type: 4,
    key: 'specialNeeds',
    name: 'Special Needs',
    imageParamName: 'Menu_SpecialNeeds',
    widthSize: 6,
  },
  {
    type: 5,
    key: 'scooters',
    name: 'Scooters',
    imageParamName: 'Menu_Scooters',
    widthSize: 6,
  },
  {
    type: 3,
    key: 'cribsAndBedding',
    name: 'Cribs and Bedding',
    imageParamName: 'Menu_Cribs',
    widthSize: 8,
  },
];

export default categories;
