import { FC, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ReserveDates from 'app/components/ReserveDates';
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
import useScrollTop from 'app/hooks/useScrollTop';
import StrollerList from '../StrollerList';
import castleBg from 'assets/images/castle-bg-desktop.jpg';

const ENV = process.env.REACT_APP_ENV;

const StrollerListDesktop: FC = () => {
  useScrollTop(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(containerRef, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      <Box minHeight="100vh" position="relative">
        <Box
          display="flex"
          justifyContent="center"
          py={4}
          px={15}
          sx={{
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url('${castleBg}')`,
            '@media (max-width: 1100px)': {
              px: 2,
            },

            transition: 'top .5s ease-in-out',

            flex: 1,
            position: 'fixed',
            top: isVisible ? -170 : 0,
            left: 0,
            right: 0,
            zIndex: 2,
            boxShadow: '0px 1px 6px 2px rgba(0, 0, 0, 0.25)',
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <Container maxWidth="xl">
            <ReserveDates shrink />
          </Container>
        </Box>

        <Header />

        <Box
          ref={containerRef}
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          py={4}
          px={15}
          sx={{
            zIndex: 5,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundImage: `url('${castleBg}')`,
            '@media (max-width: 1100px)': {
              px: 2,
            },
          }}
        >
          <ReserveDates title="Reservation Dates" />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight={300}
          pb={6}
        >
          <Container maxWidth="xl">
            <StrollerList />
          </Container>
        </Box>

        <Footer />
      </Box>
    </>
  );
};

export default StrollerListDesktop;
