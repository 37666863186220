import { FC, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GlobalStateContainer } from 'app/hooks/useGlobalState';
import categories from './strollersCategories';

const TypesMenu: FC = () => {
  const { globalState, updateGlobalState } =
    GlobalStateContainer.useContainer();

  const categoriesRef = useRef<HTMLElement>(null);

  const handleSelect = (type: number) => () => {
    if (categoriesRef?.current) {
      categoriesRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }

    updateGlobalState({ currentCategory: type });
  };

  useEffect(() => {
    if (!globalState?.currentCategory) {
      handleSelect(1)();
    }
  }, []);

  return (
    <Box ref={categoriesRef} sx={{ display: 'flex', scrollMargin: '24px' }}>
      {categories.map(option => (
        <Button
          key={option.key}
          variant={
            globalState?.currentCategory === option.type
              ? 'contained'
              : 'outlined'
          }
          sx={{
            textTransform: 'none',
            minWidth: 193,
            '&:not(:last-child)': { mr: 3 },
          }}
          onClick={handleSelect(option.type)}
        >
          {option.name}
        </Button>
      ))}
    </Box>
  );
};

export default TypesMenu;
