import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Text } from '@concepta/react-material-ui';
import { ProductInterface } from 'ks-common';
import DeliveryReturnDateFields from '../DeliveryReturnDateFields';
import { Form, FormErrors, ValueOf } from 'app/hooks/useGlobalForm';
import Tabs from './Tabs';
import castleBg from 'assets/images/castle-bg-mobile.jpg';

interface Props {
  show: boolean;
  type?: string;
  currentStrollerFromList?: ProductInterface;
  closeOverlay: () => void;
  currentProduct: Partial<Form>;
  formErrors?: Partial<FormErrors>;
  setFormField: (field: keyof Form, value: ValueOf<Form>) => void;
  changeCurrentIndex: (num: number) => void;
  submit: () => void;
  strollerUnavailable: boolean;
  isFetching: boolean;
  isSingleProduct: boolean;
}

const Card: FC<Props> = ({
  show,
  type,
  currentStrollerFromList,
  closeOverlay,
  formErrors,
  changeCurrentIndex,
  submit,
  strollerUnavailable,
  isFetching,
  isSingleProduct,
}) => {
  const theme = useTheme();

  const submitDisabled =
    strollerUnavailable ||
    isFetching ||
    !!(formErrors && Object.keys(formErrors)?.length);

  const dateError =
    (strollerUnavailable && 'Stroller not available in this date') ||
    formErrors?.deliveryDate ||
    formErrors?.returnDate;

  return (
    <MuiCard
      sx={{
        width: '100%',
        minHeight: '100vh',
        borderRadius: '25px',
        border: `1px solid ${theme.palette.grey[900]}`,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <IconButton
        aria-label="close modal"
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'grey.900',
        }}
        onClick={() => closeOverlay()}
      >
        <CloseIcon sx={{ height: '30px', width: '30px' }} />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative',

          '&::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url('${castleBg}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        }}
      >
        <Box
          sx={{
            py: 3,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            backgroundColor: alpha(theme.palette.primary.main, 0.9),
            zIndex: 1,
          }}
        >
          <Text
            fontSize={20}
            fontWeight={400}
            color="primary.contrastText"
            align="center"
          >
            {type}
          </Text>
          <Text
            fontSize={30}
            fontWeight={600}
            color="primary.contrastText"
            align="center"
            sx={{
              lineHeight: '1.2em',
              minHeight: '2.4em',
            }}
          >
            {currentStrollerFromList?.ProductName}
          </Text>

          <Box display="flex" alignItems="center" mt={1} mx="auto">
            {!isSingleProduct && (
              <Box mr={1}>
                <IconButton
                  aria-label="previous stroller"
                  sx={{
                    color: 'primary.contrastText',
                  }}
                  onClick={() => changeCurrentIndex(-1)}
                >
                  <ChevronLeft sx={{ height: '26px', width: '26px' }} />
                </IconButton>
              </Box>
            )}
            <Box width="38vw" height="38vw">
              <img
                src={currentStrollerFromList?.productImages?.[0]?.URL}
                alt="Stroller preview"
                style={{ opacity: strollerUnavailable ? 0.5 : 1 }}
                width="100%"
                height="auto"
              />
            </Box>
            {!isSingleProduct && (
              <Box ml={1}>
                <IconButton
                  aria-label="next stroller"
                  sx={{
                    color: 'primary.contrastText',
                  }}
                  onClick={() => changeCurrentIndex(1)}
                >
                  <ChevronRight sx={{ height: '26px', width: '26px' }} />
                </IconButton>
              </Box>
            )}
          </Box>

          <Box flex={1} mt={4} px={5}>
            <DeliveryReturnDateFields error={dateError} />
            <Button
              variant="contained"
              sx={{ mt: 4, minWidth: '167px', mx: 'auto' }}
              disabled={submitDisabled}
              onClick={submit}
              fullWidth
              color="secondary"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
      <Box pb={3} px={2} display="flex" flexDirection="column" flex={1}>
        {show && <Tabs currentStrollerFromList={currentStrollerFromList} />}
      </Box>
    </MuiCard>
  );
};

export default Card;
