import React, { useState, forwardRef } from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

interface TextAreaProps {
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  hiddenLabel?: boolean;
}

type Props = TextFieldProps & { options?: TextAreaProps };

const TextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { sx, type, options } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(prv => !prv);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const isPassword = type === 'password';

  return (
    <MuiTextField
      {...props}
      sx={{
        mt: '4px',
        mb: 3,
        input: { color: 'text.primary' },
        ...sx,
      }}
      size="small"
      hiddenLabel={options?.hiddenLabel}
      label={options?.hiddenLabel ? '' : props.label}
      type={isPassword ? (showPassword ? 'text' : 'password') : type}
      multiline={options?.multiline}
      rows={options?.rows}
      InputProps={{
        ...(isPassword && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }),
        ref: ref,
        ...props.InputProps,
      }}
    />
  );
});

export default TextField;
