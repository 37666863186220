import { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Header from 'app/components/Header';
import { FormContainer } from 'app/hooks/useGlobalForm';
import ProductCard from 'app/components/ProductCard/ProductCard';
import CostSummary from 'app/components/CostSummary/CostSummary';
import useScrollTop from 'app/hooks/useScrollTop';
import OrderNumber from './OrderNumber';
import { OrderCheckoutOutputInterface } from 'ks-common';

const ThankYouMobile: FC = () => {
  useScrollTop(true);

  const navigate = useNavigate();

  const location = useLocation();
  const state = location?.state as OrderCheckoutOutputInterface;
  const orderId = state?.OrderId;
  const orderQuote = state?.OrderQuoteCalculated;

  const { thankYouProducts } = FormContainer.useContainer();

  const goHome = () => {
    navigate('/');
  };

  return (
    <Box minHeight="100vh" width="100vw">
      <Header />

      <OrderNumber orderNumber={orderId} />

      <Box flex={1} mt={4} px={2} pb={4}>
        {thankYouProducts.map((product, i) => (
          <ProductCard
            key={`${product.product?.ProductID}${i}`}
            product={product}
            index={i}
            readonly
          />
        ))}

        <CostSummary quoteData={orderQuote} />

        <Button variant="contained" fullWidth sx={{ mt: 4 }} onClick={goHome}>
          Return to home
        </Button>
      </Box>
    </Box>
  );
};

export default ThankYouMobile;
