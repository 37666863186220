import { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Image } from '@concepta/react-material-ui';

export interface SimpleDialogProps {
  open: boolean;
  image: string;
  onClose: () => void;
}

const ImageDialog: FC<SimpleDialogProps> = props => {
  const { onClose, image, open } = props;

  return (
    <Dialog onClose={onClose} open={open} onClick={onClose}>
      <Image src={image} alt="Stroller item image" />
      <Box display="flex" justifyContent="flex-end" px={2}>
        <Button>CLOSE</Button>
      </Box>
    </Dialog>
  );
};

export default ImageDialog;
