import { FC } from 'react';
import { Text } from '@concepta/react-material-ui';
import { TypographyProps } from '@mui/material/Typography';

interface Props {
  children: string;
  required?: boolean;
  subLabel?: boolean;
  sx?: TypographyProps['sx'];
  m?: TypographyProps['m'];
  mt?: TypographyProps['mt'];
  mb?: TypographyProps['mb'];
  ml?: TypographyProps['ml'];
  mr?: TypographyProps['mr'];
  mx?: TypographyProps['mx'];
  my?: TypographyProps['my'];
}

const FormLabel: FC<Props & TypographyProps> = ({
  children,
  required,
  subLabel,
  sx,
  m,
  mt,
  mb = 1,
  ml,
  mr,
  mx,
  my,
}) => {
  return (
    <Text
      color="primary.darker"
      fontWeight={subLabel ? 400 : 700}
      fontSize={subLabel ? 14 : 22}
      letterSpacing="-1px"
      sx={sx}
      m={m}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      mx={mx}
      my={my}
    >
      {children}
      {required && ' *'}
    </Text>
  );
};

export default FormLabel;
