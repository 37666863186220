import { FC, ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { isMobile } from 'utils/isMobile';

interface Props {
  children: ReactNode;
  textColor?: string;
}

const ErrorText: FC<Props & TypographyProps> = props => {
  const { children, textColor, ...otherProps } = props;

  return (
    <Typography
      mt={2}
      fontSize={16}
      fontWeight={400}
      color={textColor || 'error.main'}
      letterSpacing="-0.5px"
      align={isMobile ? 'center' : 'left'}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default ErrorText;
