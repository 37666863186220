import { FC, Fragment, useState } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import FormLabel from 'app/components/FormLabel';
import { FormContainer, Form } from 'app/hooks/useGlobalForm';
import LeftRightInfo from 'app/components/LeftRightInfo';
import IconButton from '@mui/material/IconButton';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AlertDialog, { AlertType } from 'app/components/AlertDialog';
import { isMobile } from 'utils/isMobile';

interface Props {
  product: Partial<Form>;
  index: number;
  readonly?: boolean;
}

const Insurance: FC<Props> = ({ product, index, readonly }) => {
  const { updateCurrentProduct } = FormContainer.useContainer();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const insurance = product.accessories?.insurance;
  const insurancePrice = product.product?.InsuranceRate;

  const handleClose = () => {
    setAddDialogOpen(false);
    setRemoveDialogOpen(false);
  };
  const handleOpen = () => {
    insurance ? setRemoveDialogOpen(true) : setAddDialogOpen(true);
  };

  const toggleInsurance = () => {
    updateCurrentProduct(
      {
        accessories: {
          insurance: !insurance,
        },
      },
      index,
    );
  };

  return (
    <Fragment>
      <Box mb={3}>
        <LeftRightInfo
          sx={{ mt: 1 }}
          left={<FormLabel mb={0}>Insurance:</FormLabel>}
          right={
            <Box display="flex" alignItems="center">
              {!readonly && (
                <Fragment>
                  {insurance && (
                    <Fragment>
                      <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                        {insurancePrice && `$${insurancePrice}`}
                      </Text>
                      {!readonly && (
                        <IconButton sx={{ ml: 1 }} onClick={handleOpen}>
                          <DeleteOutline
                            sx={{ fontSize: isMobile ? '19px' : '22px' }}
                          />
                        </IconButton>
                      )}
                    </Fragment>
                  )}
                  {!insurance && (
                    <Link
                      sx={{ cursor: 'pointer' }}
                      onClick={handleOpen}
                      fontWeight={500}
                    >
                      ADD INSURANCE
                    </Link>
                  )}
                </Fragment>
              )}

              {readonly && (
                <Text fontSize={isMobile ? 14 : 16} fontWeight={400}>
                  {insurance ? `$${insurancePrice}` : 'No insurance'}
                </Text>
              )}
            </Box>
          }
        />
      </Box>
      <AlertDialog
        open={addDialogOpen}
        title="Attention"
        text={`Please add optional damage / theft insurance  (just $${insurancePrice} for the entire rental period)`}
        onClose={handleClose}
        onConfirm={toggleInsurance}
        alertType={AlertType.CONFIRMATION}
      />
      <AlertDialog
        open={removeDialogOpen}
        title="Attention"
        text="Are you sure you want to remove insurance?"
        onClose={handleClose}
        onConfirm={toggleInsurance}
        alertType={AlertType.CONFIRMATION}
      />
    </Fragment>
  );
};

export default Insurance;
