import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const contains = (path: string, search: string) => path.includes(search);

const Redirector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath: string = location.pathname;

  useEffect(() => {
    if (currentPath) {
      const minimizedPath = currentPath.toLowerCase();
      if (contains(minimizedPath, 'disneystrollerrentals')) {
        return navigate('/rental-agreement');
      }
      if (contains(minimizedPath, 'testimonials.aspx')) {
        return navigate('/testimonials');
      }
      if (contains(minimizedPath, 'faq.aspx')) {
        return navigate('/faqs');
      }
      if (contains(minimizedPath, 'selectdate.aspx')) {
        return navigate('/');
      }
      navigate('/');
    }
  }, [currentPath]);

  return (
    <Box flex={1}>
      <Box display="flex" alignItems="center" justifyContent="center" py={12}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Redirector;
