import { Form, VacationHome } from 'app/hooks/useGlobalForm';
import { SystemParameterInterface } from 'ks-common';

interface Props {
  vacationHomeForm:
    | Form['deliveryLocation']['vacationHome']
    | Form['returnLocation']['vacationHome'];
  systemParams?: SystemParameterInterface[];
  isSubmit?: boolean;
  unservedResorts?: string;
}

type CheckErrors = (
  props: Props,
) => undefined | Partial<Record<keyof VacationHome, string>>;

const checkVacationHomeFieldsErrors: CheckErrors = props => {
  const { systemParams, isSubmit, vacationHomeForm } = props;
  const unservedResorts = systemParams?.find(
    param => param.ParamName === 'Banned_List',
  )?.ParamValue;
  const unservedResortsArray = unservedResorts?.toLowerCase()?.split(';');

  const errors: Partial<Record<keyof VacationHome, string>> = {};

  if (
    unservedResortsArray &&
    vacationHomeForm?.streetAddress &&
    unservedResortsArray.includes(vacationHomeForm.streetAddress.toLowerCase())
  ) {
    errors.streetAddress =
      "Oops! Marriott's Grande Vista is currently not a resort we service.";
  }

  if (isSubmit) {
    if (
      !vacationHomeForm?.addressUnknown &&
      vacationHomeForm?.zip?.length !== 5
    ) {
      errors.zip = 'Please enter the ZIP code';
    }

    if (
      !vacationHomeForm?.addressUnknown &&
      vacationHomeForm?.zip?.length === 5
    ) {
      if (!vacationHomeForm?.streetAddress)
        errors.streetAddress = 'Please enter the Street Address';
      if (!vacationHomeForm?.city) errors.city = 'Please enter the City';
      if (!vacationHomeForm?.state) errors.state = 'Please enter the State';
    }
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return undefined;
};

const checkVacationHomeZipError = (props: Props) => {
  const { vacationHomeForm, systemParams } = props;

  const zipCode = vacationHomeForm?.zip || '';
  const addressUnknown = vacationHomeForm?.addressUnknown;

  const allowedZipCodes = systemParams?.find(
    param => param.ParamName === 'Vacation_Home_Zips',
  )?.ParamValue;

  const zipCodeNotAllowed =
    zipCode.length === 5 && !allowedZipCodes?.includes(zipCode);

  if (!addressUnknown && zipCodeNotAllowed) {
    return 'It looks like your delivery location is not in an area that we service.';
  }
  return '';
};

export { checkVacationHomeFieldsErrors, checkVacationHomeZipError };
