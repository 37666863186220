import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import DatePicker from 'app/components/DatePicker';
import useLocalStorageState from 'use-local-storage-state';
import { SystemParameterInterface } from 'ks-common';
import ErrorText from 'app/components/ErrorText';
import { FormContainer } from 'app/hooks/useGlobalForm';
import isToday from 'date-fns/isToday';
import { DateObject } from 'react-multi-date-picker';

interface Props {
  error?: string;
}

const DeliveryReturnDateFields: FC<Props> = ({ error }) => {
  const [systemParams] =
    useLocalStorageState<SystemParameterInterface[]>('systemParams');

  const { currentProduct, updateCurrentProduct } = FormContainer.useContainer();

  const disabledDates = useMemo(
    () =>
      systemParams?.find(param => param.ParamName === 'Blocked_Dates')
        ?.ParamValue,
    [systemParams],
  );

  const onChange = (values: (string | undefined | null)[] | null) => {
    if (values) {
      updateCurrentProduct({
        deliveryDate: values[0],
        returnDate: values[1] || null,
      });
    }
  };
  const deliveryValue = currentProduct?.deliveryDate;
  const returnValue = currentProduct?.returnDate;

  const deliveryDateIsToday =
    !!deliveryValue &&
    isToday(
      new DateObject({
        date: deliveryValue,
        format: 'YYYY-MM-DD',
      }).toDate(),
    );

  return (
    <Box>
      <DatePicker
        label="Delivery & Return"
        value={(deliveryValue || returnValue) && [deliveryValue, returnValue]}
        onChange={onChange}
        disabledDates={disabledDates}
        error={deliveryDateIsToday}
        type="both"
      />
      {error && (
        <ErrorText align="center" textColor="primary.contrastText">
          {error}
        </ErrorText>
      )}
    </Box>
  );
};

export default DeliveryReturnDateFields;
