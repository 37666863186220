import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

const REACT_APP_GA_MEASUREMENT_ID = process.env
  .REACT_APP_GA_MEASUREMENT_ID as string;

const ENV = process.env.REACT_APP_ENV;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID, {
  gaOptions: { cookieDomain: 'none' },
  ...(ENV === 'STAGING' && { gtagOptions: { debug_mode: true } }),
});

Sentry.init({
  dsn: SENTRY_DSN,
  normalizeDepth: 10,
  debug: ENV === 'DEVELOPMENT',
  environment: ENV,

  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  ENV === 'DEVELOPMENT' ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
