import { FC, useEffect, useState } from 'react';
import { Text } from '@concepta/react-material-ui';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import { FormContainer } from 'app/hooks/useGlobalForm';
import DOMPurify from 'dompurify';
import { RentalPricing } from 'app/components/RentalPricing';
import { gaProductFormatter } from 'utils/GAnalytics/formatters';

const StrollerSpecifications: FC = () => {
  const { currentProduct } = FormContainer.useContainer();

  const sanitizedProductSpecifications: { __html: string } = {
    __html:
      DOMPurify.sanitize(currentProduct?.product?.Specifications || '') || '',
  };

  const [lastProductId, setLastProductId] = useState<number | undefined>();

  useEffect(() => {
    if (
      currentProduct &&
      currentProduct?.product?.ProductID !== lastProductId
    ) {
      setLastProductId(currentProduct.product?.ProductID);
      const gaData = gaProductFormatter(currentProduct);
      ReactGA.event('view_item', gaData);
    }
  }, [currentProduct]);

  return (
    <Box flex={1} maxWidth="320px">
      {currentProduct?.product && (
        <RentalPricing product={currentProduct.product} />
      )}

      <Text fontSize={30} fontWeight={700} color="primary.darker" mt={4}>
        Specifications
      </Text>

      <Text
        fontSize={16}
        fontWeight={400}
        fontFamily="Roboto, sans-serif"
        color="grey.800"
      >
        <div dangerouslySetInnerHTML={sanitizedProductSpecifications} />
      </Text>
    </Box>
  );
};

export default StrollerSpecifications;
