import { Helmet } from 'react-helmet';
import TestimonialsMobile from './TestimonialsMobile';
import TestimonialsDesktop from './TestimonialsDesktop';
import { isMobile } from 'utils/isMobile';

const ENV = process.env.REACT_APP_ENV;

const Testimonials = () => {
  return (
    <>
      <Helmet>
        {ENV === 'PRODUCTION' && (
          <script>{`<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T9PWBRCX');
<!-- End Google Tag Manager —>`}</script>
        )}
        <title>Reviews & Testimonials | Kingdom Strollers</title>
        <meta
          name="description"
          content="Take a look at our reviews & testimonials and see why Kingdom Strollers is the top-rated Orlando stroller rental service."
        />
      </Helmet>
      {ENV === 'PRODUCTION' && (
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PWBRCX"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      )}
      {isMobile ? <TestimonialsMobile /> : <TestimonialsDesktop />}
    </>
  );
};

export default Testimonials;
