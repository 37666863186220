import { FC } from 'react';
import Box from '@mui/material/Box';
import Footer from 'app/components/Footer';
import Header from 'app/components/Header';
import ReviewOrderDesktopDisplay from './ReviewOrderDesktopDisplay';
import { OrderQuoteOutputInterface } from 'ks-common';

interface Props {
  readonly?: boolean;
  orderQuoteCalculated?: OrderQuoteOutputInterface;
  isPending?: boolean;
}

const ReviewOrderDesktop: FC<Props> = ({ orderQuoteCalculated, isPending }) => {
  return (
    <Box minHeight="100vh">
      <Header />
      <ReviewOrderDesktopDisplay
        orderQuoteCalculated={orderQuoteCalculated}
        isPending={isPending}
      />
      <Footer />
    </Box>
  );
};

export default ReviewOrderDesktop;
