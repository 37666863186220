import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from '@concepta/react-material-ui';

import KgdCard from 'app/components/KgdCard';
import { isMobile } from 'utils/isMobile';
import Form from './Form';
import castleBg from 'assets/images/castle-bg-sepia-desktop.jpg';

const Header: FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={5}
      px={15}
      sx={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url('${castleBg}')`,
        '@media (max-width: 1100px)': {
          px: 2,
        },
      }}
    >
      <KgdCard grow>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          px={isMobile ? 4 : 6}
          py={isMobile ? 2 : 4}
          alignItems="center"
          width={isMobile ? 'auto' : 800}
        >
          <Text
            fontSize={isMobile ? 20 : 31}
            fontWeight={isMobile ? 500 : 700}
            color="primary.contrastText"
            mb={isMobile ? 0 : 2}
            variant="h1"
          >
            Confirm / Edit Reservation
          </Text>

          <Form />
        </Box>
      </KgdCard>
    </Box>
  );
};

export default Header;
