import React from 'react';
import Home from 'app/screens/Home';
import ReviewOrder from 'app/screens/ReviewOrder';
import StrollerList from 'app/screens/StrollerList';
import Accessories from 'app/screens/Accessories';
import Checkout from 'app/screens/Checkout';
import ThankYou from 'app/screens/ThankYou';
import PageContent from 'app/screens/PageContent';
import Testimonials from 'app/screens/Testimonials';
import Redirector from 'app/screens/Redirector';
import Login from 'app/screens/EditOrder/Login';

export interface Routes {
  name: string;
  route: string;
  component: React.FC;
  props?: Record<string, unknown>;
  needsCart?: boolean;
  needsThankYouProducts?: boolean;
}

const routes: Array<Routes> = [
  {
    name: 'Home',
    route: '/',
    component: Home,
  },
  {
    name: 'Review Order',
    route: '/review',
    component: ReviewOrder,
    needsCart: true,
  },
  {
    name: 'Stroller List',
    route: '/stroller-list',
    component: StrollerList,
  },
  {
    name: 'Accessories',
    route: '/accessories',
    component: Accessories,
    needsCart: true,
  },
  {
    name: 'Checkout',
    route: '/checkout',
    component: Checkout,
    needsCart: true,
  },
  {
    name: 'ThankYou',
    route: '/thank-you',
    component: ThankYou,
    needsThankYouProducts: true,
  },
  {
    name: 'Page Content',
    route: '/about-us',
    component: PageContent,
  },
  {
    name: 'Page Content',
    route: '/faqs',
    component: PageContent,
  },
  {
    name: 'Page Content',
    route: '/rental-agreement',
    component: PageContent,
  },
  {
    name: 'Page Content',
    route: '/contact-us',
    component: PageContent,
  },
  {
    name: 'Testimonials',
    route: '/testimonials',
    component: Testimonials,
  },
  // Edit Order
  {
    name: 'Login',
    route: '/login',
    component: Login,
  },
  // Redirector
  {
    name: 'Wildcard',
    route: '*',
    component: Redirector,
  },
];

export default routes;
