import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useGetPageContent from 'app/hooks/useGetPageContent';
import { Text } from '@concepta/react-material-ui';
import { Helmet } from 'react-helmet';
import { isMobile } from 'utils/isMobile';
import { PageContentEnum } from 'ks-common/dist/page-content/enums/page-content.enum';

type CurrentPath = '/about-us' | '/contact-us' | '/faqs' | '/rental-agreement';

interface ScreenData {
  name: PageContentEnum;
  title: string;
  metaDesc: string;
}

const PageLoader = () => {
  const location = useLocation();
  const currentPath: string = location.pathname;

  const screens: Record<CurrentPath, ScreenData> = {
    '/about-us': {
      name: PageContentEnum['About'],
      title: 'Kingdom Strollers | Save 50% on Orlando Stroller Rentals',
      metaDesc:
        'Kingdom Stollers is the first choice for Orlando stroller rentals throughout all of Central Florida. Save up to 50% with us compared to Disney fees!',
    },
    '/contact-us': {
      name: PageContentEnum['Contact Us'],
      title: 'Contact Us | Save Up to 50% on Stroller Rentals',
      metaDesc:
        'Contact Kingdom Strollers today and schedule a delivery to anywhere you stay. Stroller rentals can be expensive. Experience the best rated service in Orlando!',
    },
    '/faqs': {
      name: PageContentEnum['FAQs'],
      title: 'Orlando Stroller Rental FAQs | Contact Us & Save Up To 50%',
      metaDesc:
        'If you have any questions about renting strollers in Orlando, visit us or contact us today. Kingdom Strollers is the first choice in Orlando stroller rentals.',
    },
    '/rental-agreement': {
      name: PageContentEnum['Rental Agreement'],
      title: 'Rental Agreement | Save up to 50% on Stroller Rentals',
      metaDesc:
        'Save up to 50% on your next Orlando stroller rental. Disney fees are too expensive, contact Kingdom Strollers today and schedule a delivery!',
    },
  };

  const screen =
    screens[currentPath as CurrentPath] || screens['/rental-agreement'];

  const { data, isPending, execute } = useGetPageContent({
    screen: screen.name,
  });

  useEffect(() => {
    execute();
  }, [currentPath]);

  const sanitizedPage = {
    __html: DOMPurify.sanitize(data?.[0]?.PageHTML || '') || '',
  };

  return (
    <>
      <Helmet>
        <title>{screen.title}</title>
        <meta name="description" content={screen.metaDesc} />
      </Helmet>

      <Box flex={1}>
        {isPending && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={4}
          >
            <CircularProgress />
          </Box>
        )}
        {!isPending && (
          <>
            <Text
              fontSize={isMobile ? 25 : 30}
              fontWeight={600}
              color="primary.darker"
              variant="h1"
            >
              {data?.[0]?.Page_Title}
            </Text>
            <div dangerouslySetInnerHTML={sanitizedPage} />
          </>
        )}
      </Box>
    </>
  );
};

export default PageLoader;
