import { FC } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import useScrollTop from 'app/hooks/useScrollTop';
import Testimonials from './Testimonials';

const TestimonialsDesktop: FC = () => {
  useScrollTop(true);

  return (
    <Box display="flex" flexDirection="column" style={{ minHeight: '100vh' }}>
      <Header />

      <Box flexGrow={1}>
        <Container maxWidth="xl" sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="center" flex={1} px={4}>
            <Testimonials />
          </Box>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default TestimonialsDesktop;
