import { FC, useState, useEffect, useRef } from 'react';
import { ProductInterface } from 'ks-common';
import { useSpring, animated } from '@react-spring/web';
import { useGestureResponder, StateType } from 'react-gesture-responder';
import { useNavigate } from '@concepta/react-router';
import ReactGA from 'react-ga4';
import Card from './Card';
import useGetProductAvailability from 'app/hooks/useGetProductAvailability';
import {
  FormContainer,
  Form,
  FormErrors,
  ValueOf,
} from 'app/hooks/useGlobalForm';
import useWindowSize from 'app/hooks/useWindowSize';
import cloneDeep from 'lodash/cloneDeep';
import useScrollTop from 'app/hooks/useScrollTop';
import { gaProductFormatter } from 'utils/GAnalytics/formatters';

interface Props {
  show: boolean;
  type?: string;
  closeModal: () => void;
  strollersList: ProductInterface[];
}

interface AvailableStroller {
  ProductID: number;
  Available: boolean;
}

const StrollerOverlay: FC<Props> = ({
  show,
  type,
  closeModal,
  strollersList,
}) => {
  const { scrollTop } = useScrollTop();
  const navigate = useNavigate();

  const { height } = useWindowSize();

  const { currentProduct, updateCurrentProduct, startNewProduct, validations } =
    FormContainer.useContainer();

  const [formErrors, setFormErrors] = useState<Partial<FormErrors>>();

  const [strollerUnavailable, setStrollerUnavailable] =
    useState<boolean>(false);

  const {
    data: productAvailability,
    execute,
    isPending,
    error,
  } = useGetProductAvailability({
    startDate: currentProduct?.deliveryDate,
    endDate: currentProduct?.returnDate,
    productId: currentProduct?.product?.ProductID,
  });

  useEffect(() => {
    if (!!productAvailability && !isPending && !error) {
      const availableStroller = productAvailability.find(
        (stlr: AvailableStroller) =>
          stlr.ProductID === currentProduct?.product?.ProductID,
      )?.Available;
      setStrollerUnavailable(!availableStroller);
    }
  }, [productAvailability]);

  useEffect(() => {
    checkFormErrors();
    if (
      currentProduct?.deliveryDate &&
      currentProduct?.returnDate &&
      currentProduct?.product
    ) {
      execute();
    }
  }, [
    currentProduct?.deliveryDate,
    currentProduct?.returnDate,
    currentProduct?.product?.ProductID,
  ]);

  const setFormField = (field: keyof Form, value: ValueOf<Form>) => {
    if (currentProduct?.step === 2) {
      startNewProduct({ [field]: value });
    } else {
      updateCurrentProduct({ [field]: value });
    }
  };

  const [currentProductIndex, setCurrentProductIndex] = useState<
    number | undefined
  >();

  const changeCurrentIndex = (num: number) => {
    setStrollerUnavailable(false);
    let newIndex = (currentProductIndex || 0) + num;
    if (newIndex === strollersList?.length) newIndex = 0;
    if (newIndex === -1) newIndex = strollersList?.length - 1;
    setCurrentProductIndex(newIndex);
  };

  useEffect(() => {
    if (show) {
      scrollTop();
      openOverlay();
      if (strollersList?.length >= 0) {
        setCurrentProductIndex(0);
      }
    }
  }, [show]);

  const currentStrollerFromList: ProductInterface | undefined =
    typeof currentProductIndex === 'number'
      ? strollersList?.[currentProductIndex]
      : undefined;

  useEffect(() => {
    if (currentStrollerFromList) {
      setFormField('product', cloneDeep(currentStrollerFromList));
      const gaData = gaProductFormatter(currentProduct);
      ReactGA.event('view_item', gaData);
    }
  }, [currentStrollerFromList]);

  const checkFormErrors = (isSubmit?: boolean) => {
    setFormErrors(undefined);

    const errors: Partial<FormErrors> = {};

    if (isSubmit) {
      if (!currentProduct?.deliveryDate) {
        errors.deliveryDate = 'Please select a delivery date';
      }
      if (!currentProduct?.returnDate) {
        errors.returnDate = 'Please select a return date';
      }
    }

    if (
      currentProduct?.deliveryDate &&
      currentProduct?.returnDate &&
      validations.dateRangeError
    ) {
      errors.deliveryDate = validations.dateRangeError;
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return errors;
    }

    return undefined;
  };

  const submit = () => {
    if (!checkFormErrors(true)) {
      setFormField('step', 1);
      closeOverlay();
      navigate('/accessories');
    }
  };

  const [lastY, setLastY] = useState<number>(0);
  const [overlayHeight, setOverlayHeight] = useState<number>(0);
  const overlayContainer = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (overlayContainer?.current?.clientHeight) {
      setOverlayHeight(overlayContainer.current.clientHeight);
    }
  });

  const [{ y }, cardApi] = useSpring(() => ({
    y: window.innerHeight,
  }));

  const openOverlay = () => {
    cardApi.start({
      from: { y: window.innerHeight },
      to: { y: 0 },
    });
  };

  const closeOverlay = (currentY?: number) => {
    cardApi.start({
      from: { y: currentY || 0 },
      to: { y: window.innerHeight },
    });
    closeModal();
    setCurrentProductIndex(undefined);
    setStrollerUnavailable(false);
    setLastY(0);
  };

  const onMove = ({ delta }: StateType) => {
    cardApi({
      y: delta[1] + lastY,
      immediate: true,
    });
  };

  const { bind } = useGestureResponder({
    onStartShouldSet: () => true,
    onRelease: onEnd,
    onTerminate: onEnd,
    onMove,
  });

  function onEnd({ delta }: StateType) {
    let currentPos = delta[1] + lastY;
    const distance = window.innerHeight - overlayHeight;

    if (currentPos > 100) {
      return closeOverlay(currentPos);
    }

    if (currentPos > 0) {
      currentPos = 0;
      cardApi({
        y: currentPos,
        immediate: false,
      });
    }

    if (currentPos < distance) {
      currentPos = distance;

      cardApi({
        y: currentPos,
        immediate: false,
      });
    }

    setLastY(currentPos);
  }

  useEffect(() => {
    if (!show) {
      cardApi.start({
        from: { y },
        to: { y: window.innerHeight },
        immediate: true,
      });
      setLastY(0);
    }
  }, [height]);

  const isSingleProduct = strollersList?.length === 1;

  return (
    <animated.div
      style={{
        position: 'fixed',
        top: 0,
        transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
        width: '100%',
        zIndex: 20,
      }}
      {...bind}
      ref={overlayContainer}
    >
      {currentProduct && (
        <Card
          show={show}
          type={type}
          currentStrollerFromList={currentStrollerFromList}
          closeOverlay={closeOverlay}
          formErrors={formErrors}
          changeCurrentIndex={changeCurrentIndex}
          currentProduct={currentProduct}
          setFormField={setFormField}
          submit={submit}
          strollerUnavailable={strollerUnavailable}
          isFetching={isPending}
          isSingleProduct={isSingleProduct}
        />
      )}
    </animated.div>
  );
};

export default StrollerOverlay;
