import { FC } from 'react';
import { Box, Grid, Collapse } from '@mui/material';
import { Text } from '@concepta/react-material-ui';
import CheckoutTextField from './CheckoutTextField';
import { AgentForm } from 'app/hooks/useOrderCheckout';
import { agentFormFields } from '../formfields';
import { OnChangeTextField } from '../Checkout';
import { FormErrors } from '../validators';
import { isMobile } from 'utils/isMobile';

interface Props {
  agentForm: Partial<AgentForm>;
  agentErrors: FormErrors<AgentForm>;
  agentOnChange: OnChangeTextField;
  handleTravelAgent: () => void;
  isAgent: boolean;
}

const Agent: FC<Props> = ({
  agentForm,
  agentErrors,
  agentOnChange,
  handleTravelAgent,
  isAgent,
}) => {
  return (
    <Box>
      <Box>
        <Text
          fontSize={isMobile ? 24 : 36}
          fontWeight={700}
          color="primary.darker"
          onClick={handleTravelAgent}
          mt={4}
          mb={2}
          sx={{ cursor: 'pointer' }}
          textTransform={isMobile ? 'uppercase' : 'none'}
        >
          I’m a travel agent (+)
        </Text>
        <Collapse in={isAgent} timeout="auto" unmountOnExit>
          <Grid container spacing={3}>
            {agentFormFields.map(field => (
              <CheckoutTextField
                key={field.name}
                label={field.label}
                name={field.name}
                value={agentForm[field.name as keyof AgentForm]}
                onChange={agentOnChange}
                required={field.required}
                maxLength={field.maxLength}
                error={agentErrors[field.name]}
                mask={field.mask}
                columns={field.columns}
              />
            ))}
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
};

export default Agent;
