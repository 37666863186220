import { useEffect } from 'react';
import useDataProvider, { useQuery } from '@concepta/react-data-provider';
import { GlobalStateContainer } from 'app/hooks/useGlobalState';
import { ResortInterface } from 'ks-common';
import { toast } from 'react-toastify';

const useGetAllResorts = () => {
  const { globalState, updateGlobalState } =
    GlobalStateContainer.useContainer();

  const { get } = useDataProvider();

  const getResortRequest = () => {
    return get({
      uri: '/resort',
    });
  };

  const {
    data: _data,
    execute,
    isPending,
    error,
  } = useQuery(getResortRequest, false, {
    onError: error => {
      // Todo: Fix error response in rockets
      const errorRes = error as unknown as {
        response: { data: { message: string } };
      };

      toast.error(errorRes?.response?.data?.message || error?.message);
    },
  });

  const data: ResortInterface[] = globalState?.resortsList?.length
    ? globalState?.resortsList
    : _data;

  useEffect(() => {
    if (_data?.length) {
      updateGlobalState({ resortsList: _data });
    }
  }, [_data]);

  useEffect(() => {
    if (!globalState?.resortsList) {
      execute();
    }
  }, []);

  return { data, isPending, error };
};

export default useGetAllResorts;
